<template>
  <div style="height: 100%; width: 100%" ref="tencentMapDivRef">
    <div style="margin: 5px 0 10px 0">
      <el-select
        v-model="fvalue"
        filterable
        remote
        clearable
        :placeholder="placeholder"
        :remote-method="onSearch"
        :loading="loading"
        style="width: 300px"
        @change="handleChange"
        value-key="id"
      >
        <el-option
          v-for="(item, index) in addressList"
          :key="index"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>

    <!-- <div ref="tencentMapRef" :key="mapKey" :id="'tencent_map_' + mapKey" style="height: 100%;width: 100%;"></div> -->
  </div>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
  name: "yesTencentMap",
  props: {
    formCreateInject: {
      type: Object,
    },
    dataSource: String,
    initLng: {
      type: [String, Number],
      default: 118.08891,
    },
    initLat: {
      type: [String, Number],
      default: 24.479627,
    },
    // 限制城市范围  福建、厦门、 放空且regionFix=false 范围为全国
    region: {
      type: String,
      default: "",
    },
    // 搜索无结果时是否固定在当前城市
    regionFix: {
      type: Boolean,
      default: false,
    },
    // 搜索结果条目数
    searchPageSize: {
      type: Number,
      default: 10,
    },
    placeholder: {
      type: String,
      default: "请输入详细地址查找",
    },
    onChangeEvent: String,
    isAutoInit: {
      type: Boolean,
      default: true,
    },
    setTimeoutTime: {
      type: [Number, String],
      default: 2000,
    },
  },
  data() {
    return {
      mapKey: null,
      address: null,
      fvalue: null,
      fdataSource: this.dataSource,
      tencentMapObject: null,
      markersObject: null,
      fInitLng: this.initLng,
      fInitLat: this.initLat,
      searchItem: null,
      loading: false,
      addressList: [],
      suggestSearchObject: null,
      tagList: [],
      infoWindowObject: null,
    };
  },
  created() {},
  mounted() {
    // 获取完整的 URL
    const url = window.location.href;
    // 获取域名及端口部分
    const domain = window.location.origin;
    // 获取 /# 后面的路径部分
    let path = url.substring(domain.length, url.indexOf("/#"));
    path = path ? path : "";
    // 动态创建脚本
    const script = document.createElement("script");
    script.src = path + "/js/tencentMap.js";
    document.head.appendChild(script);

    if (!this.isAutoInit) {
      this.generateDiv();
    }
    if (this.isAutoInit) {
      this.$nextTick(() => {
        if (this.setTimeoutTime && Number(this.setTimeoutTime) > 0) {
          let time =
            Number(this.setTimeoutTime) -
            (Number(this.setTimeoutTime) - Number(this.setTimeoutTime) / 2);
          setTimeout(() => {
            this.generateDiv();
          }, time);
        } else {
          this.generateDiv();
        }
        setTimeout(() => {
          this.initTencentMap();
        }, Number(this.setTimeoutTime));
      });
    }
  },
  methods: {
    getValue() {
      return this.fvalue;
    },
    setValue(val) {
      this.fvalue = val;
      this.handleChange(val);
    },
    loadSourceData() {
      this.setValue(utils.getSourceData(this.fDataSource));
    },
    updateSourceData() {
      utils.updateSourceData(this.fDataSource, this.fvalue);
    },
    generateDiv() {
      this.mapKey = new Date().getTime();
      const tencetnMapDiv = document.createElement("div");
      tencetnMapDiv.setAttribute("id", "tencent_map_" + this.mapKey);
      tencetnMapDiv.style.height = "100%";
      tencetnMapDiv.style.width = "100%";
      this.$refs.tencentMapDivRef.appendChild(tencetnMapDiv);
    },
    initTencentMap() {
      let tempLng = this.fInitLng;
      let tempLat = this.fInitLat;
      if (
        this.fvalue &&
        this.fvalue.location &&
        this.fvalue.location.lng &&
        this.fvalue.location.lat
      ) {
        tempLng = this.fvalue.location.lng;
        tempLat = this.fvalue.location.lat;
      }
      let center = new TMap.LatLng(tempLat, tempLng);
      // //定义map变量，调用 TMap.Map() 构造函数创建地图

      this.tencentMapObject = new TMap.Map("tencent_map_" + this.mapKey, {
        center: center, //设置地图中心点坐标
        zoom: 17.2, //设置地图缩放级别
        pitch: 0, //设置俯仰角
      });
      // // 新建一个地点搜索类
      this.searchItem = new TMap.service.Search({ pageSize: this.searchPageSize });

      // 新建一个关键字输入提示类
      this.suggestSearchObject = new TMap.service.Suggestion({
        pageSize: this.searchPageSize, // 返回结果每页条目数
        region: this.region, // 限制城市范围
        regionFix: this.regionFix, // 搜索无结果时是否固定在当前城市
      });
      this.markersObject = new TMap.MultiMarker({
        map: this.tencentMapObject,
        geometries: [],
      });
    },
    async onSearch(val) {
      if (!val || val.length <= 5) {
        return;
      }
      this.loading = true;
      this.addressList = [];
      await this.suggestSearchObject
        .getSuggestions({ keyword: val, location: this.tencentMapObject.getCenter() })
        .then((res) => {
          const data = res.data;
          data.forEach((e) => {
            let valueInfo = {
              id: e.id,
              address: e.address,
              title: e.title,
              lng: e.location.lng,
              lat: e.location.lat,
              locationInfo: e.location,
            };
            let param = {
              label: e.title + " - " + e.address,
              value: valueInfo,
            };
            this.addressList.push(param);
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
        });

      // const onSearchEvent = window.AsyncFunction("vm", "api", 'dao', 'utils', this.onSearchEvent);
      // await onSearchEvent(this, this.formCreateInject.api, dao, utils);
    },
    async handleChange(val) {
      this.tagList.forEach((e) => {
        e.close();
      });
      this.tagList = [];
      // 值变更后

      const event = window.AsyncFunction(
        "vm",
        "api",
        "dao",
        "utils",
        "resultLocation",
        this.onChangeEvent
      );
      await event(this, this.formCreateInject.api, dao, utils, val);
      if (!val || !val.locationInfo) {
        return;
      }
      this.markersObject.setGeometries([]);
      this.markersObject.updateGeometries([
        {
          id: "0", // 点标注数据数组
          position: val.locationInfo,
        },
      ]);
      let tag = new TMap.InfoWindow({
        map: this.tencentMapObject,
        position: val.locationInfo,
        content: `<h3>${val.title}</h3><p>地址：${val.address}</p>`,
        offset: { x: 0, y: -50 },
      });
      this.tagList.push(tag);
      this.tencentMapObject.setCenter(val.locationInfo);
      this.markersObject.on("click", (e) => {
        this.tagList[Number(e.geometry.id)].open();
      });
    },
  },
};
</script>

<style></style>
