<template>
    <div>
        <div
            v-if="isDesigner"
            :style="textStyle"
        >
            <pre>{{textValue?textValue:designerValue}}</pre>
        </div>
        <pre
            v-else
            :style="textStyle"
            @click="handleClick"
        >{{fvalue}}</pre>
    </div>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yes-text",
    props: {
        formCreateInject: {
            type: Object,
            required: true
        },
        dataSource: String,
        textValue: {
            type: String
        },
        textStyle: {
            type: String
        },
        formCreateName: String,
        clickEvent: String,
        // 是否需要阻止重复点击
        preventClick: {
            type: Boolean,
            default: false
        },
        // 阻止重复点击时间[默认2000ms]
        preventTime: {
            type: Number,
            default: 2000
        },
        // 重复点击时, 是否弹出提示
        showPreventTip: {
            type: Boolean,
            default: false
        },
        // 重复点击提示内容
        preventClickTip: {
            type: String,
            default: "执行操作中, 请勿重复点击"
        }
    },
    data() {
        return {
            time: 0,
            fvalue: "",
            designerValue: "",
            fDataSource: this.dataSource
        };
    },
    watch: {
        textValue(val) {
            this.fvalue = val;
            this.designerValue = val;
        },
        dataSource(val) {
            if (this.textValue && this.textValue != "") {
                return;
            }
            this.designerValue = val;
        }
    },
    computed: {
        isDesigner() {
            return this.$parent.$options._componentTag === "dragTool" || this.$parent.$parent.$parent.$options._componentTag === "dragTool";
        }
    },
    updated() {
        // if (this.textValue) {
        //   this.fvalue = this.textValue;
        //   this.designerValue = this.textValue;
        // } else {
        //   this.fvalue = this.getValue();
        //   this.designerValue = this.getValue();
        // }
    },
    created() {
        // this.fvalue = this.textValue;
        if (this.textValue && this.textValue != "") {
            this.fvalue = this.textValue;
            this.designerValue = this.textValue;
        } else {
            this.fvalue = this.getValue();
            this.designerValue = this.getValue();
        }
    },
    methods: {
        getValue() {
            return this.fvalue;
        },
        setValue(val) {
            this.fvalue = val;
        },
        loadSourceData() {
            const req = utils.getSourceData(this.fDataSource);
            if (req) {
                this.fvalue = req;
            }
        },
        updateSourceData() {
            utils.updateSourceData(this.fDataSource, this.fvalue);
        },
        async handleClick() {
            if (this.handlePrevent()) return;
            const clickEvent = window.AsyncFunction("vm", "api", "dao", "utils", this.clickEvent);
            await clickEvent(this, this.formCreateInject.api, dao, utils);
        },
        /**
         * 阻止重复点击
         * T: 阻止 F: 不阻止
         */
        handlePrevent() {
            if (!this.preventClick) return false;
            const nowTime = Date.now();
            const intervalTime = nowTime - this.time;
            // 如果点击间隔小于 this.preventTime 则不执行后续操作
            let result = false;
            if (intervalTime < this.preventTime) {
                // 是否显示 提示信息
                if (this.showPreventTip) {
                    utils.messageWarning(this.preventClickTip);
                }
                result = true;
            }
            // 更新为当前时间戳
            this.time = nowTime;
            return result;
        }
    }
};
</script>
