<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'yes-row',
  components: {},
  props: {
    dataSource: String,
    formCreateInject: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      url: '',
      fDataSource: this.dataSource
    }
  },
  watch: {
    image (val) {
      if (val == this.url) {
        return;
      }
      this.getImgBase64(val);
      // this.image = this.url;
    }
  },
  created () {
    this.url = this.image;
  },
  methods: {
    image2Base64 (img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL("image/jpg");
      return dataURL;
    },
    getImgBase64 (src) {
      if (!src) {
        this.formCreateInject.rule.props.image = '';
        this.url = ''
        return;
      }
      var base64 = "";
      var img = new Image();
      img.src = src;
      let that = this
      img.onload = function () {
        base64 = that.image2Base64(img);
        that.url = base64;
        that.formCreateInject.rule.props.image = base64;
      };
    }
  },
}
</script>

