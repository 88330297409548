<template>
  <el-timeline
    :reverse="reverse"
    :style="`height:${height};overflow:auto`"
  >
    <!-- <yes-timeline-item
      v-for="(item, index) in fvalue"
      :key="index"
       :formCreateInject="formCreateInject"
    >
    </yes-timeline-item> -->
    <slot />
  </el-timeline>
</template>

<script>
const utils = require("@/api/core/utils");
export default {
  name: "yes-timeline",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    showField: String,
    reverse: Boolean,
    height: String,
    hideTimestamp: Boolean,
    placement: String,
    type: String,
    color: String,
    size: String,
    icon: String,
  },
  data () {
    return {
      fvalue: [{}],
      fDataSource: this.dataSource
    };
  },
  created () {
  },
  methods: {
    getValue () {
      return this.fvalue;
    },
    setValue (val) {
      this.fvalue = val;
    },
    loadSourceData () {
      this.fvalue = utils.getSourceData(
        this.fDataSource
      );
    },
    updateSourceData () {
      utils.updateSourceData(this.fDataSource, this.fvalue);
    },
  },
};
</script>

<style>
</style>