<template>
  <div>
    <iframe
      :src="src"
      ref="iframe"
      width="100%"
      :height="iframeHeight"
    >
    </iframe>
  </div>
</template>

<script>
import arrayToTree from "array-to-tree";
const utils = require('@/api/core/utils');
const day = require('../utils/day');
const dao = require('@/api/core/dao');
const common = require("../utils/common");

export default {
  name: 'yes-gantt',
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    viewDataSource: String,
    id: {
      type: String,
      default: ''
    },
    nodeId: {
      type: String,
      default: ''
    },
    parentId: {
      type: String,
      default: ''
    },
    fieldConfig: {
      // 默认排序
      type: Object,
      default: () => ({}),
    },
    iframeHeight: {
      type: String,
      default: ''
    },
    dbclickEvent: {
      type: String,
      default: ''
    },
    projectUpdateEvent: {
      type: String,
      default: ''
    }
  },
  methods: {
    // 将组件的值返回
    getValue () {
      return this.fValue
    },
    // 修改组件的值
    setValue (val) {
      this.loadFDataSourceFlag = false;
      this.fValue = val
      if (this.fDataSource) {
        this.updateSourceData(val)
      }
      this.loadFDataSourceFlag = true;
    },
    zoomMinus () {//执行甘特图事件
      this.sendIframeWinpMessage('', 'string', 'zoomMinus');
    },
    zoomPlus () {//执行甘特图事件
      this.sendIframeWinpMessage('', 'string', 'zoomPlus');
    },
    left () {//执行甘特图事件
      this.sendIframeWinpMessage('', 'string', 'left');
    },
    center () {//执行甘特图事件
      this.sendIframeWinpMessage('', 'string', 'center');
    },
    right () {//执行甘特图事件
      this.sendIframeWinpMessage('', 'string', 'right');
    },
    //数据处理并传给iframe，变成和引入的甘特图一样的格式
    async dataProcessAndLoad (action) {
      
      let res = [];
      let data = JSON.parse(JSON.stringify(this.tableData));
      if (this.parentId && this.nodeId) {
        data = arrayToTree(data, {
          parentProperty: this.parentId,
          customID: this.nodeId,
        });
      }
      data.map(item => {
        item.level = 0;
        item.hasChild = false;
        res.push(item);
        if (item.children && item.children.length > 0) {
          item.hasChild = true;
          this.treeRecursion(item.children, item.level, res);
        }

      })
      res.forEach(item =>
        delete item.children
      )
      res = res.map(item => this._copyGanttObject(item));
      res.forEach(item =>
        item.fieldConfig = this.fieldConfig
      )
      //把项目依赖对象转成字符串
      res.forEach(item => {
        let depends = item.depends;
        let _depends = '';
        if (common.getType(depends).indexOf("array") > -1 && depends.length > 0) {
          depends.forEach(item => {
            let idx = '';
            res.find((currentValue, index) => {
              if (currentValue.id === item[this.id]) { idx = (index + 1) }
            })
            if (idx) {
              _depends = item.distance ? (_depends ? _depends + ',' + idx + ':' + item.distance : idx + ':' + item.distance) :
                (_depends ? _depends + ',' + idx : idx + '')
            }
          })
        }
        item.depends = _depends;
      })
      this.gantTableData = res;

      let ret = {
        tasks: res,
        selectedRow: 0,
        deletedTaskIds: [],
        resources: [
        ],
        roles: [
        ],
        canWrite: true,
        canDelete: true,
        canWriteOnParent: true,
        canAdd: true,
      };
      // await this.sendIframeWinpMessage(ret);
      if(this.firstLoad || action === 'load') {
        await this.sendIframeWinpMessage(ret);
        this.firstLoad = false 
      }else {
        await this.sendIframeWinpMessage(ret, 'string', 'reload');
      }
    },
    //树数据递归操作
    treeRecursion (childrens, level, res) {
      childrens.map(item => {
        item.level = level + 1;
        item.hasChild = false;
        res.push(item);

        if (item.children && item.children.length > 0) {
          this.treeRecursion(item.children, item.level, res);
          item.hasChild = true;
        }
      })
    },
    // 读取绑定数据源数据
    loadSourceData () {
      const val = utils.getSourceData(this.fDataSource)
      if (val && this.loadFDataSourceFlag) {
        this.fValue = val;
        this.sendIframeWinpMessage(val, 'array', 'check');
      }
    },
    async loadViewSourceData () {
    
      const val = utils.getSourceData(this.fViewDataSource)
      if (val && JSON.stringify(val) !== '{}') {
        this.tableData = val;
        await this.dataProcessAndLoad();
      }
    },
    // 修改绑定数据源数据
    updateSourceData (val) {
      utils.updateSourceData(this.fDataSource, val ? val : this.fvalue);
    },
    //获取iframe返回的值
    async handleMessage (event) {
      if (event.data.action) {
        const { params, cmd, type, action } = event.data;

        if (cmd !== "ganttIframe") {
          return;
        }
        if (action === 'load') {
          this.dataProcessAndLoad('load');
        }
        if (action === 'update') {
          if (type === "object") {
            this.updateTableData(params);
          }
        }
        if (action === 'check') {
          const _tableData = this.tableData.filter(item => params.indexOf(item[this.id]) > -1)
          this.setValue(_tableData);
        }
        if (action === 'dbclick') {
          const row = this.tableData.find(item => item[this.id] === params);
          this.setValue([row]);
          if (this.dbclickEvent) {
            const dbclickEvent = window.AsyncFunction("vm", "data", "api", 'dao', 'utils', this.dbclickEvent);
            await dbclickEvent(this, row, this.formCreateInject.api, dao, utils);
          }
        }
        if (action === 'changeStatus') {
          params.forEach(async (item) => {
            const value = this.tableData.find(t => t[this.id] === item.id)
            const fieldStatus = this.fields.find(f => f.key === 'status')
            value[fieldStatus.value] = item.status
            if (this.projectUpdateEvent) {
              const projectUpdateEvent = window.AsyncFunction("vm", "data", "api", 'dao', 'utils', this.projectUpdateEvent);
              await projectUpdateEvent(this, value, this.formCreateInject.api, dao, utils)
            }
          })
        }
      }
    },
    //数据源数据转成甘特图数据
    _copyGanttObject (data) {
      const _data = {
        progress: 0,
        progressByWorklog: false,
        relevance: 0,
        type: '',
        typeId: '',
        description: '',
        level: 0,
        depends: '',
        canWrite: true,
        duration: 0,
        start: new Date().getTime(),
        end: new Date().getTime(),
        startIsMilestone: false,
        endIsMilestone: false,
        collapsed: false,
        assigs: [],
        hasChild: false,
      }
      _data.level = data.level;
      _data.hasChild = data.hasChild;
      this.fields.forEach(item => {
        if (data[item.value]) {
          _data[item.key] = data[item.value]
          if (_data[item.key] instanceof Date) {
            _data[item.key] = _data[item.key].getTime()
          }
          if (item.key === 'start' || item.key === 'end') {
            _data[item.key] = new Date(day.formatDateTime(_data[item.key])).getTime()
          }

        }
      });
      return _data;
    },
    //甘特图数据转数据源数据
    async updateTableData (ganttObj) {
      const tableObj = this.tableData.find(item => item[this.id] === ganttObj.id);
      this.fields.forEach(item => {
        tableObj[item.value] = ganttObj[item.key]
        if (item.key === 'start' || item.key === 'end') {
          tableObj[item.value] = new Date(day.formatDateTime(tableObj[item.value]))
        }

        if (item.key === 'depends' && tableObj[item.value]) {
          const _dependList = tableObj[item.value].split(',')
          let _depends = [];
          _dependList.forEach(dep => {
            let depObj = {}
            depObj[this.id] = dep;
            depObj["distance"] = '';
            if (dep.indexOf(":") > -1) {
              const depList = dep.split(":");
              depObj[this.id] = this.gantTableData[depList[0]].id;
              depObj["distance"] = depList[1];
            }
            _depends.push(depObj);
          })
          tableObj[item.value] = _depends;
        }
      });

      if (this.projectUpdateEvent) {
        const projectUpdateEvent = window.AsyncFunction("vm", "data", "api", 'dao', 'utils', this.projectUpdateEvent);
        await projectUpdateEvent(this, tableObj, this.formCreateInject.api, dao, utils)
      }
    },
    sendIframeWinpMessage (data = '', type = 'string', action = 'load') {
      this.iframeWin.postMessage({
        params: data, /*在iframe页面中接收通过key也就是param接收，因此传输的数据可以是对象，包含多个key以及对应的数据*/
        cmd: 'gantt',
        type: type,
        action: action,
        tableHead: this.fieldConfig
      }, "*");
    },
  },
  mounted () {
    this.iframeWin = this.$refs.iframe.contentWindow;
    this.fields = Object.keys(this.fieldConfig).map(key => {
      return { key: key, value: this.fieldConfig[key].field }
    })
    this.fields = this.fields.filter(item => item.value)
    window.addEventListener("message", this.handleMessage);
  },
  created () {
  },
  data () {
    return {
      firstLoad: true,
      loadFDataSourceFlag: true,
      fields: [],
      tableData: [],
      gantTableData: [],
      iframeWin: {},//../public/gantt/index.html?id=' + (new Date()).getTime(),
      src: './gantt/index.html?time=' + (new Date()).getTime(),
      fDataSource: this.dataSource,
      fViewDataSource: this.viewDataSource,
      fValue: ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
