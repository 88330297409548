<template>
  <div style="width: 100%">
    <slot />
    <!-- <template v-if="fvalue.length > 0">
        <template v-for="(item , index) in fvalue ">
            <slot :item="item" :index="index"/>
        </template>
    </template>
    <template v-else>
         <slot/>
    </template> -->
  </div>
</template>

<script>
const utils = require("@/api/core/utils");

export default {
  name: "yes-data-object",
  components: {},
  props: {
    dataSource: String,
    formCreateInject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: "",
      fvalue: {},
      fDataSource: this.dataSource,
    };
  },
  watch: {
    fvalue() {
      if (this.isDesigner()) return;
      if (this.fDataSource) {
        // console.log("object..notice",this.fDataSource, this.fvalue)
        this.noticeChange();
        this.updateSourceData();
      }
    },
    row(val) {
      this.fvalue = val;
    },
  },
  created() {},
  methods: {
    isDesigner() {
      return (
        this.$parent.$options._componentTag === "dragTool" ||
        this.$parent.$parent.$parent.$options._componentTag === "dragTool"
      );
    },
    getValue() {
      return this.fvalue;
    },
    setValue(value, key) {
      if (key != undefined) {
        this.$set(this.fvalue, key, value);
      } else {
        this.fvalue = value;
      }
    },
    // 读取绑定数据源数据
    loadSourceData() {
      //console.log()
      this.fvalue = utils.getSourceData(this.fDataSource);
      //console.log('objectload', this.fDataSource, this.fvalue)
    },
    // 修改绑定数据源数据
    updateSourceData() {
      utils.updateSourceData(this.fDataSource, this.fvalue);
    },
    noticeChange() {
      if (this.isDesigner()) return;
      for (var k in this.fvalue) {
        let itemList = this.findChildByName(this.formCreateInject, k);
        if (itemList.length > 0) {
          itemList.map((item) => {
            item.props.fIndex = this.formCreateInject.rule.props.fIndex;
            if (item.name)
              item.name = item.name + this.formCreateInject.rule.props.fIndex;
            if (item.field)
              item.field = item.field + this.formCreateInject.rule.props.fIndex;
            if (item.props.modelUid) {
              item.__fc__.el.uid = this.fvalue[item.props.modelUid];
            }
            if (item.__fc__.el.setValue) {
              if (item.type === "yes-table") {
                item.__fc__.el.setTableValue(this.fvalue[k]);
              } else {
                item.__fc__.el.setValue(this.fvalue[k]);
              }
            }
            if (item.__fc__.el.loadViewSourceData) item.__fc__.el.loadViewSourceData();
          });
        }
      }
    },
    findChildByName(item, name) {
      let childrenItem = [];
      for (var i = 0; i < item.children.length; i++) {
        if (
          (item.children[i].field && item.children[i].field == name) ||
          (item.children[i].name && item.children[i].name == name)
        ) {
          childrenItem.push(item.children[i]);
        }
        if (item.children[i].children && item.children[i].children.length > 0) {
          let childrenItem2 = this.findChildByName(item.children[i], name);
          if (childrenItem2) {
            childrenItem = childrenItem.concat(childrenItem2);
          }
        }
      }
      return childrenItem;
    },
  },
};
</script>
