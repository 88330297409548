<template>
  <vue-pivottable
    :data="fvalue"
    :rows="rows"
    :cols="cols"
    :aggregatorName="countType"
    :rendererName="type"
    :vals="vals"
  >
  </vue-pivottable>
</template>

<script>
const utils = require('@/api/core/utils');
import { VuePivottable } from 'vue-pivottable'
import 'vue-pivottable/dist/vue-pivottable.css'
export default {
  name: "yesPivottable",
  components:{
    VuePivottable
  },
  props: {
    formCreateInject: {
      type: Object
    },
    dataSource: String,
    typeDefault:String,
    countTypeDefault: String,
    valsDefault:Array,
    rowsDefault: Array,
    colsDefault: Array
  },
  data () {
    return {
      fvalue: [],
      type: this.typeDefault,
      countType: this.countTypeDefault,
      vals:this.valsDefault,
      rows: this.rowsDefault,
      cols: this.colsDefault,
      fDataSource: this.dataSource
    };
  },
  created () {
  },
  methods: {
    getValue () {
      return this.fvalue;
    },
    setValue (val) {
      this.fvalue = val;
    },
    setType(val) {
      this.type = val
    },
    setCountType(val) {
      this.countType = val
    },
    setValueOpiton(val) {
      this.vals = val
    },
    setCols(val) {
      this.cols = val
    },
    setRows(val) {
      this.rows = val
    },
    loadSourceData () {
      this.fvalue = utils.getSourceData(
        this.fDataSource
      );
    },
    updateSourceData () {
      utils.updateSourceData(this.fvalue);
    }
  }
}
</script>
