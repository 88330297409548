<template>
    <div>
        <MarkdownItVue
            class="md-body"
            :content="fvalue"
        ></MarkdownItVue>
    </div>
</template>

<script>
const utils = require("@/api/core/utils");
import MarkdownItVue from "markdown-it-vue";
import "markdown-it-vue/dist/markdown-it-vue.css";

export default {
    name: "yesMarkdown",
    components: {
        MarkdownItVue
    },
    props: {
        formCreateInject: {
            type: Object
        },
        initValue: String,
        dataSource: String
    },
    data() {
        return {
            fvalue: "",
            fDataSource: this.dataSource
        };
    },
    created() {
        this.loadSourceData();
        if (!this.fvalue && this.initValue) {
            this.fvalue = this.initValue;
        }
    },
    methods: {
        getValue() {
            return this.fvalue;
        },
        setValue(val) {
            this.fvalue = val;
        },
        loadSourceData() {
            this.fvalue = utils.getSourceData(this.fDataSource);
        }
    }
};
</script>