<template>
    <svg ref="barCode"></svg>
</template>
  
<script>
import JsBarcode from 'jsbarcode';
const utils = require('@/api/core/utils');

export default {
    name: "yesBarCode",
    props: {
        formCreateInject: {
            type: Object
        },
        dataSource: String,
        // auto = CODE128
        // 详细类型 文档：https://github.com/lindell/JsBarcode/wiki#barcodes
        // 支持的类型：CODE128, EAN13, CODE39, ITF-14, MSI, Pharmacode, Codabar, UPC ......
        barCodeType: {
            type: String,
            default: 'CODE128'
        },
        // 一维码线宽
        width: {
            type: [String, Number],
            default: 2
        },
        // 一维码高度
        height: {
            type: [String, Number],
            default: 100
        },
        // 一维码内容
        barCodeValue: {
            type: [String, Number],
            default: undefined
        },
        // 是否显示下方内容
        displayValue: {
            type: Boolean,
            default: true
        },
        // 下方内容字体斜体或粗细：bold, italic, bold italic
        fontOptions: {
            type: String,
            default: ""
        },
        // 下方内容字体：fantasy, monospace
        font: {
            type: String,
            default: "monospace"
        },
        // 下方内容水平对齐位置：left, center, right.
        textAlign: {
            type: String,
            default: "center"
        },
        // 下方内容垂直位置：bottom, top
        textPosition: {
            type: String,
            default: "bottom"
        },
        // 下方内容与一维码的间距
        textMargin: {
            type: [String, Number],
            default: 3
        },
        // 下方内容字体大小
        fontSize: {
            type: [String, Number],
            default: 40
        },
        // 背景颜色
        background: {
            type: String,
            default: "#FFF"
        },
        // 线条颜色
        lineColor: {
            type: String,
            default: "#000"
        },
        // 一维码边距
        margin: {
            type: [String, Number],
            default: 10
        },
        // 是否跳出防护栏
        flat: {
            type: Boolean,
            default: false
        },
        // 最后一个字符 EAN-13有时在条形码后面印有一个字符, 最常见的是>字符
        lastChar: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            fvalue: "",
            fDataSource: this.dataSource
        };
    },
    watch: {
    },
    created() {
        if (this.barCodeValue != null && this.barCodeValue != undefined && this.barCodeValue != "") {
            this.setValue(this.barCodeValue);
        }
    },
    mounted() {
        this.initBarCode();
    },
    methods: {
        getValue() {
            return this.fvalue;
        },
        setValue(val) {
            this.fvalue = val;
            this.initBarCode();
        },
        loadSourceData() {
            this.setValue(utils.getSourceData(this.fDataSource));
            this.initBarCode();
        },
        updateSourceData() {
            utils.updateSourceData(this.fDataSource, this.fvalue);
        },
        initBarCode() {
            if (!this.fvalue || JSON.stringify(this.fvalue) === '{}') {
                return;
            }
            JsBarcode(this.$refs.barCode, this.fvalue, {
                format: this.barCodeType,
                width: this.width,
                height: this.height,
                barCodeValue: this.barCodeValue,
                displayValue: this.displayValue,
                fontOptions: this.fontOptions,
                font: this.font,
                textAlign: this.textAlign,
                textPosition: this.textPosition,
                textMargin: this.textMargin,
                fontSize: this.fontSize,
                background: this.background,
                lineColor: this.lineColor,
                margin: this.margin,
                flat: this.flat,
                lastChar: this.lastChar
            })
        }
    },
}
</script>
  