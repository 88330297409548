<template>
    <div>
        <div class="mermaid" v-html="fvalue"></div>
    </div>
</template>
  
<script>
const utils = require('@/api/core/utils');
import mermaid from 'mermaid'
mermaid.initialize({
    startOnLoad: false
});
export default {
    name: "yesMermaid",
    props: {
        formCreateInject: {
            type: Object
        },
        dataSource: String
    },
    data() {
        return {
            fvalue: '',
            fDataSource: this.dataSource
        };
    },
    created() {
    },
    watch:{
        fvalue(e) {
            setInterval(() => {
                mermaid.init(undefined, document.querySelectorAll(".mermaid"));
            }, 100);
        }
    },
    mounted() {
    },
    methods: {
        handleClick(e) {
            console.log("handleClick", e)
        },
        getValue() {
            return this.fvalue;
        },
        setValue(val) {
            this.fvalue = val;
        },
        loadSourceData() {
            this.fvalue = utils.getSourceData(
                this.fDataSource
            );
        }
    }
}
</script>
  