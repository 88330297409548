<template>
  <div>
    <video
      ref="videoElement"
      style="width:100%;"
      :controls="isControls"
    ></video>
  </div>
</template>
  
<script>
import flvjs from 'flv.js';
const dao = require('@/api/core/dao');
const utils = require('@/api/core/utils');
export default {
  name: "yesFlv",
  props: {
    formCreateInject: {
      type: Object
    },
    dataSource: String,
    typeDefault: String,
    isControls: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      fvalue: "",
      flvPlayer: null,
      type: this.typeDefault,
      fDataSource: this.dataSource
    };
  },
  created() {
  },
  beforeDestroy() {
    if (this.flvPlayerData) {
      flvPlayerData.destroy();
      flvPlayerData = null;
    }
  },
  methods: {
    setType(val) {
      this.type = val;
    },
    getValue() {
      return this.fvalue;
    },
    setValue(val) {
      this.fvalue = val;
    },
    loadSourceData() {
      this.fvalue = utils.getSourceData(this.fDataSource);
      if (this.fvalue === '' || this.fvalue === undefined || this.fvalue === null) return;
      if (this.flvPlayerData) {
        this.flvPlayerData.unload();
        this.flvPlayerData.detachMediaElement();
        this.flvPlayerData.destroy();
        this.flvPlayerData = null;
      }
      const videoElement = this.$refs.videoElement;
      setTimeout(() => {
        this.flvPlayerData = flvjs.createPlayer(
          {
            isLive: true,
            hasVideo: true,
            hasAudio: false,
            type: this.type,
            url: this.fvalue
          },
          {
            isLive: true,
            lazyLoad: false,
            seekType: 'range',
            enableWorker: false,
            enableWorker: false,
            lazyLoadMaxDuration: 7,
            deferLoadAfterSourceOpen: false
          }
        );
        this.flvPlayerData.attachMediaElement(videoElement);
        this.flvPlayerData.load();
        this.flvPlayerData.play();
      }, 1500);
    }
  }
}
</script>
  