<template>
    <div>
        <div
            id="visualization"
            ref="visualization"
        ></div>
    </div>
</template>

<script>
import { DataSet, Timeline } from "vis-timeline/standalone";
import "vis-timeline/styles/vis-timeline-graph2d.css";
const Handlebars = require("handlebars");
//import swal from 'sweetalert';
import moment from "moment";
import { log } from "handlebars";
const utils = require("@/api/core/utils");
const dao = require("@/api/core/dao");
export default {
    name: "yesVisTimeline",
    components: {},
    props: {
        formCreateInject: {
            type: Object,
            required: true
        },
        isStack: {
            type: Boolean,
            default: true
        },
        isStackSubgroups: {
            type: Boolean,
            default: true
        },
        dataSource: String,
        viewDataSource: String,
        addScript: String,
        updateScript: String,
        moveScript: String,
        removeScript: String,
        initOpitions: Object,
        itemFields: Object,
        groupFields: Object,
        template: String,
        templatebind: String,
        clickScript: String,
        itemOverScript: String,
        itemOutScript: String,
        hoverTime: {
            type: [Number, String],
            default: 1000
        },
        itemOverflow: Boolean,
        // 项目之间的最小垂直边距
        itemVertical: {
            type: [Number, String],
            default: 0
        },
        // 项目之间的最小水平边距
        itemHorizontal: {
            type: [Number, String],
            default: 0
        },
        // 根据每个项定义组件最小高度
        groupItemMinHeight: {
            type: [Number, String],
            default: 0
        }
    },
    data() {
        return {
            fvalue: [],
            fDataSource: this.dataSource,
            fViewDataSource: this.viewDataSource,
            container: "",
            groups: [],
            items: new DataSet([]),
            options: {},
            methodOptions: {},
            timeline: "",
            startTime: "",
            endTime: "",
            count: 1,
            hoverTimer: null
        };
    },
    mounted() {
        const that = this;
        this.items = new DataSet(this.fvalue);
        this.container = document.getElementById("visualization");
        // Configuration for the Timeline
        this.methodOptions = {
            stack: this.isStack,
            stackSubgroups: this.isStackSubgroups,
            moment: function (date) {
                return moment(date).locale("zh-cn");
            },
            locale: moment.locale("zh-cn"),
            onAdd: async function (item, callback) {
                const addEvent = window.AsyncFunction("vm", "api", "dao", "utils", "item", that.addScript);
                let result = await addEvent(that, that.formCreateInject.api, dao, utils, item);
                callback(null);
                // if (result) {
                //     callback(item); // send back adjusted new item
                //     that.fvalue.push(item)
                //     that.updateSourceData()
                // }
                // else {
                //     callback(null); // cancel item creation
                // }
            },
            onMove: async function (item, callback) {
                const moveEvent = window.AsyncFunction("vm", "api", "dao", "utils", "item", that.moveScript);
                let result = await moveEvent(that, that.formCreateInject.api, dao, utils, item);
                if (result) {
                    for (let i = 0; i < that.fvalue.length; i++) {
                        if (that.fvalue[i].id === item.id) {
                            item = that.updateItem(item);
                            that.fvalue[i] = item;
                            that.updateSourceData();
                            callback(item);
                        }
                    }
                } else {
                    callback(null); // cancel item creation
                }
            },
            onMoving: function (item, callback) {
                callback(item); // send back the (possibly) changed item
            },
            onUpdate: async function (item, callback) {
                const updateEvent = window.AsyncFunction("vm", "api", "dao", "utils", "item", that.updateScript);
                let result = await updateEvent(that, that.formCreateInject.api, dao, utils, item);
                callback(null);
                // if (result) {
                //     for(let i = 0;i <that.fvalue.length; i++) {
                //         if(that.fvalue[i].uid === item.uid) {
                //             that.fvalue[i] = item
                //             that.updateSourceData()
                //             callback(item)
                //         }
                //     }
                // }
                // else {
                //     callback(null); // cancel item creation
                // }
            },
            onRemove: async function (item, callback) {
                const removeEvent = window.AsyncFunction("vm", "api", "dao", "utils", "item", that.removeScript);
                let result = await removeEvent(that, that.formCreateInject.api, dao, utils, item);
                if (result) {
                    that.fvalue = that.fvalue.filter((i) => i.id !== item.id);
                    callback(item);
                    that.updateSourceData();
                } else {
                    callback(null); // cancel item creation
                }
            }
        };
        this.options = Object.assign({}, this.initOpitions, this.methodOptions);
        if (this.template != "" && this.template != undefined) {
            let temp = Handlebars.compile(this.template);
            this.options = Object.assign({}, this.options, {
                tooltip: { template: temp }
            });
        }
        // Create a Timeline
        if (this.timeline === "" || this.timeline == null) {
            this.timeline = new Timeline(this.container, this.items, this.options);
        }
        // this.handleItemOverflow(this);
    },
    methods: {
        getValue() {
            return this.fvalue;
        },
        setValue(val) {
            this.fvalue = val;
        },
        setStartTime(startTime) {
            this.startTime = startTime;
        },
        setEndTime(endTime) {
            this.endTime = endTime;
        },
        getItems() {
            if (!this.items) {
                console.info("not found items");
                return undefined;
            }
            return this.items;
        },
        getItem(id) {
            if (!this.items) {
                console.info("not found item");
                return undefined;
            }
            return this.items.get(id);
        },
        setSelection(value, isFocus = undefined, options = undefined) {
            if (this.timeline) {
                let temp_option = {};
                if (!options) {
                    if (isFocus !== undefined) {
                        temp_option = { focus: isFocus };
                    }
                } else {
                    temp_option = options;
                }

                this.timeline.setSelection(value, temp_option);
            }
        },
        async loadSourceData() {
            let newData = utils.getSourceData(this.fDataSource);
            if (JSON.stringify(this.fvalue) !== JSON.stringify(newData)) {
                if (this.timeline != null) {
                    await this.timeline.destroy();
                    this.timeline = null;
                }
                if (!newData || newData == null || !Array.isArray(newData)) {
                    console.info(" newData 数据为空");
                    return;
                }
                console.info("count: ", this.count);
                this.count++;
                this.convertValue(newData);
                this.items = new DataSet(this.fvalue);
                if (this.startTime) {
                    this.options.start = this.startTime;
                }
                if (this.endTime) {
                    this.options.end = this.endTime;
                }
                // 组件最小高度
                if (this.groupItemMinHeight > 0) {
                    const minHeight = parseInt(this.groupItemMinHeight);
                    this.options.minHeight = minHeight * this.groups.length;
                }
                // 项目之间的最小  水平itemHorizontal/垂直itemVertical  边距
                if (this.itemVertical || this.itemHorizontal) {
                    // 垂直
                    let vertical = undefined;
                    if (this.itemVertical > 0) {
                        vertical = this.itemVertical;
                    }
                    // 水平
                    let horizontal = undefined;
                    if (this.itemHorizontal > 0) {
                        horizontal = this.itemHorizontal;
                    }
                    this.options.margin = { item: {} };
                    if (vertical) {
                        this.options.margin.item.vertical = vertical;
                    }
                    if (horizontal) {
                        this.options.margin.item.horizontal = horizontal;
                    }
                }

                this.timeline = new Timeline(this.container, this.items, this.options);
                const that = this;
                if (that.clickScript) {
                    async function onClick(props) {
                        const clickEvent = window.AsyncFunction("vm", "api", "dao", "utils", "props", "timeline", "fvalue", "items", that.clickScript);
                        await clickEvent(that, that.formCreateInject.api, dao, utils, props, that.timeline, that.fvalue, that.items);
                    }
                    this.timeline.on("click", onClick);
                }
                if (that.itemOverScript) {
                    if (!that.hoverTime) {
                        async function onItemOver(events) {
                            const itemOverEvent = window.AsyncFunction("vm", "api", "dao", "utils", "events", "timeline", "fvalue", that.itemOverScript);
                            await itemOverEvent(that, that.formCreateInject.api, dao, utils, events, that.timeline, that.fvalue);
                        }
                        this.timeline.on("itemover", onItemOver);
                    } else {
                        function onItemOver(events) {
                            if (that.hoverTimer != null) {
                                clearTimeout(that.hoverTimer);
                            }
                            that.hoverTimer = setTimeout(async () => {
                                const itemOverEvent = window.AsyncFunction("vm", "api", "dao", "utils", "events", "timeline", "fvalue", that.itemOverScript);
                                await itemOverEvent(that, that.formCreateInject.api, dao, utils, events, that.timeline, that.fvalue);
                            }, that.hoverTime);
                        }
                        this.timeline.on("itemover", onItemOver);
                    }
                }

                if (!that.itemOutScript) {
                    this.timeline.on("itemout", function (events) {
                        // 清除定时器
                        if (that.hoverTimer != null) {
                            clearTimeout(that.hoverTimer);
                        }
                    });
                } else {
                    async function onItemOut(events) {
                        if (that.hoverTimer != null) {
                            clearTimeout(that.hoverTimer);
                        }
                        const itemOutEvent = window.AsyncFunction("vm", "api", "dao", "utils", "events", "timeline", "fvalue", that.itemOutScript);
                        await itemOutEvent(that, that.formCreateInject.api, dao, utils, events, that.timeline, that.fvalue);
                    }
                    this.timeline.on("itemout", onItemOut);
                }
                this.handleItemOverflow(this);
                await this.lvs();
            }
        },
        handleItemOverflow(vm) {
            if (vm.itemOverflow) {
                let elements = document.querySelectorAll(".vis-item-overflow");
                elements.forEach((element) => {
                    element.style.cssText += "overflow: visible !important;";
                });
            }
        },
        async lvs() {
            if (this.groups && Array.isArray(this.groups) && this.groups.length > 0) {
                this.convertGroup();
                if (this.timeline && this.timeline != null) {
                    this.timeline.setGroups(this.groups);
                }
            }
        },
        loadViewSourceData() {
            let val2 = utils.getSourceData(this.fViewDataSource);
            if (val2 && JSON.stringify(this.groups) !== JSON.stringify(val2)) {
                this.groups = val2;
                if (this.groups && Array.isArray(this.groups) && this.groups.length > 0) {
                    this.convertGroup();
                    if (this.timeline && this.timeline != null) {
                        this.timeline.setGroups(this.groups);
                    }
                }
            }
        },
        updateSourceData() {
            utils.updateSourceData(this.fDataSource, this.fvalue);
        },
        convertValue(newData) {
            // 检验显示字段是否存在值
            if (this.itemFields) {
                // 遍历显示字段
                for (let key in this.itemFields) {
                    // 获取 型参 $xxx
                    const itemValue = this.itemFields[key];
                    // 判断是否为字符串
                    if (typeof itemValue === "string" && itemValue) {
                        // 存储 key: itemValue  value = { 索引: 值 }
                        let elementMap = {};
                        for (let ind in newData) {
                            // 使用split函数分割字符串，用"||"作为分隔符。  $aa||$bb||cc
                            let elements = itemValue.split("||");
                            for (let index in elements) {
                                // 使用"$"检测是否含有所需替换的变量。如果有，取出变量名
                                let tempData = elements[index];
                                if (tempData.indexOf("$") === 0) {
                                    // 获取key
                                    const k = tempData.substring(1);
                                    const temp = newData[ind];
                                    elements[index] = temp[k];
                                    const itemKey = `${itemValue}_${ind}`;
                                    elementMap[itemKey] = elements;
                                }
                            }
                        }
                        newData.map((item, ind) => {
                            const itemKey = `${itemValue}_${ind}`;
                            item[key] = elementMap[itemKey].join("");
                        });
                        this.fvalue = newData;
                    } else {
                        // 不是字符串的值不做处理，直接赋值
                        newData.map((item) => {
                            item[key] = itemValue;
                        });
                        this.fvalue = newData;
                    }
                }
            }
        },
        updateItem(item) {
            if (this.itemFields) {
                let newData = [item];
                for (let key in this.itemFields) {
                    const itemValue = this.itemFields[key];
                    // 判断是否为字符串
                    if (typeof itemValue === "string" && itemValue) {
                        // 存储 key: itemValue  value = { 索引: 值 }
                        let elementMap = {};
                        for (let ind in newData) {
                            // 使用split函数分割字符串，用"||"作为分隔符。  $aa||$bb||cc
                            let elements = itemValue.split("||");
                            for (let index in elements) {
                                // 使用"$"检测是否含有所需替换的变量。如果有，取出变量名
                                let tempData = elements[index];
                                if (tempData.indexOf("$") === 0) {
                                    // 获取key
                                    const k = tempData.substring(1);
                                    const temp = newData[ind];
                                    elements[index] = temp[k];
                                    const itemKey = `${itemValue}_${ind}`;
                                    elementMap[itemKey] = elements;
                                }
                            }
                        }
                        newData.map((item, ind) => {
                            const itemKey = `${itemValue}_${ind}`;
                            item[key] = elementMap[itemKey].join("");
                        });
                    }
                }
            }
            return item;
        },
        convertGroup() {
            if (this.groupFields) {
                if (!Array.isArray(this.groups) || this.groups.length < 1) {
                    return;
                }
                for (let key in this.groupFields) {
                    const itemValue = this.groupFields[key];
                    if (typeof itemValue === "string" && itemValue) {
                        let elementMap = {};
                        for (let ind in this.groups) {
                            // 使用split函数分割字符串，用"||"作为分隔符。  $aa||$bb||cc
                            let elements = itemValue.split("||");
                            for (let index in elements) {
                                let tempData = elements[index];
                                // 使用"$"检测是否含有所需替换的变量。如果有，取出变量名
                                if (tempData.indexOf("$") === 0) {
                                    // 获取key
                                    const k = tempData.substring(1);
                                    const temp = this.groups[ind];
                                    elements[index] = temp[k];
                                    const itemKey = `${itemValue}_${ind}`;
                                    elementMap[itemKey] = elements;
                                }
                            }
                        }
                        this.groups.map((item, ind) => {
                            const itemKey = `${itemValue}_${ind}`;
                            item[key] = elementMap[itemKey].join("");
                        });
                    } else {
                        this.groups.map((item) => {
                            item[key] = itemValue;
                        });
                    }
                }
            }
        }
    }
};
</script>

<style></style>