<template>
  <div></div>
</template>

<script>
export default {
  name: 'yes-message',
  props: {
    message: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  methods: {
    showMessage(type, message) {
      if (type == "error") {
        this.$message.error({
          message: message,
        });
      } else if (type == "default") {
        this.$message({
          message: message,
        });
      } else {
        this.$message({
          message: message,
          type: type,
        });
      }
    },
  },
};
</script>

<style>
</style>