<template>
  <!-- 富文本 -->
  <div>
    <editor
      v-model="content"
      :init="init"
      :disabled="disabledValue"
      @input="change"
    ></editor>
  </div>
</template>
 
 
<script>
// import common from "@/api/common";
import { upload } from "@/api/core/dao";
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/icons/default/icons";
import "tinymce/themes/silver";
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/directionality";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/preview";
import "tinymce/plugins/link";
import "tinymce/plugins/hr";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/imagetools";

const utils = require("@/api/core/utils");
export default {
  name: "yes-editor",
  components: {
    Editor,
  },
  props: {
    titleCss: String,
    contentCss: String,
    definedCss: Object,
    dataSource: String,
    formCreateInject: {
      type: Object,
      required: true,
    },
    // value: {
    //   type: String,
    //   default: ""
    // },
    disabled: {
      type: Boolean,
      default: false,
    },
    app: {
      type: String,
    },
    model: {
      type: String,
    },
    model_uid: {
      type: String,
    },

    plugins: {
      type: [String, Array],
      default:
        "preview searchreplace autolink directionality visualblocks visualchars fullscreen image anchor link media template code codesample table charmap hr nonbreaking insertdatetime advlist lists wordcount imagetools textpattern autosave autoresize",
    },
    toolbar: {
      type: [String, Array],
      default:
        "code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link codesample | alignleft aligncenter alignright alignjustify outdent indent formatpainter | \
    styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
    table image media anchor charmap hr pagebreak insertdatetime | fullscreen preview",
    },
  },
  data () {
    return {
      disabledValue: false,
      //初始化配置
      init: {
        //menubar: true, // 菜单栏显隐
        language_url: "static/tinymce/langs/zh_CN.js",
        //language_url: '../../static/tinymce/langs/zh_CN.js', // vue-cli2.x
        language: "zh_CN",
        skin_url: "static/tinymce/skins/ui/oxide",
        //skin_url: '../../static/tinymce/skins/ui/oxide', // vue-cli2.x
        //content_css: '../../static/tinymce/skins/content/default/content.css',// vue-cli2.x
        height: 770,
        min_height: 770,
        max_height: 770,
        toolbar_mode: "wrap",
        plugins: this.plugins,
        toolbar: this.toolbar,
        content_style: "p {margin: 5px 0;}",
        convert_urls:false,
        fontsize_formats: "12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 44px 46px 48px 50px 56px 72px",
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        branding: false,
        // imagetools_cors_hosts: ['img.flower-points.com', 'office.yes-soft.cn', 'http://120.224.164.69:9080/water', 'http://120.224.164.69:9080', 'http://localhost:8080'],
        // imagetools_credentials_hosts: ['img.flower-points.com', 'office.yes-soft.cn', 'http://120.224.164.69:9080/water', 'http://120.224.164.69:9080', 'http://localhost:8080'],

        // 图片上传
        // images_upload_url: '/upload',
        images_upload_handler: (blobInfo, success, failure) => {
          // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
          const formData = new FormData();
          formData.append("file", blobInfo.blob());
          formData.append("app", this.app);
          formData.append("model", this.model);
          formData.append("model_uid", this.model_uid);
          upload(formData)
            .then((res) => {
              if (res.success) {
                const file = res.data.file[0];
                success(file.url);
                return;
              }
            })
            .catch(() => {
              failure("上传出错");
            });
        },
        file_picker_callback: async function (callback, value, meta) {
          if (meta.filetype === "media") {
            var input = document.createElement('input')
            input.style.width="100%";
            input.type = 'file'//添加file类型
            input.style.display="none";
            input.id = 'files';
            document.body.appendChild(input);
            input.click();
            input.onchange = async(event) => {
            // console.log('event', event)
            // let formData = new FormData();
            //     formData.append("files",event.target.files[0])
            //     document.body.removeChild(input);
            //       const res = await request({
            //       url: `/${getRouter().import}/${app}/${view}`,
            //       method: 'post',
            //       data:formData
            //     });

              const formData = new FormData();
              formData.append("file", event.target.files[0]);
              upload(formData).then((res) => {
                console.log('upload...', res)
                  if (res.success) {
                    const file = res.data.file[0];
                    callback(file.url);
                    return;
                  }
                })
                .catch(() => {
                  failure("上传出错");
                });
                // callback(res)
              }
          }
        },
      },
      content: "",
      fDataSource: this.dataSource
    };
  },
  mounted () {
    this.disabledValue = this.disabled
    tinymce.init({});
    this.loadSourceData();
  },
  methods: {
    setDisabled(val) {
      this.disabledValue = val
    },
    // 将组件的值返回
    getValue () {
      return this.content;
    },
    // 修改组件的值
    setValue (val) {
      this.content = val;
    },
    // 读取绑定数据源数据
    loadSourceData () {
      let val = utils.getSourceData(this.fDataSource);
      val = val === null ? "" : val;
      this.content = val !== undefined ? val : this.fvalue;
      // this.content = utils.getSourceData(this.fDataSource);
    },
    // 修改绑定数据源数据
    updateSourceData (val) {
      utils.updateSourceData(this.fDataSource, val);
    },
    change (val) {
      // 绑定数据源时，输入框变化对应修复数据源数据
      if (this.fDataSource) {
        this.updateSourceData(val);
      }
    },
  },
  watch: {
    disabled(val){
      this.disabledValue = val
    },
    // value (newValue) {
    //   this.content = newValue;
    // },
    content (newValue) {
      this.$emit("input", newValue);
      utils.noticeParentDataObject(this.formCreateInject, newValue);
    },
  },
};
</script>
<style scoped lang="scss">
</style>