<template>
    <div>
        <el-dialog
            ref="yes_dialog"
            :width="width"
            :modal="false"
            :top="topHeight"
            :title="dialog_title"
            :modal-append-to-body="false"
            :close-on-click-modal="closeOnClickModal"
            :close-on-press-escape="closeOnPressEscape"
            :visible="dialogVisable || designerVisable"
            :class="designerVisable ? 'dialog-designer' : ''"
            @close="close"
        >
            <div
                class="divStyle"
                :style="{ height: height, maxHeight: maxHeight }"
            >
                <slot />
            </div>
            <span
                slot="footer"
                class="dialog-footer"
                v-show="isShowButton"
            >
                <el-button
                    size="mini"
                    @click="close"
                >
                    {{cancelText}}
                </el-button>
                <el-button
                    size="mini"
                    type="primary"
                    @click="submitForm"
                >
                    {{confirmText}}
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getComponent } from "../api/core/component";
const utils = require("@/api/core/utils");
const dao = require("../api/core/dao");
export default {
    name: "yes-dialog",
    props: {
        dataSource: String,
        formCreateInject: {
            type: Object,
            required: true
        },
        title: {
            type: String
        },
        width: {
            type: String
        },
        height: {
            type: String
        },
        app: {
            type: String
        },
        code: {
            type: String
        },
        clickEvent: {
            type: String
        },
        isShowButton: {
            type: Boolean
        },
        topHeight: {
            type: String
        },
        maxHeight: {
            type: String
        },
        closeEvent: {
            type: String
        },
        closeOnClickModal: {
            type: Boolean,
            default: false
        },
        closeOnPressEscape: {
            type: Boolean,
            default: false
        },
        confirmText: {
            type: String,
            default: "确 定"
        },
        cancelText: {
            type: String,
            default: "取 消"
        },
        // 是否需要阻止重复点击
        preventClick: {
            type: Boolean,
            default: true
        },
        // 阻止重复点击时间[默认2000ms]
        preventTime: {
            type: Number,
            default: 2000
        },
        // 重复点击时, 是否弹出提示
        showPreventTip: {
            type: Boolean,
            default: false
        },
        // 重复点击提示内容
        preventClickTip: {
            type: String,
            default: "执行操作中, 请勿重复点击"
        }
    },
    data() {
        return {
            time: 0,
            style: "",
            top: "15vh",
            dialog_title: "",
            dialogVisable: false,
            designerVisable: false,
            fDataSource: this.dataSource
        };
    },
    created() {
        if (this.$parent && this.$parent.$options._componentTag === "dragTool") {
            this.designerVisable = true;
            return;
        }
        this.load();
    },
    watch: {
        dialog_title(val) {
            this.dialog_title = val;
        }
    },
    mounted() {
        this.dialog_title = this.title;
        this.$refs.yes_dialog.rendered = true;
    },
    methods: {
        setCloseOnClickModal(val) {
            this.closeOnClickModal = val;
        },
        show() {
            this.dialogVisable = true;
        },
        async close() {
            this.dialogVisable = false;
            const Script = window.AsyncFunction("vm", "api", "dao", "utils", this.closeEvent);
            await Script(this, this.formCreateInject.api, dao, utils);
        },
        setTitle(str) {
            if (str == this.dialog_title) {
                return;
            }
            this.dialog_title = str;
        },
        async submitForm() {
            // 重复点击
            if (this.handlePrevent()) return;
            const Script = window.AsyncFunction("vm", "api", "dao", "utils", this.clickEvent);
            await Script(this, this.formCreateInject.api, dao, utils);
        },
        async load() {
            const dialogRule = this.formCreateInject.rule.children[0];
            if (this.app && this.code) {
                let res = await getComponent({ app: this.app, code: this.code });
                if (res.success && res.data != "") {
                    let rule = res.data[0].rule;
                    let ruleJson = JSON.parse(rule);
                    dialogRule.children = ruleJson;
                }
            }
        },
        /**
         * 阻止重复点击
         * T: 阻止 F: 不阻止
         */
        handlePrevent() {
            if (!this.preventClick) return false;
            const nowTime = Date.now();
            const intervalTime = nowTime - this.time;
            // 如果点击间隔小于 this.preventTime 则不执行后续操作
            let result = false;
            if (intervalTime < this.preventTime) {
                // 是否显示 提示信息
                if (this.showPreventTip) {
                    utils.messageWarning(this.preventClickTip);
                }
                result = true;
            }
            // 更新为当前时间戳
            this.time = nowTime;
            return result;
        }
    }
};
</script>

<style>
.dialog-designer {
    position: unset !important;
    height: 80%;
}
/* .dialogPosition {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
} */

.divStyle {
    position: relative;
    display: flex;
    overflow: auto;
}
</style>
<style scoped>
/* .el-dialog__wrapper {
  right: unset !important;
} */
::v-deep .el-dialog__body {
    padding: unset !important;
}
</style>
