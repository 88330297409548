<template>
    <el-checkbox-group
        v-model="fvalue"
        :min="min"
        :max="max"
        :disabled="disabledValue"
        @change="change"
    >
        <el-checkbox
            v-for="item in checkboxItem"
            :key="item[itemKey]"
            :label="item[itemLable]"
        >{{ item[itemValue] }}</el-checkbox>
    </el-checkbox-group>
</template>
<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yes-checkbox",
    props: {
        titleCss: String,
        contentCss: String,
        definedCss: Object,
        dataSource: String,
        viewDataSource: String,
        formCreateInject: {
            type: Object,
            required: true
        },
        itemKey: {
            type: String,
            default: "id"
        },
        itemLable: {
            type: String,
            default: "value"
        },
        itemValue: {
            type: String,
            default: "value"
        },
        min: {
            type: Number
        },
        max: {
            type: Number
        },
        disabled: {
            type: Boolean
        },
        defaultOptios: {
            type: Array,
            default: () => []
        },
        changeEvent: {
            type: String
        }
    },
    data() {
        return {
            fvalue: [],
            checkboxItem: [],
            fDataSource: this.dataSource,
            fViewDataSource: this.viewDataSource,
            disabledValue: false
        };
    },
    mounted() {
        this.disabledValue = this.disabled;
        this.checkboxItem = this.defaultOptios.map((item) => {
            let obj = {};
            obj[this.itemLable] = item.label;
            obj[this.itemValue] = item.value;
            return obj;
        });
        this.loadSourceData();
    },
    watch: {
        disabled(val) {
            this.disabledValue = val;
        },
        fvalue(val) {
            this.$emit("input", val);
            utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
        }
    },
    methods: {
        setDisabled(val) {
            this.disabledValue = val;
        },
        // 将组件的值返回
        getValue() {
            return this.fvalue;
        },
        // 修改组件的值
        setValue(val) {
            this.fvalue = val;
        },
        loadViewSourceData() {
            let val2 = utils.getSourceData(this.fViewDataSource);
            this.checkboxItem = val2 !== undefined ? val2 : this.checkboxItem;
        },
        // 读取绑定数据源数据
        loadSourceData() {
            let val1 = utils.getSourceData(this.fDataSource);
            this.fvalue = val1 !== undefined ? val1 : this.fvalue;
            // this.checkboxItem = utils.getSourceData(this.optionSource);
            // this.fvalue = utils.getSourceData(this.fDataSource);
        },
        // 修改绑定数据源数据
        updateSourceData(val) {
            utils.updateSourceData(this.fDataSource, val);
        },
        async change(val) {
            // 绑定数据源时，输入框变化对应修复数据源数据
            if (this.fDataSource) {
                this.updateSourceData(val);
            }
            if (this.changeEvent) {
                const changeEvent = window.AsyncFunction("vm", "api", "dao", "value", "utils", this.changeEvent);
                await changeEvent(this, this.formCreateInject.api, dao, val, utils);
            }
        }
    }
};
</script>