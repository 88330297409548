<template>
    <canvas ref="qrcode"></canvas>
</template>

<script>
import QRCode from 'qrcode';
const utils = require('@/api/core/utils');
export default {
    name: "yesQrCode",
    props: {
        formCreateInject: {
            type: Object
        },
        dataSource: String,
        // 容错级别
        errorCorrectionLevel: {
            type: String,
            default: "H"
        },
        // 二维码内容
        qrCodeValue: String,
        // 二维码质量 如果请求的类型是image/jpeg或image/web，则介于0和1之间的数字表示图像质量。
        quality: {
            type: Number,
            default: 0.92
        },
        // 二维码留白边距
        margin: {
            type: [String, Number],
            default: 4
        },
        // 二维码宽
        width: {
            type: [String, Number],
            default: 200
        },
        // 生成的二维码类型
        type: {
            type: String,
            default: "image/png"
        },
        // 前景色
        dark: {
            type: String,
            default: "#000000FF"
        },
        // 背景色
        light: {
            type: String,
            default: "#FFFFFFFF"
        },
    },
    data() {
        return {
            fvalue: "",
            fDataSource: this.dataSource
        };
    },
    watch: {
    },
    created() {
        if (this.qrCodeValue != null && this.qrCodeValue != undefined && this.qrCodeValue != "") {
            this.setValue(this.qrCodeValue);
        }
    },
    mounted() {
        this.initQrCode();
    },
    methods: {
        getValue() {
            return this.fvalue;
        },
        setValue(val) {
            this.fvalue = val;
            this.initQrCode();
        },
        loadSourceData() {
            this.setValue(utils.getSourceData(this.fDataSource));
            this.initQrCode();
        },
        updateSourceData() {
            utils.updateSourceData(this.fDataSource, this.fvalue);
        },
        setFvalue(val) {
            this.fvalue = val;
        },
        initQrCode() {
            if (!this.fvalue || JSON.stringify(this.fvalue) === '{}') {
                return;
            }
            let opts = {
                errorCorrectionLevel: this.errorCorrectionLevel,
                type: this.type,
                quality: this.quality,
                margin: this.margin,
                width: this.width,
                height: this.width,
                text: this.fvalue,
                color: {
                    dark: this.dark,
                    light: this.light
                }
            };
            QRCode.toCanvas(this.$refs.qrcode, this.fvalue, opts, function (error) {
                if (error) console.error('gen-QrCode-Error', error)
            });
        }
    },
}
</script>
  