<template>
  <el-carousel
    :height="height"
    :initial-index="currentIndex"
    trigger="click"
    :autoplay="autoplay"
    :interval="interval"
    :indicator-position="indicatorPosition"
    :arrow="arrow"
    :type="type"
    :loop="loop"
    :direction="direction"
  >
    <slot></slot>
  </el-carousel>
</template>

<script>
// const utils = require("@/api/core/utils");
export default {
  name: "yes-carousel",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    height: String,
    initialIndex: Number,
    autoplay: Boolean,
    interval: Number,
    indicatorPosition: String,
    arrow: String,
    type: String,
    loop: Boolean,
    direction: String,
  },
  data () {
    return {
      currentIndex: 0,
      fDataSource: this.dataSource
    };
  },
  created () {
    this.currentIndex = this.initialIndex - 1;
  },
  watch: {},
  methods: {
    addCarousel () {
      if (this.$parent.$options._componentTag === 'dragTool') {
        this.$parent.$emit('addChild')
        return;
      }
      this.addRule();
    },
    addRule (data = {}, rule = []) { //添加子组件
      const carouselRule = this.formCreateInject.rule;
      const carouselItemRule = {
        "type": "el-carousel-item",
        "name": data.itemId,
        "props": {
          "name": data.name,
          "label": data.label,
        },
        children: rule,
        "_fc_drag_tag": "yes-carousel-item",
        "hidden": false,
        "display": true
      }
      carouselRule.children.push(carouselItemRule)
    },
  },
};
</script>

<style>
</style>