<template>
  <el-col :span="span" :offset="offset" :push="push" :pull="pull">
    <slot/>
  </el-col>
</template>

<script>
export default {
  name: 'yes-row-col',
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    span: {
      type: Number
    },
    offset: {
      type: Number
    },
    push: {
      type: Number
    },
    pull: {
      type: Number
    }
  }
}
</script>
