<template>
  <el-collapse-item :name="name" :title="title" :disabled="fdisabled">
    <slot />
  </el-collapse-item>
</template>

<script>
// import uniqueId from "@form-create/utils/lib/unique";
// const utils = require("@/api/core/utils");
// const dao = require('@/api/core/dao');
export default {
  name: "yesCollapseItem",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    name: String,
    title: String,
    disabled: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '48px'
    },
    padding: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: '13px'
    },
    fontWeight: {
      type: String,
      default: '500'
    },
    fontFamily: {
      type: String,
      default: ''
    },
    paddingLeft: {
      type: String,
      default: '15px'
    }
  },
  data() {
    return {
      fdisabled: this.disabled
    };
  },
  watch: {
    name(val) {
      this.formCreateInject.rule.name = val;
    },
  },
  created() {
  },
  mounted() {
    let ecih = this.$el.querySelector('.el-collapse-item__header');
    ecih.style.height = this.height;
    ecih.style.fontSize = this.fontSize;
    ecih.style.fontWeight = this.fontWeight;
    ecih.style.fontFamily = this.fontFamily;
    if (this.padding) {
      ecih.style.padding = this.padding;
    } else {
      ecih.style.paddingLeft = this.paddingLeft;
    }
  },
  methods: {
    setDisabled(val) {
      this.fdisabled = val;
    }
  },
}
</script>