<template>
  <el-breadcrumb
    :separator="separator"
    :separator-class="separatorClass"
  >
    <slot />
  </el-breadcrumb>

</template>

<script>
// const dao = require('@/api/core/dao');
const utils = require('@/api/core/utils');
// const common = require('../utils/common');
export default {
  name: 'yes-breadcrumb',
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    separator: {
      type: String,
      default: '',
    },
    separatorClass: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      fvalue: [],
      fDataSource: this.dataSource
    }
  },
  methods: {
    getValue () {
      return this.fvalue;
    },
    // 修改组件的值
    setValue (val) {
      this.fvalue = val;
    },
    // 读取绑定数据源数据
    loadSourceData () {
      this.fvalue = utils.getSourceData(
        this.fDataSource
      );
    },
    // 修改绑定数据源数据
    updateSourceData () {
      utils.updateSourceData(
        this.fDataSource,
        this.fvalue
      );
    },
  }
}
</script>

<style>
</style>