<template>
  <el-result
    :title="title"
    :sub-title="subTitle"
    :icon="icon"
  >
    <!-- <slot /> -->
    <div
      v-if="showButton"
      slot="extra"
    >
      <el-button
        type="primary"
        size="medium"
        @click="buttonClick"
      >{{buttonText}}</el-button>
    </div>
  </el-result>
</template>

<script>
// import uniqueId from "@form-create/utils/lib/unique";
const utils = require("@/api/core/utils");
const dao = require('@/api/core/dao');
export default {
  name: "yesResult",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    title: String,
    subTitle: String,
    icon: String,
    showButton: Boolean,
    buttonText: String,
    clickEvent: String,
  },
  data () {
    return {};
  },
  watch: {},
  methods: {
    async buttonClick () {
      const clickEvent = window.AsyncFunction("vm", "api", 'dao', 'fApi', 'utils', this.clickEvent);
      await clickEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils)
    }
  },
}
</script>
