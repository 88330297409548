<template>
  <div id="radarMain" :style="{ height: height, width: width }"></div>
</template>

<script>
const utils = require("@/api/core/utils");
// const dao = require('@/api/core/dao');
import * as echarts from "echarts";

export default {
  name: "yes-radar",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    //名称字段
    nameField: {
      type: String,
      default: "",
    },
    //值字段
    valueField: {
      type: String,
      default: "",
    },
    //属性字段
    typeField: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "radar",
    },
    marginTop: {
      type: String,
      default: "10%",
    },
    marginLeft: {
      type: String,
      default: "3%",
    },
    marginRight: {
      type: String,
      default: "3%",
    },
    marginBottom: {
      type: String,
      default: "3%",
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    canSaveImage: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      viewData: [],
      legendList: [],
      indicatorList: [],
      radarList: [],
      fDataSource: this.dataSource,
    };
  },
  // created() {
  //   this.initEcharts();
  // },
  watch: {
    viewData() {
      this.parse();
    },
  },
  methods: {
    // 方法
    initEcharts() {
      // 基于准备好的dom，初始化echarts实例

      // 新建一个promise对象
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      //然后异步执行echarts的初始化函数
      newPromise.then(() => {
        //	此dom为echarts图标展示dom
        var myChart = echarts.init(document.getElementById("radarMain"));
        // 绘制图表
        myChart.setOption({
          title: {
            text: this.title,
          },
          grid: {
            top: this.marginTop,
            left: this.marginLeft,
            right: this.marginRight,
            bottom: this.marginBottom,
            //containLabel 为 true 的时候：
            // grid.left grid.right grid.top grid.bottom grid.width grid.height 决定的是包括了坐标轴标签在内的所有内容所形成的矩形的位置。
            // 这常用于『防止标签溢出』的场景，标签溢出指的是，标签长度动态变化时，可能会溢出容器或者覆盖其他组件。
            containLabel: true,
          },
          legend: {
            data: this.legendList,
          },
          tooltip: {
            show: this.showTooltip,
            trigger: "item",
          },
          toolbox: {
            show: this.canSaveImage,
            feature: {
              //保存图片
              saveAsImage: {},
            },
          },
          radar: {
            //圆形坐标系试图
            // shape: 'circle',
            indicator: this.indicatorList,
          },
          series: [
            {
              //name: "Budget vs spending",
              data: this.radarList,
              type: "radar",
            },
          ],
        });
      });
    },
    // 将组件的值返回
    getValue() {
      //   return this.href;
    },
    // 修改组件的值
    setValue() {
      //   this.href = val;
    },
    // 读取绑定数据源数据
    loadSourceData() {
      let da = utils.getSourceData(this.fDataSource);
      this.viewData = da !== undefined ? da : this.data;
      console.log("this.viewData-----", this.viewData);
    },
    parse() {
      console.log("this.viewData", this.viewData);
      this.viewData.forEach((item, index) => {
        if (index == 0) {
          this.indicatorList.push({
            name: item[this.typeField],
            max: 1,
          });
          this.radarList.push({
            name: item[this.nameField],
            value: [
              {
                name: item[this.typeField],
                num: item[this.valueField],
              },
            ],
          });
          return;
        }
        //遍历所有的坐标没找到同名的坐标 就添加坐标
        //找到坐标退出循环不做动作
        let indicatorCheck = false;
        for (let i in this.indicatorList) {
          if (this.indicatorList[i].name == item[this.typeField]) {
            indicatorCheck = false;
            break;
          } else {
            indicatorCheck = true;
          }
        }
        if (indicatorCheck) {
          this.indicatorList.push({
            name: item[this.typeField],
            max: 1,
          });
        }
        //遍历所有组 找到同名就在属性值添加数组并退出循环
        //没找到就添加新的组
        let redarCheck = false;
        for (let i in this.radarList) {
          if (this.radarList[i].name == item[this.nameField]) {
            this.radarList[i].value.push({
              name: item[this.typeField],
              num: item[this.valueField],
            });
            redarCheck = false;
            break;
          } else {
            redarCheck = true;
          }
        }
        if (redarCheck) {
          this.radarList.push({
            name: item[this.nameField],
            value: [
              {
                name: item[this.typeField],
                num: item[this.valueField],
              },
            ],
          });
        }
      });
      this.sorter();
    },
    sorter() {
      let data = [];
      for (let i in this.radarList) {
        data[i] = [];
        this.radarList[i].value.forEach(() => {
          data[i].push(0);
        });
      }
      this.radarList.forEach((item, i1) => {
        this.legendList.push(item.name);
        this.indicatorList.forEach((xa, i2) => {
          item.value.forEach((ya, i3) => {
            if (xa.name == ya.name) {
              let num = ya.num.replace("%", "");
              num = Number(num / 100);
              data[i1][i2] = num;
              if (this.radarList[i1].value.length - 1 == i3) {
                this.radarList[i1].value = data[i1];
              }
              return;
            }
          });
        });
      });
      this.initEcharts();
    },
    // 修改绑定数据源数据
    updateSourceData() {},
  },
};
</script>
<style scoped></style>
