<template>
    <el-card shadow="never" class="aui-card--fill">
      <el-calendar v-model="calendarValue">
        <template slot="dateCell" slot-scope="{date, data}">
          <div :class="data.isSelected ? 'is-selected' : ''" @click="handleClick(data)" style="padding-bottom: 50px;">
            {{ data.day.split('-').slice(1).join('-') }}
            <div v-for="item in calendarList" :key="item.index" :class="item.isHoliday ? 'is-holiday' : ''">
                <div v-if="item.date == data.day.split('-').slice(1).join('-')"> 
                    <p style="font-size: 14px;">
                        {{item.hour}}
                    </p>
                    <p style="font-size: 14px;word-break: break-all;">
                        {{item.name}}
                    </p>
                    
                </div>
                <div v-if="item.date == data.day.split('-').slice(1).join('-') && item.isHoliday"> 
                    <p style="font-size: 14px;padding: 25px 0px;margin-top: -23px !important;color: #c10909;">
                        休假
                    </p>
                    
                </div>
            </div>
          </div>
        </template>
      </el-calendar>
   
    </el-card>
  </template>
   
  <script>
  const utils = require("@/api/core/utils");
  const dao = require("@/api/core/dao");
    export default {
      props: {
        formCreateInject: {
            type: Object,
            required: true,
        },
        dataSource: {
            type: String
        },
        clickEvent: String
      },
      data() {
        return {
          calendarValue: new Date(),
          calendarList:[],
          fDataSource:this.dataSource,
          selectedDate:''
        };
      },
      computed: {
   
      },
      created() {
        this.calendarList = this.getValue();
      },
      mounted() {
      },
      watch: {
      },
      methods: {
        // 将组件的值返回
        getValue() {
            return this.calendarList;
        },
        // 修改组件的值
        setValue(val) {
            this.calendarList = val;
        },
        loadSourceData() {
            this.calendarList = utils.getSourceData(this.fDataSource);
        },
        updateSourceData() {
            utils.updateSourceData(this.fDataSource, this.calendarList);
        },
        async handleClick(data){
          this.selectedDate = data.day
        const click = window.AsyncFunction(
          "vm",
          "api",
          "dao",
          "fApi",
          "utils",
          this.clickEvent
        );
        await click(
          this,
          this.formCreateInject.api,
          dao,
          this.formCreateInject,
          utils
        );
      },
       // 设置日历组件显示的时间范围
       setDefaultDate(val) {
          if (val) {
              this.calendarValue = val;
          }
        },
      },
      components: {},
    };
  </script>
   
  <style scoped lang="scss">
    .is-selected {
      color: #1989FA;
    }
   
    .item_config {
      background-color: #000;
      color: #fff;
      padding: 10px;
      box-sizing: border-box;
      margin-top: 20px;
      border-radius: 10px;
   
      ._key {
        float: left;
        width: 70px;
        text-align: right
      }
   
      ._value {
        width: 100%;
        padding-left: 80px;
        text-align: left;
   
      }
      .el-calendar-table .el-calendar-day {
        height: 110px !important;
      }
    }
    .is-holiday {
      background-color:#f0c1bb;
    }
  </style>