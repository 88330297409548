<template>
    <el-cascader
        v-model="fvalue"
        :size="size"
        :props="itemProp"
        :options="options"
        :debounce="debounce"
        :clearable="clearable"
        :separator="separator"
        :filterable="filterable"
        :disabled="disabledValue"
        :placeholder="placeholder"
        :filter-method="filterMethod"
        :collapse-tags="collapseTags"
        :show-all-levels="showLevels"
        @change="handleChange"
    ></el-cascader>
</template>
<script>
const utils = require("@/api/core/utils");
const dao = require("@/api/core/dao");
export default {
    name: "yes-cascader",
    props: {
        titleCss: String,
        contentCss: String,
        definedCss: Object,
        dataSource: String,
        optionSource: String,
        formCreateInject: {
            type: Object,
            required: true,
        },
        // 配置选项，具体见 https://element.eleme.cn/#/zh-CN/component/cascader
        itemProp: {},
        // 尺寸
        size: {
            type: String,
        },
        // 是否禁用
        disabled: {
            type: Boolean,
        },
        // 是否支持清空选项
        clearable: {
            type: Boolean,
        },
        // 输入框中是否显示选中值的完整路径
        showLevels: {
            type: Boolean,
        },
        // 当选中节点变化时触发
        changeEvent: {
            type: String,
        },
        // 输入框占位文本
        placeholder: {
            type: String,
        },
        // 选项分隔符
        separator: {
            type: String,
            default: " / ",
        },
        // 是否可搜索选项
        filterable: {
            type: Boolean,
        },
        // 自定义搜索逻辑
        filterMethod: {
            type: String,
        },
        // 搜索关键词输入的去抖延迟，毫秒
        debounce: {
            type: Number,
            default: 300,
        },
        // 多选模式下是否折叠Tag
        collapseTags: {
            type: Boolean,
        },
        // handleChange(val) {
        //     this.$emit("input", val);
        //     this.$emit("change", val);
        //     utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
        // },
    },
    data() {
        return {
            fvalue: [],
            options: [],
            fDataSource: this.dataSource,
            disabledValue: false,
        };
    },
    mounted() {
        this.disabledValue = this.disabled;
        this.loadSourceData();
    },
    watch: {
        disabled(val) {
            this.disabledValue = val;
        },
        fvalue(val) {
            this.$emit("input", val);
            utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
        },
    },
    methods: {
        setDisabled(val) {
            this.disabledValue = val;
        },
        // 将组件的值返回
        getValue() {
            return this.fvalue;
        },
        // 修改组件的值
        setValue(val) {
            this.fvalue = val;
        },
        // 读取绑定数据源数据
        loadSourceData() {
            let val2 = utils.getSourceData(this.optionSource);
            this.options = val2 !== undefined ? val2 : this.fvalue;
            let val1 = utils.getSourceData(this.fDataSource);
            this.fvalue = val1 !== undefined ? val1 : this.fvalue;
            // this.options = utils.getSourceData(this.optionSource);
            // this.fvalue = utils.getSourceData(this.fDataSource);
        },
        // 修改绑定数据源数据
        updateSourceData(val) {
            utils.updateSourceData(this.fDataSource, val);
        },
        async handleFilter(node, keyword) {
            if (!this.filterMethod) {
                return;
            }
            const filterEvent = window.AsyncFunction("vm", "api", "dao", "node", "keyword", "utils", this.filterMethod);
            return await filterEvent(this, this.formCreateInject.api, dao, node, keyword, utils);
        },
        async handleChange(val) {
            // 绑定数据源时，输入框变化对应修复数据源数据
            if (this.fDataSource) {
                this.updateSourceData(val);
            }
            if (this.changeEvent) {
                const changeEvent = window.AsyncFunction("vm", "api", "dao", "value", "utils", this.changeEvent);
                await changeEvent(this, this.formCreateInject.api, dao, val, utils);
            }
        },
    },
};
</script>