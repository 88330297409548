<template>
    <el-table-column
        :type="type"
        :prop="prop"
        :fixed="fixed"
        :index="index"
        :label="label"
        :align="align"
        :sort-by="sortBy"
        :width="widthShow"
        :filters="localFilters"
        :sortable="sortable"
        :resizable="resizable"
        :column-key="columnKey"
        :class-name="className"
        :selectable="selectable"
        :sort-orders="sortOrders"
        :sort-method="sortMethod"
        :min-width="minWidthShow"
        :header-align="headerAlign"
        :render-header="renderHeader"
        :filter-method="localFfilterMethod"
        :formatter="formatterFunction"
        :filtered-value="filteredValue"
        :filter-multiple="filterMultiple"
        :label-class-name="labelClassName"
        :filter-placement="filterPlacement"
        :reserve-selection="reserveSelection"
        :show-overflow-tooltip="showOverflowTooltip"
        key="name-column-{{ tableKey }}"
    >
        <template :slot-scope="scope">
            <slot></slot>
        </template>
    </el-table-column>
</template>

<script>
const utils = require("@/api/core/utils");
export default {
    name: "yes-table-column",
    props: {
        viewDataSource: String,
        formCreateInject: {
            type: Object,
            required: true
        },
        type: {
            //对应列的类型。如果设置了 selection 则显示多选框；如果设置了 index 则显示该行的索引（从 1 开始计算）；如果设置了 expand 则显示为一个可展开的按钮
            type: String
        },
        index: {
            // 如果设置了 type=index，可以通过传递 index 属性来自定义索引
            type: [Number, Function]
        },
        columnKey: {
            // column 的 key，如果需要使用 filter-change 事件，则需要此属性标识是哪个 column 的筛选条件
            type: String
        },
        label: {
            // 列的显示文字
            type: String
        },
        prop: {
            // 对应列内容的字段名，也可以使用 property 属性
            type: String
        },
        width: {
            // 对应列的宽度
            type: String
        },
        widthLg: {
            type: String
        },
        widthMd: {
            type: String
        },
        widthXs: {
            type: String
        },
        minWidth: {
            // 对应列的最小宽度
            type: String
        },
        minWidthLg: {
            type: String
        },
        minWidthMd: {
            type: String
        },
        minWidthXs: {
            type: String
        },
        fixed: {
            // 对应列是否固定在左侧或者右侧
            type: [String, Boolean],
            default: false
        },
        renderHeader: {
            // 列标题 Label 区域渲染使用的 Function
            type: Function
        },
        sortable: {
            // 对应列是否可排序
            type: [Boolean, String],
            default: false
        },
        sortMethod: {
            // 对应列的排序方法
            type: Function
        },
        sortBy: {
            // 对应列的排序依据
            type: [String, Array, Function]
        },
        sortOrders: {
            // 对应列的排序方向
            type: Array
        },
        resizable: {
            // 对应列是否可以通过拖动改变宽度（需要在 el-table 上设置 border 属性为真）
            type: Boolean,
            default: true
        },
        formatter: {
            // 对应列的格式化方法
            type: String
        },
        showOverflowTooltip: {
            // 对应列的内容是否需要 tooltip 显示
            type: Boolean,
            default: true
        },
        align: {
            // 对应列的文字对齐方式
            type: String,
            default: "left"
        },
        headerAlign: {
            // 对应列的头部文字对齐方式
            type: String,
            default: "center"
        },
        className: {
            // 对应列的自定义类名
            type: String
        },
        labelClassName: {
            // 对应列的标题自定义类名
            type: String
        },
        selectable: {
            // 对应列是否可以选择
            type: Function
        },
        reserveSelection: {
            // 对应列是否保留选中状态
            type: Boolean,
            default: false
        },
        filters: {
            // 对应列的筛选项
            type: Array
        },
        filterPlacement: {
            // 过滤弹出框的定位
            type: String
        },
        filterMultiple: {
            // 对应列的筛选是否多选
            type: Boolean,
            default: true
        },
        filterMethod: {
            // 对应列的筛选方法
            type: Function
        },
        filteredValue: {
            // 对应列的筛选值
            type: Array
        },
        filterHandler: {
            // 对应新列的筛选方法
            type: Boolean
        }
    },
    data() {
        return {
            widthShow: "",
            minWidthShow: "",
            formatterFunction: null,
            localFilters: this.viewDataSource,
            localFfilterMethod: this.filterMethod,
            tableKey: 0
        };
    },
    computed: {
        isDesigner() {
            if (this.formCreateInject.children) {
                return this.formCreateInject.children.findIndex((item) => item.type === "DragTool") > -1;
            }
            return false;
        }
    },
    watch: {
        // formatter(val) {
        //   this.formatterFunction = eval('(function (a, b, c){return ' + val + '}())');
        //   this.formatter.h = this.$createElement;
        //   console.log("this.formatterFunction", this.formatterFunction)
        // }
        // useSolt (val) {
        //   if (this.formCreateInject.children) {
        //     this.formCreateInject.children.forEach(item => {
        //       if (item.type === 'DragTool') {
        //         console.log("DragTool......", item)
        //         item.props.drag = val;
        //       }
        //     })
        //   }
        // }
        viewDataSource: {
            handler(newFilters) {
                if (newFilters) {
                    this.loadFilterSourceData(newFilters);
                }
            },
            deep: true
        },
        prop(val) {
            this.formCreateInject.rule.name = val;
        }
    },
    async created() {
        let winWidth = document.documentElement.clientWidth;
        if (this.viewDataSource) {
            this.loadFilterSourceData(this.viewDataSource);
        }
        this.widthShow = this.width;
        this.minWidthShow = this.minWidth;
        // 大屏适配
        if (this.widthLg && winWidth >= 1200) {
            this.widthShow = this.widthLg;
        }
        if (this.minWidthLg && winWidth >= 1200) {
            this.minWidthShow = this.minWidthLg;
        }
        // 中屏适配
        if (this.widthMd && winWidth < 1200 && winWidth >= 768) {
            this.widthShow = this.widthMd;
        }
        if (this.minWidthMd && winWidth < 1200 && winWidth >= 768) {
            this.minWidthShow = this.minWidthMd;
        }
        // 小屏适配
        if (this.widthXs && winWidth < 768) {
            this.widthShow = this.widthXs;
        }
        if (this.minWidthXs && winWidth < 768) {
            this.minWidthShow = this.minWidthXs;
        }
        if (this.formatter) {
            this.formatterFunction = (row, column, cell, index) => {
                return this.formatterData(row, column, cell, index);
            };
        }
        if (this.filterHandler) {
            this.localFfilterMethod = (value, row, column) => {
                const property = column["property"];
                return row[property] === value;
            };
        }
    },
    mounted() {
        // if (this.$parent.$parent.$parent.$options._componentTag === 'dragTool') {
        //   this.enableRuleWatch();//启用监听事件，这样子才能监听到拖拽改变去实时刷新
        // }
    },
    methods: {
        //开启监听当前组件规则变化
        // enableRuleWatch() {
        //     this.rule = this.formCreateInject.rule;

        //     let designer = this.$parent; //design对象
        //     while (!designer.getRule) {
        //         designer = designer.$parent;
        //     }
        //     this.$watch(
        //         "rule",
        //         () => {
        //             designer.setRule(designer.getRule());
        //         },
        //         {
        //             deep: true
        //             // immediate: true,
        //         }
        //     );
        // },
        formatterData(row, column, cell, index) {
            const formatterFunction = window.Function("vm", "api", "utils", "row", "column", "cell", "index", this.formatter);
            return formatterFunction(this, this.formCreateInject.api, utils, row, column, cell, index);
        },
        // 读取绑定数据源数据
        loadFilterSourceData(newFilters) {
            const value = utils.getSourceData(newFilters);
            this.localFilters = Array.isArray(value) ? value.map((text) => ({ text, value: text })) : [];
        }
    }
};
</script>

<style></style>