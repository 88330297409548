<template>
  <el-avatar
    :src="fvalue"
    :size="size"
    :shape="shape"
    :alt="alt"
    :fit="fit"
  >
    <slot></slot>
  </el-avatar>
</template>

<script>
const utils = require("@/api/core/utils");
export default {
  name: 'yes-avatar',
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    size: String,
    shape: String,
    alt: String,
    fit: String,
    src: String,
  },
  data () {
    return {
      fvalue: "",
      fDataSource: this.dataSource
    };
  },
  created () {
    this.fvalue = this.src;
  },
  methods: {
    getValue () {
      return this.fvalue;
    },
    setValue (val) {
      this.fvalue = val;
    },
    loadSourceData () {
      this.fvalue = utils.getSourceData(
        this.fDataSource
      );
    },
    updateSourceData () {
      utils.updateSourceData(this.fDataSource, this.fvalue);
    },
  },
}
</script>
