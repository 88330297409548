<template>
  <el-dialog
    :visible.sync="viewShow"
    width="800px"
    append-to-body
    @closed="close"
  >
    <form-create
      name="form"
      :option="dataOptions"
      :rule="dataRule"
      v-model="fApi"
      :value.sync="value"
    />
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
    },
    rule: {
      type: Array,
    }
  },
  watch: {
    show (val) {
      val == true ? (this.viewShow = true) : "";
    },
    options () {
      this.dataOptions = this.options
    },
    rule () {
      this.dataRule = this.rule
    }
  },
  data () {
    return {
      viewShow: false, // 是否显示dialog
      dataOptions: {},
      dataRule: [],
      fApi: {},
      value:{"kn75ushiu9tu":"hello word!!"}
    }
  },
  mounted () {
  },
  methods: {
    close () {
      this.$emit("finish");
      this.viewShow = false;
    },
  },
}
</script>
