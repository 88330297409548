<template>
    <div class="thmlbox">
    </div>
  </template>
  
<script>
const utils = require("@/api/core/utils");
export default {
  name: "yesHtml",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    htmlDiv: {
      type: String
    },
  },
  data () {
    return {
      htmlStr: "",
      fDataSource: this.dataSource
    };
  },
  created () {;
    if (this.htmlDiv) {
      this.htmlStr = this.htmlDiv
    } else {
      this.htmlStr = this.getValue();
    }
  },
  watch: {
    htmlStr(){
      let thmlbox = document.getElementsByClassName('thmlbox')
      thmlbox[0].innerHTML = this.htmlStr
    },
  },
  methods: {
      // 将组件的值返回
      getValue() {
          return this.htmlStr;
      },
      // 修改组件的值
      setValue(val) {
          this.htmlStr = val;
      },
      // 读取绑定数据源数据
      loadSourceData() {
          this.htmlStr = utils.getSourceData(
              this.fDataSource
          );
      },
      // 修改绑定数据源数据
      updateSourceData() {
          utils.updateSourceData(this.fDataSource, this.htmlStr);
      }
  },
}
</script>
<style>
p {
  margin-top:0!important;
  margin-bottom:0!important;
}
</style>