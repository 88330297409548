<template>
    <div class="block">
        <el-pagination
            ref="_yes_Pagination"
            :total="total"
            :small="small"
            :disabled="disabled"
            :prev-text="prevText"
            :next-text="nextText"
            :page-sizes="pageSizes"
            :layout="currentLayout"
            :background="background"
            :current-page="currentPage"
            :page-size="currentPageSize"
            :pager-count="currentPagerCount"
            :hide-on-single-page="hideOnSinglePage"
            @prev-click="handlePrevClick"
            @next-click="handleNextClick"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
            <span>{{currentPage}}</span>
        </el-pagination>
    </div>
</template>
<script>
const utils = require("@/api/core/utils");
const dao = require("../api/core/dao");
export default {
    name: "yes-pagination",
    props: {
        formCreateInject: {
            type: Object,
            required: true
        },
        dataSource: String,
        background: {
            type: Boolean,
            default: false
        },
        pagerCount: {
            type: [String, Number],
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        prevText: {
            type: String,
            default: ""
        },
        nextText: {
            type: String,
            default: ""
        },
        hideOnSinglePage: {
            type: Boolean,
            default: false
        },
        pageSizes: {
            type: Array
        },
        pageSize: {
            type: [String, Number],
            default: "20"
        },
        layout: {
            type: String,
            default: ""
        },
        sizeChangeEvent: {
            type: String,
            default: ""
        },
        pageChangeEvent: {
            type: String,
            default: ""
        },
        prevEvent: {
            type: String,
            default: ""
        },
        nextEvent: {
            type: String,
            default: ""
        }
    },
    methods: {
        // 将组件的值返回
        getValue() {
            return this.fvalue;
        },
        // 修改组件的值
        setValue(val) {
            this.fvalue = val;
        },
        // 读取绑定数据源数据
        loadSourceData() {
            const total = this.formCreateInject.api.exec(this.fDataSource, "getTotal");
            this.total = total;
            const pageQuery = this.formCreateInject.api.exec(this.fDataSource, "getPageQuery");
            this.currentPage = pageQuery.page;
            if (pageQuery.size !== this.currentPageSize) {
                this.loadView();
                let resPage = {
                    page: this.currentPage,
                    size: this.currentPageSize
                };
                this.formCreateInject.api.exec(this.fDataSource, "setPageQuery", resPage);
            }
        },
        // 修改绑定数据源数据
        updateSourceData() {},
        loadView() {
            // 获取同步数据源的是否默认加载属性
            const firstLoad = this.formCreateInject.api.exec(this.fDataSource, "getFirstLoad");
            // 判断是否第一次加载
            // 如果是第一次加载且不默认加载则放弃触发load
            if (!this.isInitLoad && !firstLoad) {
                this.isInitLoad = true;
                return;
            }
            this.formCreateInject.api.exec(this.fDataSource, "load", this.currentPage, this.currentPageSize);
        },
        async handleSizeChange(val) {
            this.currentPageSize = val;
            this.currentPage = 1;
            this.isInitLoad = true;
            await this.loadView();
            if (this.sizeChangeEvent) {
                const func = window.AsyncFunction("vm", "size", "dao", "api", "utils", this.sizeChangeEvent);
                await func(this, val, dao, this.formCreateInject.api, utils);
            }
        },
        async handleCurrentChange(val) {
            this.currentPage = val;
            this.isInitLoad = true;
            await this.loadView();
            if (this.pageChangeEvent) {
                const func = window.AsyncFunction("vm", "page", "dao", "api", "utils", this.pageChangeEvent);
                await func(this, val, dao, this.formCreateInject.api, utils);
            }
        },
        async handlePrevClick(val) {
            this.currentPage = val;
            this.isInitLoad = true;
            await this.loadView();
            if (this.prevEvent) {
                const func = window.AsyncFunction("vm", "page", "dao", "api", "utils", this.prevEvent);
                await func(this, val, dao, this.formCreateInject.api, utils);
            }
        },
        async handleNextClick(val) {
            this.currentPage = val;
            this.isInitLoad = true;
            await this.loadView();
            if (this.nextEvent) {
                const func = window.AsyncFunction("vm", "page", "dao", "api", "utils", this.nextEvent);
                await func(this, val, dao, this.formCreateInject.api, utils);
            }
        },
        getPagination() {
            return this.$refs._yes_Pagination;
        }
    },
    mounted() {
        this.currentPageSize = this.pageSize ? parseInt(this.pageSize) : this.currentPageSize;
        this.currentPagerCount = this.pagerCount ? parseInt(this.pagerCount) : this.currentPagerCount;
        if (this.layout) {
            const list = ["prev", "pager", "next", "jumper", "->", "total", "slot"];
            const tempLayout = this.layout
                .split(",")
                .filter((item) => list.indexOf(item) > -1)
                .join(",");
            this.currentLayout = tempLayout.length > 0 ? tempLayout : this.currentLayout;
        }
    },
    data() {
        return {
            total: 0,
            currentPage: 1,
            isInitLoad: false,
            currentPageSize: 20,
            currentPagerCount: 5,
            fDataSource: this.dataSource,
            currentLayout: "total,sizes,prev,pager,next,jumper"
        };
    }
};
</script>