<template>
    <div>
        <div
            v-if="id"
            :id="id"
        ></div>
    </div>
</template>

<script>
const utils = require("@/api/core/utils");
export default {
    name: "yesAliPlayer",
    props: {
        formCreateInject: {
            type: Object,
        },
        dataSource: String,
        width: {
            type: String,
            default: "100%",
        },
        height: {
            type: String,
            default: "100%",
        },
        preLoad: {
            type: Boolean,
            default: true,
        },
        isEnglish: {
            type: Boolean,
            default: false,
        },
        delayTime: {
            type: Number,
            default: 6,
        },
        delayText: {
            type: String,
            default: "",
        },
        delayTimeMultiple: {
            type: Number,
            default: 1.3,
        },
        autoPlayDelayDisplayText: {
            type: String,
            default: ".",
        },
    },
    data() {
        return {
            fvalue: "",
            palyer: null,
            id: undefined,
            fWidth: "",
            fHeight: "",
            fDelayText: this.delayText,
            fDelayTime: this.delayTime,
            fDataSource: this.dataSource,
            fDelayTimeMultiple: this.delayTimeMultiple,
            fTime: 1000,
        };
    },
    created() {
        this.id = this.getId();
        this.fDelayTime = Math.round(this.delayTime * this.delayTimeMultiple);
        if (!this.fWidth) {
            this.fWidth = this.width;
        }
        if (!this.fHeight) {
            this.fHeight = this.height;
        }
        const fdtm = this.fDelayTimeMultiple;
        if (fdtm && fdtm > 0) {
            this.fTime = 1000 * fdtm;
        }
    },
    beforeDestroy() {
        if (this.palyer != null) {
            this.player.pause();
            this.player.dispose();
            this.palyer = null;
        }
    },
    mounted() {},
    methods: {
        getWidth() {
            return this.fWidth;
        },
        getHeight() {
            return this.fHeight;
        },
        setWidth(val) {
            this.fWidth = val;
        },
        setHeight(val) {
            this.fHeight = val;
        },
        getId() {
            const randomNum = Math.floor(Math.random() * 900000) + 100000;
            return "J_prismPlayer" + randomNum;
        },
        getValue() {
            return this.fvalue;
        },
        setValue(val) {
            this.fvalue = val;
        },
        setDelayTime(val) {
            this.fDelayTime = val;
        },
        loadSourceData() {
            this.fvalue = utils.getSourceData(this.fDataSource);
            if (this.fvalue === "" || this.fvalue === undefined || this.fvalue === null) return;
            if (this.player != null) {
                this.id = undefined;
                this.player.pause();
                this.player.dispose();
                this.player = null;
                this.id = this.getId();
            }
            const that = this;
            setTimeout(() => {
                this.player = new Aliplayer(
                    {
                        id: that.id,
                        isLive: true, // 是否为直播播放。
                        width: that.fWidth,
                        height: that.fHeight,
                        source: that.fvalue, // 播放地址，可以是第三方直播地址，或阿里云直播服务中的拉流地址。
                        preload: that.preLoad,
                        autoPlayDelay: that.fDelayTime,
                        autoPlayDelayDisplayText: that.autoPlayDelayDisplayText,
                        skinLayout: [
                            {
                                name: "bigPlayButton",
                                align: "blabs",
                                x: 30,
                                y: 80,
                            },
                            {
                                name: "infoDisplay",
                            },
                            {
                                name: "controlBar",
                                align: "blabs",
                                x: 0,
                                y: 0,
                                children: [
                                    {
                                        name: "liveDisplay",
                                        align: "tlabs",
                                        x: 15,
                                        y: 6,
                                    },
                                    {
                                        name: "fullScreenButton",
                                        align: "tr",
                                        x: 10,
                                        y: 10,
                                    },
                                ],
                            },
                        ],
                    },
                    function (player) {
                        const fdtm = that.fDelayTimeMultiple;
                        if (fdtm && fdtm > 0) {
                            let count = Math.round(that.fDelayTime / fdtm) - 1;
                            if (that.fDelayTime) {
                                let a = document.getElementsByClassName("prism-info-display")[0];
                                let l = setInterval(() => {
                                    if (that.isEnglish) {
                                        a.innerText = `${count} second remaining, please wait in cache...`;
                                    } else {
                                        a.innerText = ` 剩余${count}秒, 缓存中请稍等...`;
                                    }
                                    if (count < -1) {
                                        clearInterval(l);
                                    }
                                    if (count > -2) {
                                        count--;
                                    }
                                }, that.fTime);
                            }
                        }
                        console.log("The player is created.");
                    }
                );
            }, 1000);
        },
    },
};
</script>
