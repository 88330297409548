<template>
    <el-date-picker
        v-model="fvalue"
        :type="type"
        :size="size"
        :format="showFormat"
        :clearable="clearable"
        :value-format="format"
        :disabled="disabledValue"
        :end-placeholder="endText"
        :placeholder="placeholder"
        :default-time="fdefaultTime"
        :start-placeholder="startText"
        :picker-options="pickerOptions"
        :range-separator="type == 'daterange' ? rangeSeparator : ''"
        @change="change"
    >
    </el-date-picker>
</template>
<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yes-datePicker",
    props: {
        titleCss: String,
        contentCss: String,
        definedCss: Object,
        dataSource: String,
        disabled: Boolean,
        formCreateInject: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            default: "date",
        },
        startText: {
            type: String,
            default: "开始时间",
        },
        endText: {
            type: String,
            default: "结束时间",
        },
        placeholder: {
            type: String,
        },
        rangeSeparator: {
            type: String,
            default: "至",
        },
        changeEvent: {
            type: String,
        },
        format: {
            type: String,
        },
        showFormat: {
            type: String,
        },
        clearable: {
            type: Boolean,
        },
        disabledDateEvent: {
            type: String,
        },
        size: {
            type: String,
        },
    },
    data() {
        return {
            pickerOptions: {
                disabledDate: this.handleDisabledDate,
            },
            fvalue: "",
            disabledValue: false,
            fDataSource: this.dataSource,
            fdefaultTime: "00:00:00",
        };
    },
    mounted() {
        this.disabledValue = this.disabled;
        this.loadSourceData();
        const rangeTypeList = ["monthrange", "datetimerange", "daterange"];
        if (this.type && rangeTypeList.includes(this.type)) {
            this.fdefaultTime = ["00:00:00", "23:59:59"];
        }
    },
    watch: {
        disabled(val) {
            this.disabledValue = val;
        },
        fvalue(val) {
            this.$emit("input", val);
            utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
        },
    },
    methods: {
        setDefaultTime(val) {
            this.fdefaultTime = val;
        },
        setDisabled(val) {
            this.disabledValue = val;
        },
        // 将组件的值返回
        getValue() {
            return this.fvalue;
        },
        // 修改组件的值
        setValue(val) {
            this.fvalue = val;
        },
        // 读取绑定数据源数据
        loadSourceData() {
            let val = utils.getSourceData(this.fDataSource);
            this.fvalue = val !== undefined ? val : this.fvalue;
        },
        // 修改绑定数据源数据
        updateSourceData(val) {
            utils.updateSourceData(this.fDataSource, val);
        },
        setDisabledDateValue(val) {
            this.disabledDateValue = val;
        },
        async change(val) {
            // 绑定数据源时，输入框变化对应修复数据源数据
            if (this.fDataSource) {
                this.updateSourceData(val);
            }
            if (this.changeEvent) {
                const changeEvent = window.AsyncFunction("vm", "api", "dao", "value", "utils", this.changeEvent);
                await changeEvent(this, this.formCreateInject.api, dao, val, utils);
            }
        },
        handleDisabledDate(time) {
            if (!this.disabledDateEvent) {
                return false;
            }
            const disabledEvent = window.Function("vm", "api", "dao", "value", "utils", this.disabledDateEvent);
            return disabledEvent(this, this.formCreateInject.api, dao, time, utils);
        },
    },
};
</script>
<style scoped>
::v-deep .el-range-separator {
    width: 24px !important;
}
</style>