<template>
  <el-switch
    v-model="fvalue"
    :active-color="openColor"
    :inactive-color="closeColor"
    :active-text="openText"
    :inactive-text="closeText"
    :disabled="disabledValue"
    @change="change"
  >
  </el-switch>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
  name: "yes-switch",
  props: {
    titleCss: String,
    contentCss: String,
    definedCss: Object,
    dataSource: String,
    formCreateInject: {
      type: Object,
      required: true,
    },
    openColor: {
      type: String,
    },
    closeColor: {
      type: String,
    },
    openText: {
      type: String,
    },
    closeText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    changeEvent: {
      type: String,
    },
    // initColField: {
    //   type: String
    // }
  },
  data () {
    return {
      fvalue: false,
      fDataSource: this.dataSource,
      disabledValue: false
    };
  },
  mounted () {
    this.disabledValue = this.disabled
    // if (this.initColField) {
    //   this.fvalue = utils.getRow(this).code ? 1 : 0
    //   utils.getRow(this).code = 0
    // }
    this.loadSourceData();
  },
  watch: {
    disabled(val){
      this.disabledValue = val
    },
    fvalue (val) {
      this.$emit("input", val);
      utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
    },
  },
  created () {
    let data =
      this.formCreateInject.api.data &&
      this.formCreateInject.api.data[this.formCreateInject.prop.name];
    if (data) {
      this.$nextTick(() => {
        this.fvalue = data;
      });
    }
  },
  methods: {
    setDisabled(val) {
      this.disabledValue = val
    },
    // 将组件的值返回
    getValue () {
      return this.fvalue;
    },
    // 修改组件的值
    setValue (val) {
      this.fvalue = val;
    },
    // 读取绑定数据源数据
    loadSourceData () {
      let val = utils.getSourceData(this.fDataSource);
      this.fvalue = val !== undefined ? val : this.fvalue;
      if (this.fvalue == 0) {
        this.fvalue = false;
      } else if (this.fvalue == 1) {
        this.fvalue = true;
      }
    },
    // 修改绑定数据源数据
    updateSourceData (val) {
      utils.updateSourceData(this.fDataSource, val);
    },
    async change (val) {

      if (this.fDataSource) {
        this.updateSourceData(val);
      }
      if (this.changeEvent) {
        const change = window.AsyncFunction(
          "vm",
          "api",
          "dao",
          "value",
          "utils",
          "vm",
          this.changeEvent
        );
        await change(this, this.formCreateInject.api, dao, val, utils, this);
      }
    },
  },
};
</script>