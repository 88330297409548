
<template>
    <div class="hello" style="width:100%;height:100%;position:relative">
        <!-- <div style="position: absolute;top:0;">
          <input style="font-size:16px;" type="file" @change="uploadExcel" />
        </div> -->
        <!-- <el-button @click="getJson">获取json</el-button> -->
        <div v-if="isDrag" style="display: flex; color: #606266;height: 30px;line-height: 30px;">
            <i name="yes-icon-workbook" class="yes-icon-workbook" style="width:30px;margin-left: 20px;"></i>
            <span>{{showLabel}}</span>
        </div>
        <div v-if="!isDrag" id="luckysheet"
            style="margin:0px;padding:0px;position:absolute;width:100%;left: 0px;top: 0px;bottom:0px;"></div>
    </div>
</template>
  
  <script>
import LuckyExcel from 'luckyexcel'
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: 'yesWorkbook',
    props: {
        formCreateInject: {
            type: Object,
            required: true,
        },
        showLabel:String,
        defaultJson: Array,
        beforeCreateScript: String,
        afterCreateScript: String,
    },
    data() {
        return {
            isDrag: false,
            dataJson: [
                {
                    "name": "Sheet1",
                    "color": "",
                    "index": 0,
                    "status": 0,
                    "order": 0,
                    "celldata": [],
                    "config": {}
                }
            ],
            options: {
                container: 'luckysheet', // 设定DOM容器的id
                title: '', // 设定表格名称
                lang: 'zh', // 设定表格语言
                plugins: [],
                data: {},
                hook: {}
            }
        }

    },
    async created() {
        const vm = this
        if (this.$parent.$options._componentTag === "dragTool") {
            this.isDrag = true;
        } else {
            if (this.defaultJson && this.defaultJson !== [] && this.defaultJson !== '' && this.defaultJson !== undefined) {
                this.dataJson = this.defaultJson
            }
            await this.beforeCreateFunction()
            $(function () {
                vm.options.data = vm.dataJson
                luckysheet.create(
                    vm.options
                );
            });
        }
    },
    async mounted() {
        await this.afterCreateFunction()
    },
    methods: {
        setJson(dataJson) {
            this.dataJson = dataJson
            this.options.data = dataJson.sheets
            // this.options.title = dataJson.info.name
            // this.options.userInfo = dataJson.info.name.creator
            window.luckysheet.destroy();
            luckysheet.create(
                this.options
            );
        },
        getJson() {
           return luckysheet.getAllSheets()
        },
        uploadExcel(evt) {
            const files = evt.target.files;
            if (files == null || files.length == 0) {
                //   utils.messageError("没有选择文件");
                return;
            }
            let name = files[0].name;
            let suffixArr = name.split("."), suffix = suffixArr[suffixArr.length - 1];
            if (suffix != "xlsx") {
                utils.messageError("目前只支持上传xlsx格式文件");
                return;
            }
            LuckyExcel.transformExcelToLucky(files[0], function (exportJson, luckysheetfile) {
                if (exportJson.sheets == null || exportJson.sheets.length == 0) {
                    utils.messageError("未找到文件数据");
                    return;
                }
                window.luckysheet.destroy();
                window.luckysheet.create({
                    container: 'luckysheet', //luckysheet is the container id
                    showinfobar: false,
                    data: exportJson.sheets,
                    title: exportJson.info.name,
                    userInfo: exportJson.info.name.creator
                });
            });
        },
        async beforeCreateFunction() {
            const beforeCreateEvent = window.AsyncFunction(
                "vm",
                "api",
                "dao",
                "fApi",
                "utils",
                "options",
                this.beforeCreateScript,
            );
            await beforeCreateEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils, this.options);
        },
        async afterCreateFunction() {
            const afterCreateEvent = window.AsyncFunction(
                "vm",
                "api",
                "dao",
                "fApi",
                "utils",
                "sheet",
                this.afterCreateScript,
            );
            await afterCreateEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils, luckysheet);
        },
    }
}
</script>
  <<style lang="scss">
  .luckySheet {
      margin: 0px;
      padding: 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
  }
  
  .luckysheet_info_detail {
      display: none !important;
  }
  </style>
  