<template>
  <el-table
    :data="tableData"
    :border="showBorder"
    @selection-change="handleSelectionChange"
    @row-click="handleRowClick"
  >
    <el-table-column
      type="selection"
      width="55"
      v-if="showSelection"
    >
    </el-table-column>
    <el-table-column
      type="index"
      width="50"
      v-if="showIndex"
    >
    </el-table-column>
    <el-table-column
      v-for="(item,index) in tabelColumnData"
      :prop="item.title"
      :label="item.title"
      :width="colWidth"
      :key="index"
    >
    </el-table-column>
  </el-table>

</template>

<script>
//import { viewReflect } from '@/api/core/view'
export default {
  name: 'yes-table',
  props: {
    source: {
      type: String,
      default: ''
    },
    clickMethodBody: {
      type: String,
      default: ''
    },
    checkMethodBody: {
      type: String,
      default: ''
    },
    showIndex: {
      type: Boolean,
      default: false,
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
    showBorder: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: "暂无数据",
    },
    colWidth: {
      type: String,
      default: "120px",
    },
    rowWidth: {
      type: String,
      default: "800px",
    }
  },
  data () {
    return {
      row: {},
      checkedRows: [],
      tableData: [],
      tabelColumnData: [],
    }
  },
  methods: {
    handleRowClick (row, column, event) {
      if (this.checkMethodBody) {
        const func = new Function('row', 'column', 'event', this.clickMethodBody);
        func(row, column, event); // 3

      }
    },
    handleSelectionChange (selection) {
      if (this.clickMethodBody) {
        const func = new Function('selection', this.checkMethodBody);
        func(selection);
      } // 3
    }

    // handleInput () {
    //   // console.log($event)
    //   // this.value = $event
    // }
  },
  async mounted () {

    const dataOne = [
      {
        cr_id: 1,
        cr_name: 'Portfolio',
        cr_parent: undefined
      },
      {
        cr_id: 2,
        cr_name: 'Web Development',
        cr_parent: 1
      },
      {
        cr_id: 3,
        cr_name: 'Recent Works',
        cr_parent: 2
      },
      {
        cr_id: 4,
        cr_name: 'About Me',
        cr_parent: undefined
      }
    ]
    if (this.source) {
      //const res = await viewReflect(this.source);
      this.tableData = [...dataOne];
    }


    // let fetch = {
    //   action: 'http://datavmap-public.oss-cn-hangzhou.aliyuncs.com/areas/csv/100000_province.json',
    //   method: 'GET',
    // }
    //fetch().then(res => { console.log("res:", res) })
    if (this.tableData.length > 0) {

      this.tabelColumnData = Object.keys(this.tableData[0]).map(item => Object.assign({}, {
        title: item,
      }))
    }

    //this.tableData.map(item=>{title:});
  }
}
</script>

<style>
</style>