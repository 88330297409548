<template>
  <el-collapse
    v-model="activeNames"
    :accordion="accordion"
    @change="handleChange"
  >
    <slot />
  </el-collapse>
</template>

<script>
import uniqueId from "@form-create/utils/lib/unique";
const utils = require("@/api/core/utils");
const dao = require("@/api/core/dao");
export default {
  name: "yesCollapse",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    accordion: {
      type: Boolean,
      default: false,
    },
    activeName: String,
    changeEvent: String,
  },
  data () {
    return {
      activeNames: [],
      activeNamesLength: 0,
      exitActiveNames: [],
    };
  },
  watch: {
    activeName (val) {
      this.setActiveNames(val);
    },
  },
  created () {
    if (this.activeNames.length === 0 && this.$slots.default) {
      let name = this.$slots.default[0].componentOptions.propsData.name;
      this.activeNames.push(name);
      this.activeNamesLength = this.$slots.default.length;
    }
    if (this.activeName) {
      this.activeNames = [];
      this.setActiveNames(this.activeName);
    }
  },
  updated () {
    if (this.activeNamesLength === 0 && this.$slots.default) {
      this.activeNames.concat(
        this.$slots.default[0].componentOptions.propsData.name
      );
      this.activeNamesLength = this.$slots.default.length;
    }
    if (this.$slots.default.length > this.activeNamesLength) {
      let name =
        this.$slots.default[this.$slots.default.length - 1].componentOptions
          .propsData.name;
      if (this.accordion) {
        this.activeNames = name;
        this.activeNamesLength = this.$slots.default.length;
      } else {
        if (typeof this.activeNames === "string") {
          this.activeNames = [this.activeNames];
        }
        this.activeNames.find((item) => item === name)
          ? this.activeNames.splice(this.activeNames.indexOf(name), 1)
          : this.activeNames.push(name);
        this.activeNamesLength = this.$slots.default.length;
      }
    }
    if (this.$slots.default.length < this.activeNamesLength) {
      let name =
        this.$slots.default[this.$slots.default.length - 1].componentOptions
          .propsData.name;
      if (this.accordion) {
        this.activeNames = name;
        this.activeNamesLength = this.$slots.default.length;
      } else {
        this.activeNames.find((item) => item === name)
          ? this.activeNames.splice(this.activeNames.indexOf(name), 1)
          : this.activeNames.push(name);
        this.activeNamesLength = this.$slots.default.length;
      }
    }
  },
  methods: {
    setActiveNames (name) {
      let arr = name.split(",");
      arr.forEach((element) => {
        this.activeNames.find((item) => item === element)
          ? ""
          : this.activeNames.push(element);
      });
    },
    openOrCloseItem (val) {
      this.activeNames.find((item) => item === val)
        ? this.activeNames.splice(this.activeNames.indexOf(val), 1)
        : this.activeNames.push(val);
    },
    async handleChange (val) {
      this.activeNames = val;
      const tabClick = window.AsyncFunction(
        "vm",
        "api",
        "rule",
        "dao",
        "utils",
        this.changeEvent
      );
      await tabClick(
        this,
        this.formCreateInject.api,
        this.formCreateInject.rule,
        dao,
        utils
      );
    },
    addCollapse () {
      if (this.$parent.$options._componentTag === "dragTool") {
        this.$parent.$emit("addChild");
        return;
      }
      this.addRule();
    },
    addRule (data = {}, rule = []) {
      //添加子组件
      const collapseRule = this.formCreateInject.rule;
      const collapseItemRule = {
        type: "el-collapse-item",
        name: data.itemId || uniqueId(),
        props: {
          name: data.name || uniqueId(),
          title: data.title || "新面板",
          disabled: data.disabled || false,
        },
        children: rule,
        _fc_drag_tag: "yes-collapse-item",
        hidden: false,
        display: true,
      };
      collapseRule.children.push(collapseItemRule);
    },
  },
};
</script>
