<template>
    <el-time-picker
        v-model="fvalue"
        value-format="HH:mm:ss"
        :is-range="range"
        :arrow-control="control"
        :disabled="disabledValue"
        :placeholder="placeholder"
        :end-placeholder="endText"
        :start-placeholder="startText"
        :range-separator="rangeSeparator"
        @change="change"
    >
    </el-time-picker>
</template>
<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yes-timepicker",
    props: {
        titleCss: String,
        contentCss: String,
        definedCss: Object,
        dataSource: String,
        formCreateInject: {
            type: Object,
            required: true
        },
        range: {
            type: Boolean
        },
        control: {
            type: Boolean
        },
        rangeSeparator: {
            type: String,
            default: "至"
        },
        defaultValue: {
            type: String,
            default: "default_time"
        },
        startText: {
            type: String,
            default: "开始时间"
        },
        disabled: {
            type: Boolean
        },
        endText: {
            type: String,
            default: "结束时间"
        },
        placeholder: {
            type: String
        },
        changeEvent: {
            type: String
        }
    },
    data() {
        return {
            fvalue: "",
            isInit: true,
            disabledValue: false,
            fDataSource: this.dataSource,
            defaultTime: "00:00:00",
            defaultTimeList: ["00:00:00", "23:59:59"]
        };
    },
    mounted() {
        if (!utils.isNull(this.defaultValue) && this.defaultValue === "default_time") {
            this.fvalue = !this.range ? this.defaultTime : this.defaultTimeList;
        }
        this.disabledValue = this.disabled;
        this.loadSourceData();
    },
    watch: {
        fvalue(val, old) {
            if (this.isInit) return;
            if (JSON.stringify(val) !== JSON.stringify(old)) {
                this.$emit("input", val);
                utils.noticeParentDataObject(this.formCreateInject, val);
            }
        }
    },
    methods: {
        setDisabled(val) {
            this.disabledValue = val;
        },
        // 将组件的值返回
        getValue() {
            return this.fvalue;
        },
        // 修改组件的值
        setValue(val) {
            this.fvalue = val;
        },
        // 读取绑定数据源数据
        loadSourceData() {
            if (!this.fDataSource) return;
            const val = utils.getSourceData(this.fDataSource);
            if (utils.isNull(val)) {
                this.fvalue = "";
                return;
            }
            const tempVal = JSON.stringify(val);
            const tempFvalue = JSON.stringify(this.fvalue);
            if (tempVal === tempFvalue) return;

            if (!this.range && this.fvalue === this.defaultTime) {
                this.fvalue = "";
            }
            const json = JSON.stringify(this.defaultTimeList);
            if (this.range && json === tempFvalue) {
                this.fvalue = "";
            }
            if (utils.isNull(this.fvalue)) {
                this.fvalue = val;
            } else {
                if (tempVal !== tempFvalue) {
                    this.fvalue = val;
                }
            }
            // if (utils.isNull(val)) {
            //     if (this.isInit) {
            //         this.isInit = false;
            //         if (this.defaultValue === "default_time") {
            //             if (!this.range && this.fvalue === this.defaultTime) {
            //                 this.fvalue = "";
            //             }
            //             if (this.range && json === tempFvalue) {
            //                 this.fvalue = "";
            //             }
            //         }
            //     }
            // }
            // this.fvalue = val !== undefined ? val : this.fvalue;
            // this.fvalue = utils.getSourceData(this.fDataSource);
        },
        // 修改绑定数据源数据
        updateSourceData(val) {
            utils.updateSourceData(this.fDataSource, val);
        },
        async change(val) {
            // 绑定数据源时，输入框变化对应修复数据源数据
            if (this.fDataSource) {
                this.updateSourceData(val);
            }
            if (this.changeEvent) {
                const changeEvent = window.AsyncFunction("vm", "api", "dao", "value", "utils", this.changeEvent);
                await changeEvent(this, this.formCreateInject.api, dao, val, utils);
            }
        }
    }
};
</script>