<template>
    <el-cascader v-model="fvalue" :options="options" :disabled="disabledValue" :size="size" :props="{ 'value': 'name' ,'label':'name'}"
        :clearable="clearable" @change="handleChange" placeholder="请选择" style="width:100%" filterable></el-cascader>
</template>
<script>
const utils = require("@/api/core/utils");
const dao = require("@/api/core/dao");
import { getProvince, getCityTree ,getAreaTree } from './areaData/area'
export default {
    name: "yes-cascader",
    props: {
        dataSource: String,
        formCreateInject: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
        },
        size: {
            type: String,
        },
        clearable: {
            type: Boolean,
        },
        changeEvent: {
            type: String,
        },
        level: {
            type:Number
        }
    },
    data() {
        return {
            fvalue: [],
            options: [],
            fDataSource: this.dataSource,
            disabledValue: false
        };
    },
    mounted() {
        this.disabledValue = this.disabled
        this.getOptions()
        this.loadSourceData();
    },
    watch: {
        disabled(val) {
            this.disabledValue = val
        },
        fvalue(val) {
            this.$emit("input", val);
            utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
        },
    },
    methods: {
        getOptions() {
            if(this.level === 1) this.options = getProvince()
            if(this.level === 2) this.options = getCityTree()
            if(this.level === 3) this.options = getAreaTree()
            // if(this.level === 4) this.options = getStreetTree()
        },
        setDisabled(val) {
            this.disabledValue = val
        },
        // 将组件的值返回
        getValue() {
            return this.fvalue;
        },
        // 修改组件的值
        setValue(val) {
            this.fvalue = val;
        },
        // 读取绑定数据源数据
        loadSourceData() {
            // let val2 = utils.getSourceData(this.optionSource);
            // this.options = val2 !== undefined ? val2 : this.fvalue;
            // let val1 = utils.getSourceData(this.fDataSource);
            // this.fvalue = val1 !== undefined ? val1 : this.fvalue;
            // this.options = utils.getSourceData(this.optionSource);
            // this.fvalue = utils.getSourceData(this.fDataSource);
        },
        // 修改绑定数据源数据
        updateSourceData(val) {
            utils.updateSourceData(this.fDataSource, val);
        },
        async handleChange(val) {
            // 绑定数据源时，输入框变化对应修复数据源数据

            let label = ''
            for( let i = 0; i<val.length; i++) {
                label += i === 0? val[i]:( '/' + val[i])
            }
            if (this.fDataSource) {
                this.updateSourceData(label);
            }
            if (this.changeEvent) {
                const changeEvent = window.AsyncFunction(
                    "vm",
                    "api",
                    "dao",
                    "value",
                    "utils",
                    this.changeEvent
                );
                await changeEvent(this, this.formCreateInject.api, dao, val, utils);
            }
        },
    },
};
</script>+
    