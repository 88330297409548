<template>
  <el-link
    :icon="icon"
    :href="href"
    :target="target"
    :type="type"
    :underline="underline"
    :disabled="disabled"
  >
    <slot></slot>
  </el-link>
</template>

<script>
const utils = require("@/api/core/utils");
// const dao = require('@/api/core/dao');
export default {
  name: "yesLink",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    icon: String,
    target: {
      type: String,
      default: "_blank",
    },
    type: String,
    underline: Boolean,
    disabled: Boolean,
  },
  data () {
    return {
      href: "",
      fDataSource: this.dataSource
    };
  },
  watch: {
  },
  methods: {
    // 将组件的值返回
    getValue () {
      return this.href;
    },
    // 修改组件的值
    setValue (val) {
      this.href = val;
    },
    // 读取绑定数据源数据
    loadSourceData () {
      this.href = utils.getSourceData(
        this.fDataSource
      );
    },
    // 修改绑定数据源数据
    updateSourceData () {
      utils.updateSourceData(this.fDataSource, this.href);
    },
    setHref (herf) {
      this.href = herf;
    },
  },
}
</script>
