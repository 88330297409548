<template>
  <div>
    <div v-if="isDesigner">
      <h1>{{ dataSource }}</h1>
    </div>
    <slot v-else> </slot>
  </div>
</template>
<script>
function getComponentValue(_component, _name) {
  if (_component.name === _name) {
    return _component.__fc__.el;
  }
  if (_component.children) {
    for (let i = 0; i < _component.children.length; i++) {
      let _value = getComponentValue(_component.children[i], _name);
      if (_value) {
        return _value;
      }
    }
  }
  return {};
}

const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
  name: "yesComponent",
  props: {
    formCreateInject: {
      type: Object,
      required: true
    },
    dataSource: String,
    bindField: {
      type: String,
      default: ""
    },
    app: String,
    code: String,
    afterCreateScript: String,
    initLoading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      fDataSource: this.dataSource,
      options: {},
      state: false
    };
  },
  computed: {
    isDesigner() {
      return (
        this.$parent.$options._componentTag === "dragTool" ||
        this.$parent.$parent.$parent.$options._componentTag === "dragTool"
      );
    },
  },
  async created() {
    if (
      this.$parent.$options._componentTag === "dragTool" ||
      this.$parent.$parent.$parent.$options._componentTag === "dragTool"
    ) {
      return;
    }
    if (this.initLoading) {
      await this.load();
    }
  },
  async mounted() {
    if (this.initLoading) {
      await this.afterCreateLoad();
    }
  },
  methods: {
    async initLoad() {
      await this.load();
      await this.afterCreateLoad();
    },
    getLoadState() {
      return this.state;
    },
    setRule(rule = []) {
      //添加子组件
      const tabRule = this.formCreateInject.rule;
      tabRule.children = rule;
    },
    addRule(rule = []) {
      //添加子组件
      const tabRule = this.formCreateInject.rule;
      tabRule.children = tabRule.children.concat(rule);
    },
    setValue(val) {
      this.data = val;
    },
    getValue() {
      return this.data;
    },
    // 读取绑定数据源数据
    loadSourceData() {
      let data = utils.getSourceData(this.fDataSource);
      this.setValue(data);
    },
    // 修改绑定数据源数据
    updateSourceData() {
      utils.updateSourceData(this.fDataSource, this.data);
    },
    async load() {
      const that = this;
      if (!(this.app && this.code)) {
        return;
      }
      let data = await dao.view("fde", "component", { app: this.app, code: this.code });
      let dataRule = data.data.length > 0 ? data.data[0].rule : "";
      this.options = data.data.length > 0 ? JSON.parse(data.data[0].options) : "";
      let rule = {};
      if (dataRule) {
        rule = JSON.parse(dataRule);
      }
      this.addRule(rule);
      this.$nextTick(async () => {
        getComponentValue(this.formCreateInject.rule, this.bindField).fvalue = this.value;
        await this.componentCreatedLoad();
        that.state = true;
      });
    },
    async componentCreatedLoad() {
      if (this.options.form) {
        if (this.options.form.afterCreateScript) {
          const afterEvent = window.AsyncFunction(
            "vm",
            "api",
            "dao",
            "utils",
            this.options.form.afterCreateScript
          );
          await afterEvent(this, this.formCreateInject.api, dao, utils);
        }
      }
    },
    async afterCreateLoad() {
      const clickEvent = window.AsyncFunction(
        "vm",
        "api",
        "dao",
        "utils",
        this.afterCreateScript
      );
      await clickEvent(this, this.formCreateInject.api, dao, utils);
    }
  }
};
</script>
