<template>
    <div>
        <div id="highchartsContainer"></div>
    </div>
</template>
  
<script>
import './webDatarocks/webdatarocks.css';
import Highcharts from 'highcharts'
const utils = require("@/api/core/utils");
const dao = require('@/api/core/dao');

export default {
    name: "yesWebDataHighcharts",
    props: {
        formCreateInject: {
            type: Object,
            required: true,
        },
        rockId:String,
        chartType: String
    },
    data() {
        return {
            webdatarocks:null
        };
    },
    methods: {
        init(webdatarocks){
            debugger
            webdatarocks.highcharts.getData({
                type: this.chartType
            }, function(data) {
                Highcharts.chart('highchartsContainer', data);
            }, function(data) {
                Highcharts.chart('highchartsContainer', data);
            });
        }
    }
}
</script>
  
<style></style>