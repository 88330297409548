<template>
    <div style="margin: 10px;">
        <div style="margin: 10px;">
            <span>
                <el-select v-model="selectedView" class="view-select">
                    <el-option v-for="view in viewOptions" :key="view.value" :value="view.value"
                        :label="view.title"></el-option>
                </el-select>
            </span>
            <div class="buttons">
                <el-button plain type="primary" @click="onClickTodayButton">
                    今天
                </el-button>
                <el-button plain type="primary" @click="onClickMoveButton(-1)">
                    <span v-if="selectedView == 'month'">
                        上个月
                    </span>
                    <span v-else-if="selectedView == 'day'">
                        昨天
                    </span>
                    <span v-else>
                        上一周
                    </span>
                </el-button>
                <el-button plain type="primary" @click="onClickMoveButton(1)">
                    <span v-if="selectedView == 'month'">
                        下个月
                    </span>
                    <span v-else-if="selectedView == 'day'">
                        明天
                    </span>
                    <span v-else>
                        下一周
                    </span>
                </el-button>
            </div>
            <span class="date-range">当前显示时间<span v-if="selectedView !== 'day'">范围</span>: {{ dateRangeText }}</span>
        </div>

        <ToastUICalendar ref="calendar" :week="fWeek" :month="fMonth" :view="'month'" :events="fvalue" :theme="themeData"
            :isReadOnly="isReadOnly" :timezone="{ zones }" :calendars="fCalendars" :grid-selection="true"
            :use-form-popup="true" :use-detail-popup="true" :style="{ 'height': fHeight, 'width': fWidth }"
            :template="{ 'milestone': getTemplateForMilestone, 'allday': getTemplateForAllday }" @clickEvent="onClickEvent"
            @clickDayName="onClickDayName" @selectDateTime="onSelectDateTime" @afterRenderEvent="onAfterRenderEvent"
            @beforeUpdateEvent="onBeforeUpdateEvent" @beforeDeleteEvent="onBeforeDeleteEvent"
            @beforeCreateEvent="onBeforeCreateEvent" @clickTimezonesCollapseBtn="onClickTimezonesCollapseBtn" />
    </div>
</template>

<script>
/* eslint-disable no-console */
import ToastUICalendar from '@toast-ui/vue-calendar'
import '@toast-ui/calendar/dist/toastui-calendar.min.css'
import 'tui-date-picker/dist/tui-date-picker.min.css';
import 'tui-time-picker/dist/tui-time-picker.min.css';
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
// import { events } from './mock-data';
// import { theme } from './theme';
// import './app.css';
export default {
    components: {
        ToastUICalendar
    },
    props: {
        // 示例文档
        // https://github.com/nhn/tui.calendar/blob/main/docs/en/apis/options.md#weekcollapseduplicateevents
        formCreateInject: {
            type: Object,
            required: true,
        },
        dataSource: String,
        height: {
            type: String,
            default: '800px'
        },
        width: {
            type: String,
            default: '100%'
        },
        // 显示类型 month/week/day
        view: {
            type: String,
            default: 'month'
        },
        // 呈现在日历的定义事件
        defaultEvents: {
            type: [Object, Array]
        },
        // 定义日历以对事件进行分组
        defaultCalendars: {
            type: [Object, Array],
            default: () => [{
                id: '0',
                name: 'Private',
                backgroundColor: '#9e5fff',
                borderColor: '#9e5fff',
                dragBackgroundColor: '#9e5fff',
            },
            {
                id: '1',
                name: 'Company',
                backgroundColor: '#00a9ff',
                borderColor: '#00a9ff',
                dragBackgroundColor: '#00a9ff',
            }]
        },
        defaultDate: {
            type: [String, Object]
        },
        month: Object,
        week: Object,
        // 是否只读 false
        isReadOnly: {
            type: Boolean,
            default: true
        },
        // 点击日历是否弹出默认弹窗
        useFormPopup: {
            type: Boolean,
            default: true
        },
        // 点击日历中的事件是否弹出默认弹窗
        useDetailPopup: {
            type: Boolean,
            default: true
        },
        clickScript: String,
        afterRenderScript: String,
        afterCreateScript: String,
        clickDayNameScript: String,
        beforeUpdateScript: String,
        beforeDeleteScript: String,
        beforeCreateScript: String,
        selectDateTimeScript: String,
        beforeCreateEventScript: String,
    },
    data() {
        return {
            fvalue: this.defaultEvents,
            fHeight: this.height,
            fWidth: this.width,
            themeData: {},
            fMonth: {},
            fWeek: {},
            defaultMonth: {},
            defaultWeek: {},
            templateOptions: {
            },
            fCalendars: this.defaultCalendars,
            zones: [{
                timezoneName: 'Asia/Shanghai',
                displayLabel: 'Shanghai',
                tooltip: 'UTC+08:00',
            }],
            selectedView: this.view,
            viewOptions: [
                {
                    title: '按月',
                    value: 'month',
                },
                {
                    title: '按周',
                    value: 'week',
                },
                {
                    title: '按天',
                    value: 'day',
                },
            ],
            dateRangeText: '',
        };
    },
    computed: {
        calendarInstance() {
            return this.$refs.calendar.getInstance();
        },
    },
    watch: {
        selectedView(newView) {
            this.calendarInstance.changeView(newView);
            this.setDateRangeText();
        },
    },
    async created() {
        this.setThemeData();
        this.setWeekOptions();
        this.setMonthOptions();
        await this.beforeCreateFunction();
        this.fvalue = this.defaultEvents ? this.defaultEvents : this.fvalue;
    },
    async mounted() {
        this.setDefaultDate();
        this.setDateRangeText();
        this.afterCreateFunction();
    },
    methods: {
        // 读取绑定数据源数据
        loadSourceData() {
            this.fvalue = utils.getSourceData(this.fDataSource);
        },
        // 修改绑定数据源数据
        updateSourceData() {
            utils.updateSourceData(
                this.fDataSource,
                this.fvalue
            );
        },
        // 设置日历事件
        setFvalue(val) {
            if (val) {
                this.fvalue = val;
            }
        },
        // 设置默认日历事件
        setDefaultEvents(val) {
            if (val) {
                this.defaultEvents = val;
            }
        },
        // 设置日历事件分组
        setCalendars(val) {
            if (val) {
                this.fCalendars = val;
            }
        },
        // 设置日历组件显示的时间范围
        setDefaultDate(val) {
            if (this.defaultDate) {
                this.calendarInstance.setDate(this.defaultDate);
            }
            if (val) {
                this.calendarInstance.setDate(val);
            }
        },
        // 设置周数据选项
        setWeekOptions(val) {
            if (val) {
                this.fWeek = val;
            } else {
                if (!this.week || this.week == null) {
                    this.fWeek = this.defaultWeek;
                } else {
                    this.fWeek = this.week;
                }
            }
        },
        // 设置是否只读
        setReadOnly(val) {
            if (val) {
                this.isReadOnly = val;
            }
        },
        // 设置月数据选项
        setMonthOptions(val) {
            if (val) {
                this.fWeek = val;
            } else {
                if (!this.month || this.month == null) {
                    this.fMonth = this.defaultMonth;
                } else {
                    this.fMonth = this.month;
                }
            }
        },
        // 模板
        getTemplateForMilestone(event) {
            return `<span style="color: #fff; background-color: ${event.backgroundColor};">${event.title}</span>`;
        },
        // 模板
        getTemplateForAllday(event) {
            return `[All day] ${event.title}`;
        },
        // 点击网格后的操作
        async onSelectDateTime({ start, end }) {
            const calenderObject = {
                'start': start,
                'end': end
            };
            console.group('onSelectDateTime');
            console.log(`Date : ${start} ~ ${end}`);
            console.groupEnd();
            const tempEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", "calenderObject", this.selectDateTimeScript);
            await tempEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils, calenderObject);
        },
        // 新增网格事件前的操作
        async onBeforeCreateEvent(eventData, customEventData) {
            const calenderObject = {
                'eventData': eventData
            };
            console.group('onBeforeCreateEvent');
            console.log(eventData);
            console.groupEnd();
            let event = {};
            if (eventData == null) {
                event = customEventData;
            } else {
                const attendees = eventData.attendees;
                event = {
                    calendarId: eventData.calendarId || '',
                    id: eventData.id || String(Math.random()),
                    title: eventData.title,
                    isAllday: eventData.isAllday,
                    start: eventData.start,
                    end: eventData.end,
                    attendees: attendees || ['anyone'],
                    category: eventData.isAllday ? 'allday' : 'time',
                    dueDateClass: '',
                    location: eventData.location,
                    state: eventData.state,
                    isPrivate: eventData.isPrivate,
                };
            }
            await this.calendarInstance.createEvents([event]);
            const beforeCreateEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", "calenderObject", this.beforeCreateEventScript);
            await beforeCreateEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils, calenderObject);
        },
        // 更新网格中事件前的操作
        async onBeforeUpdateEvent(updateData, customEventData) {
            const calenderObject = {
                'updateDate': updateData
            };
            console.group('onBeforeUpdateEvent');
            console.log('calenderObject: ', calenderObject);
            console.log(updateData);
            console.groupEnd();
            const targetEvent = updateData.event;
            const changes = { ...updateData.changes };
            await this.calendarInstance.updateEvent(targetEvent.id, targetEvent.calendarId, changes);
            const beforeUpdateEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", "calenderObject", this.beforeUpdateScript);
            await beforeUpdateEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils, calenderObject);
        },
        // 删除网格中事件前的操作
        async onBeforeDeleteEvent({ title, id, calendarId }) {
            const calenderObject = {
                'id': id,
                'title': title,
                'calendarId': calendarId
            };
            console.group('onBeforeDeleteEvent');
            console.log('Event Info : ', title);
            console.groupEnd();
            await asyncthis.calendarInstance.deleteEvent(id, calendarId);
            const beforeDeleteEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", "calenderObject", this.beforeDeleteScript);
            await beforeDeleteEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils, calenderObject);
        },
        // 事件保存后，页面渲染后的操作
        async onAfterRenderEvent({ title }) {
            const calenderObject = {
                'title': title
            };
            console.group('onAfterRenderEvent');
            console.log('Event Info : ', title);
            console.groupEnd();
            const tempEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", "calenderObject", this.afterRenderScript);
            await tempEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils, calenderObject);
        },
        // 按周按天中点击某个日期标题的操作
        async onClickDayName({ date }) {
            const calenderObject = {
                'date': date
            };
            console.group('onClickDayName');
            console.log('Date : ', date);
            console.groupEnd();
            const tempClickEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", "calenderObject", this.clickDayNameScript);
            await tempClickEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils, calenderObject);
        },
        // 点击网格中的事件后的操作
        async onClickEvent({ nativeEvent, event }) {
            const calenderObject = {
                'nativeEvent': nativeEvent,
                'event': event
            };
            console.group('onClickEvent');
            console.log('MouseEvent : ', nativeEvent);
            console.log('Event Info : ', event);
            console.groupEnd();
            const tempClickEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", "calenderObject", this.clickScript);
            await tempClickEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils, calenderObject);
        },
        // 多时区点击
        onClickTimezonesCollapseBtn(timezoneCollapsed) {
            console.group('onClickTimezonesCollapseBtn');
            console.log('Is Timezone Collapsed?: ', timezoneCollapsed);
            console.groupEnd();
            const newTheme = {
                'week.daygridLeft.width': '100px',
                'week.timegridLeft.width': '100px',
            };
            this.calendarInstance.setTheme(newTheme);
        },
        onClickTodayButton() {
            this.calendarInstance.today();
            this.setDateRangeText();
        },
        onClickMoveButton(offset) {
            this.calendarInstance.move(offset);
            this.setDateRangeText();
        },
        // 设置显示文本  按月：年月 / 按周：年月日~月日 / 按天：年月日
        setDateRangeText() {
            const date = this.calendarInstance.getDate();
            const start = this.calendarInstance.getDateRangeStart();
            const end = this.calendarInstance.getDateRangeEnd();
            const startYear = start.getFullYear();
            const endYear = end.getFullYear();
            switch (this.selectedView) {
                case 'month':
                    this.dateRangeText = `${date.getFullYear()}.${date.getMonth() + 1}`;
                    return;
                case 'day':
                    this.dateRangeText = `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
                    return;
                default:
                    this.dateRangeText = `${startYear}.${start.getMonth() + 1}.${start.getDate()} - ${startYear !== endYear ? `${endYear}.` : ''}${end.getMonth() + 1}.${end.getDate()}`;
            }
        },
        // 加载前
        async beforeCreateFunction() {
            const beforeCreateEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", this.beforeCreateScript);
            await beforeCreateEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils);
        },
        // 加载后
        async afterCreateFunction() {
            const afterCreateEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", this.afterCreateScript);
            await afterCreateEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils);
        },
        // 设置样式
        setThemeData(val) {
            if (val) {
                this.themeData = val;
                return;
            }
            this.themeData = {
                common: {
                    border: '1px solid #ddd',
                    backgroundColor: 'white',
                    holiday: { color: '#f54f3d' },
                    saturday: { color: '#135de6' },
                    dayName: { color: '#333' },
                    today: { color: '#009688' },
                    // 点击选中网格
                    gridSelection: {
                        backgroundColor: 'rgba(19, 93, 230, 0.2)',
                        border: '1px solid #135de6',
                    },
                },
                month: {
                    dayName: {
                        borderLeft: 'none',
                        backgroundColor: 'inherit',
                    },
                    holidayExceptThisMonth: { color: '#f3acac' },
                    dayExceptThisMonth: { color: '#bbb' },
                    weekend: { backgroundColor: '#fafafa' },
                    moreView: { boxShadow: 'none' },
                    moreViewTitle: { backgroundColor: '#f4f4f4' },
                },
                week: {
                    dayName: {
                        borderTop: '1px solid #ddd',
                        borderBottom: '1px solid #ddd',
                        borderLeft: '1px solid #ddd',
                        backgroundColor: 'inherit',
                    },
                    today: {
                        color: '#009688',
                        backgroundColor: 'inherit',
                    },
                    pastDay: { color: '#999' },
                    panelResizer: { border: '1px solid #ddd' },
                    dayGrid: { borderRight: '1px solid #ddd' },
                    dayGridLeft: {
                        width: '100px',
                        backgroundColor: '',
                        borderRight: '1px solid #ddd',
                    },
                    weekend: { backgroundColor: 'inherit' },
                    timeGridLeft: {
                        width: '100px',
                        backgroundColor: '#fafafa',
                        borderRight: '1px solid #ddd',
                    },
                    timeGridLeftAdditionalTimezone: { backgroundColor: '#fdfdfd' },
                    timeGridHourLine: { borderBottom: '1px solid #eee' },
                    timeGridHalfHourLine: { borderBottom: '1px dotted #f9f9f9' },
                    timeGrid: { borderRight: '1px solid #ddd' },
                    nowIndicatorLabel: { color: '#135de6' },
                    nowIndicatorPast: { border: '1px solid rgba(19, 93, 230, 0.3)' },
                    nowIndicatorBullet: { backgroundColor: '#135de6' },
                    nowIndicatorToday: { border: '1px solid #135de6' },
                    nowIndicatorFuture: { border: '1px solid #135de6' },
                    pastTime: { color: '#999' },
                    futureTime: { color: '#333' },
                    gridSelection: { color: '#135de6' },
                },
            }
        }
    },
};
</script>

<style>
.buttons {
    display: inline-block;
    margin-left: 10px;
}

.buttons>button {
    margin-right: 5px;
}

.buttons>button:last-child {
    margin-right: 0;
}

.date-range {
    margin-left: 10px;
}
</style>