<template>
  <div>
    <el-divider
      :direction="direction"
      :content-position="contentPosition"
    >
      {{content}}
    </el-divider>
  </div>
</template>

<script>
export default {
  name: 'yes-divider',
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    direction: String,
    contentPosition: String,
    content: String,
    customStyle: String
  },
  watch: {
    direction: {
      handler(newVal, oldVal) {
        console.log('oldVal', newVal, oldVal )
        if(newVal === 'vertical') {
          this.addNewStyle('.el-divider--vertical {display: inline-block;width: 1px;height: 1em;margin: 0 8px;vertical-align: middle;position: relative;' + this.customStyle + '}')
        } else {
          this.addNewStyle('.el-divider--horizontal {display: block;height: 1px;width: 100%;margin: 24px 0;' + this.customStyle + '}')
        }
        // }
      },
      deep: false,  // 不开启深度监听，默认false
      immediate: false  // 初始化数据模型（首次绑定），不触发监听，默认false
    },
    customStyle: {
      handler(newVal, oldVal) {
        console.log('oldVal', oldVal, newVal)
        // if (newVal) {
        console.log('newVal=====', newVal)
        if(this.direction === 'vertical') {
          this.addNewStyle('.el-divider--vertical {display: inline-block;width: 1px;height: 1em;margin: 0 8px;vertical-align: middle;position: relative;' + newVal + '}')
        } else {
          this.addNewStyle('.el-divider--horizontal {display: block;height: 1px;width: 100%;margin: 24px 0;' + newVal + '}')
        }
        // }
      },
      deep: false,  // 不开启深度监听，默认false
      immediate: false  // 初始化数据模型（首次绑定），不触发监听，默认false
    }
  },
  data () {
    return {};
  },
  mounted () {
    if(this.direction === 'vertical') {
      this.addNewStyle('.el-divider--vertical {display: inline-block;width: 1px;height: 1em;margin: 0 8px;vertical-align: middle;position: relative;' + this.customStyle + '}')
    } else {
      this.addNewStyle('.el-divider--horizontal {display: block;height: 1px;width: 100%;margin: 24px 0;' + this.customStyle + '}')
    }
  },
  methods: {
    addNewStyle(newStyle) {
      let ele = document.getElementById('style_js_new')
      if (!ele) {
        ele = document.createElement('style')
        ele.type = 'text/css'
        ele.id = 'style_js_new'
        document.getElementsByTagName('head')[0].appendChild(ele)
      }
      ele.appendChild(document.createTextNode(newStyle))
    }
  },
}
</script>
<style>
</style>
