<template>
  <el-radio-group
    v-model="fvalue"
    :size="size"
    @change="change"
    :style="definedCss"
    :disabled="disabledValue"
    :class="{ titleCss, contentCss }"
  >
    <el-radio
      v-for="item in radioList"
      :key="item[itemKey ? itemKey : 'id']"
      :label="item[itemLable ? itemLable : 'value']"
    >
      {{ item[itemValue ? itemValue : "value"] }}
    </el-radio>
  </el-radio-group>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
  name: "yes-radio",
  props: {
    titleCss: String,
    contentCss: String,
    definedCss: Object,
    dataSource: String,
    viewDataSource: String,
    formCreateInject: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: "small",
    },
    itemKey: {
      type: String,
    },
    itemLable: {
      type: String,
    },
    itemValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    defaultOptios: {
      type: Array,
      default: () => [],
    },
    changeEvent: {
      type: String,
    },
  },
  data() {
    return {
      radioList: [],
      fvalue: "",
      fDataSource: this.dataSource,
      fViewDataSource: this.viewDataSource,
      disabledValue: false
    };
  },
  mounted() {
    this.disabledValue = this.disabled
    this.radioList = this.defaultOptios.map((item) => {
      let obj = {};
      obj[this.itemLable] = item.label;
      obj[this.itemValue] = item.value;
      return obj;
    });
    this.loadSourceData();

  },
  watch: {
    disabled(val) {
      this.disabledValue = val
    },
    fvalue(val) {
      this.$emit("input", val);
      utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
    },
  },
  methods: {
    setDisabled(val) {
      this.disabledValue = val
    },
    // 将组件的值返回
    getValue() {
      return this.fvalue;
    },
    // 修改组件的值
    setValue(val) {
      this.fvalue = val;
    },
    loadViewSourceData() {
      let val2 = utils.getSourceData(this.fViewDataSource);
      this.radioList = val2 !== undefined ? val2 : this.radioList;
    },
    // 读取绑定数据源数据
    loadSourceData() {
      let val1 = utils.getSourceData(this.fDataSource);
      this.fvalue = val1 !== undefined ? val1 : this.fvalue;
      // this.radioList = utils.getSourceData(this.optionSource);
      // this.fvalue = utils.getSourceData(this.fDataSource);
    },
    // 修改绑定数据源数据
    updateSourceData(val) {
      utils.updateSourceData(this.fDataSource, val);
    },
    async change(val) {
      if (this.fDataSource) {
        this.updateSourceData(val);
      }
      if (this.changeEvent) {
        const event = window.AsyncFunction(
          "vm",
          "api",
          "dao",
          "fApi",
          "utils",
          this.changeEvent
        );
        await event(
          this,
          this.formCreateInject.api,
          dao,
          this.formCreateInject,
          utils
        );
      }
    },
  },
};
</script>