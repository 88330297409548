<template>
    <el-input-number
        :min="min"
        :max="fMax"
        :step="step"
        :size="size"
        :label="label"
        v-model="fvalue"
        :controls="controls"
        :precision="precision"
        :disabled="disabledValue"
        :placeholder="placeholder"
        @change="change"
    ></el-input-number>
</template>
<script>
const utils = require('@/api/core/utils');
export default {
    name: 'yes-inputNumber',
    props: {
        titleCss: String,
        contentCss: String,
        definedCss: Object,
        dataSource: String,
        formCreateInject: {
            type: Object,
            required: true
        },
        size: {
            type: String
        },
        min: {
            type: Number
        },
        max: {
            type: Number
        },
        precision: {
            type: Number
        },
        disabled: {
            type: Boolean
        },
        controls: {
            type: Boolean
        },
        label: {
            type: String
        },
        type: {
            type: String
        },
        placeholder: {
            type: String
        },
        step: {
            type: Number
        }
    },
    data() {
        return {
            fvalue: undefined,
            fDataSource: this.dataSource,
            fMax: this.max,
            disabledValue: false
        };
    },
    mounted() {
        this.disabledValue = this.disabled;
        this.loadSourceData();
    },
    watch: {
        disabled(val) {
            this.disabledValue = val;
        },
        fvalue(val) {
            this.$emit('input', val);
            utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
        }
    },
    methods: {
        setDisabled(val) {
            this.disabledValue = val;
        },
        // 将组件的值返回
        getValue() {
            return this.fvalue;
        },
        // 修改组件的值
        setValue(val) {
            this.fvalue = val;
        },
        setMaxValue(num) {
            this.fMax = num;
        },
        // 读取绑定数据源数据
        loadSourceData() {
            let val = utils.getSourceData(this.fDataSource);
            this.fvalue = val !== undefined ? val : this.fvalue;
        },
        // 修改绑定数据源数据
        updateSourceData() {
            utils.updateSourceData(this.fDataSource, this.fvalue);
        },
        change() {
            // 绑定数据源时，输入框变化对应修复数据源数据
            if (this.fDataSource) {
                this.updateSourceData();
            }
        }
    }
};
</script>