<template>
    <audio 
          ref="audioPlayer"
          :loop="loop"
          :src="fvalue"
          :muted="muted"
          :style="fstyle"
          :width="fwidth"
          :height="fheight"
          :controls="controls"
          :autoplay="autoplay"
          @play="playAudio"
          @ended="handleEnd"
          @pause="pauseAudio"
          @timeupdate="handleTimeUpdate"
          @loadedmetadata="handleLoadedMetaData"
    ></audio>
</template>

<script>
const dao = require('@/api/core/dao');
const utils = require('@/api/core/utils');
export default {
    name: 'yes-audio',
    props: {
        formCreateInject: {
            type: Object,
            required: true
        },
        src: String,
        // 循环播放音频。默认值: false, 表示不循环。
        loop: {
            type: Boolean,
            default: false
        },
        // 是否静音的布尔值。默认值: false, 表示有声音。
        muted: {
            type: Boolean,
            default: false
        },
        srcType: {
            type: String,
            default: 'link'
        },
        customStyle: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        },
        controls: {
            type: Boolean,
            default: true
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        endEvent: String,
        playEvent: String,
        pauseEvent: String,
        timeUpdateEvent: String,
        loadedmetadataEvent: String
    },
    data() {
        return {
            fvalue: '',
            fstyle: '',
            fwidth: '',
            fheight: '',
            duration: 0,
            currentTime: 0,
            fLrcList: null
        };
    },
    computed: {},
    watch: {},
    created() {
        this.fvalue = this.src;
        this.fstyle = this.customStyle;
    },
    methods: {
        hide() {
            this.$refs.audioPlayer.style['visibility'] = 'hidden';
        },
        show() {
            this.$refs.audioPlayer.style['visibility'] = 'visible';
        },
        getDuration() {
            return this.duration;
        },
        getCurrentTime() {
            return this.currentTime;
        },
        setStyle(style) {
            this.fstyle = style;
        },
        getStyle() {
            return this.fstyle;
        },
        setWidth(width) {
            this.fwidth = width;
        },
        getWidth() {
            return this.fwidth;
        },
        setHeight(height) {
            this.fheight = height;
        },
        getHeight() {
            return this.fheight;
        },
        getRef() {
            return this.$refs.audioPlayer;
        },
        setValue(value) {
            this.fvalue = value;
        },
        getValue() {
            return this.fvalue;
        },
        async replayAudio() {
            this.$refs.audioPlayer.currentTime = 0;
            setTimeout(() => {
                this.playAudio();
            }, 50);
        },
        async pauseAudio() {
            // console.log('pauseAudio');
            if (!this.fvalue) return;
            this.$refs.audioPlayer.pause();
            if (this.pauseEvent) {
                const fn = window.AsyncFunction('vm', 'api', 'dao', 'fApi', 'utils', 'ref', 'duration', 'currentTime', this.pauseEvent);
                await fn(this, this.formCreateInject.api, dao, this.formCreateInject, utils, this.$refs.audioPlayer, this.duration, this.currentTime);
            }
        },
        async playAudio() {
            // console.log('playAudio');
            if (!this.fvalue) return;
            this.$refs.audioPlayer.play();
            if (this.playEvent) {
                const fn = window.AsyncFunction('vm', 'api', 'dao', 'fApi', 'utils', 'ref', 'duration', 'currentTime', this.playEvent);
                await fn(this, this.formCreateInject.api, dao, this.formCreateInject, utils, this.$refs.audioPlayer, this.duration, this.currentTime);
            }
        },
        async handleEnd() {
            // console.log('handleEnd');
            // 可以在这里添加播放结束后的逻辑
            if (this.endEvent) {
                const fn = window.AsyncFunction('vm', 'api', 'dao', 'fApi', 'utils', 'ref', 'duration', 'currentTime', this.endEvent);
                await fn(this, this.formCreateInject.api, dao, this.formCreateInject, utils, this.$refs.audioPlayer, this.duration, this.currentTime);
            }
        },
        async handleTimeUpdate() {
            // console.log('handleTimeUpdate');
            this.currentTime = this.$refs.audioPlayer.currentTime;
            // console.log('handleTimeUpdate_currentTime', this.currentTime);
            // console.log('handleTimeUpdate_duration', this.duration);
            if (this.timeUpdateEvent) {
                const fn = window.AsyncFunction('vm', 'api', 'dao', 'fApi', 'utils', 'ref', 'duration', 'currentTime', this.timeUpdateEvent);
                await fn(this, this.formCreateInject.api, dao, this.formCreateInject, utils, this.$refs.audioPlayer, this.duration, this.currentTime);
            }
        },
        async handleLoadedMetaData() {
            // console.log('handleLoadedMetaData');
            this.duration = this.$refs.audioPlayer.duration;
            // console.log('handleTimeUpdate_duration', this.duration);
            if (this.loadedmetadataEvent) {
                const fn = window.AsyncFunction('vm', 'api', 'dao', 'fApi', 'utils', 'ref', 'duration', 'currentTime', this.loadedmetadataEvent);
                await fn(this, this.formCreateInject.api, dao, this.formCreateInject, utils, this.$refs.audioPlayer, this.duration, this.currentTime);
            }
        }
    }
};
</script>

<style></style>
