<template>
  <el-tag
    :type="type"
    :closable="closable"
    :disable-transitions="disableTransitions"
    :hit="hit"
    :color="color"
    :size="size"
    :effect="effect"
  >
    {{values}}
  </el-tag>
</template>

<script>
export default {
  name: 'yes-tag',
  props: {
    values: {
      type: String
    },
    type: {
      type: String
    },
    closable: {
      type: Boolean
    },
    disableTransitions: {
      type: Boolean
    },
    hit: {
      type: Boolean
    },
    color: {
      type: String
    },
    size: {
      type: String
    },
    effect: {
      type: String
    }

  }
}
</script>

<style>
</style>