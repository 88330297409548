<template>
    <div>
        <span v-if="isDesigner()">数据列表</span>
        <slot />
    </div>
</template>

<script>
const utils = require("@/api/core/utils");
export default {
    name: "yes-data-array",
    components: {},
    props: {
        dataSource: String,
        repeat: String,
        formCreateInject: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fvalue: [],
            rule: null,
            panelName: "",
            length: 0,
            isDrag: true,
            showName: "",
            fDataSource: this.dataSource,
            changeLoadding: false
        };
    },
    watch: {
        fvalue(val, old) {
            this.length = val ? val.length : 0;
            if (JSON.stringify(val) !== JSON.stringify(old)) {
                this.changeRule();
            }
        }
    },
    created() {
        let copyRule = this.findChildByName(this.formCreateInject, this.repeat);
        if (copyRule) {
            this.rule = { ...copyRule };
            this.panelName = copyRule.__fc__.parent.field ? copyRule.__fc__.parent.field : copyRule.__fc__.parent.name;
        }
    },
    mounted() {},
    methods: {
        isDesigner() {
            return this.$parent.$options._componentTag === "dragTool" || this.$parent.$parent.$parent.$options._componentTag === "dragTool";
        },
        getValue() {
            return this.fvalue;
        },
        setValue(value, index) {
            if (index != undefined) {
                this.fvalue[index] = value;
            } else {
                this.fvalue = value;
            }
        },
        // 读取绑定数据源数据
        loadSourceData() {
            this.fvalue = utils.getSourceData(this.fDataSource);
        },
        async changeRule() {
            // if (this.changeLoadding) return;
            this.changeLoadding = true;
            if (this.panelName) {
                let childrenList = [];
                for (let i = 0; i < this.length; i++) {
                    let newRule = JSON.parse(JSON.stringify(this.rule));
                    let objectRule = this.findObjectRule(newRule);
                    if (objectRule) {
                        objectRule.name = objectRule.name + i;
                        objectRule.props.dataSource = this.fDataSource + "." + i;
                        objectRule.props.fIndex = i;
                        childrenList.push(newRule);
                    }
                }
                await this.formCreateInject.api.updateRule(this.panelName, {
                    children: childrenList
                });
            }
            utils.exec(this.fDataSource, "noticeChange");
        },
        findObjectRule(item) {
            let childrenItem;
            for (let i = 0; i < item.children.length; i++) {
                if (item.children[i] && item.children[i].type == "yes-data-object") {
                    childrenItem = item.children[i];
                    break;
                } else if (item.children[i].children && item.children[i].children.length > 0) {
                    childrenItem = this.findObjectRule(item.children[i]);
                    if (childrenItem) {
                        break;
                    }
                }
            }
            if (childrenItem) {
                return childrenItem;
            } else {
                return undefined;
            }
        },
        findChildByName(item, name) {
            let childrenItem;
            for (let i = 0; i < item.children.length; i++) {
                if ((item.children[i].field && item.children[i].field == name) || (item.children[i].name && item.children[i].name == name)) {
                    childrenItem = item.children[i];
                    break;
                } else if (item.children[i].children && item.children[i].children.length > 0) {
                    childrenItem = this.findChildByName(item.children[i], name);
                    if (childrenItem) {
                        break;
                    }
                }
            }
            return childrenItem;
        }
    }
};
</script>

