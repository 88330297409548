<template>
    <div>
        <div id="pivotContainer"></div>
    </div>
</template>

<script>
import WebDataRocks from "./webDatarocks/webdatarocks.js";
import "./webDatarocks/webdatarocks.highcharts.js";
import "./webDatarocks/webdatarocks.css";
const utils = require("@/api/core/utils");
const dao = require("@/api/core/dao");

export default {
    name: "yesWebDatarocks",
    props: {
        formCreateInject: {
            type: Object,
            required: true,
        },
        dataSource: String,
        dataFields: {},
        allConfig: {},
        toolbarShow: Boolean,
        clickEvent: String,
        doubleClickEvent: String,
        customizeCellScript: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            isDrag: false,
            isLoad: false,
            fvalue: [],
            fDataSource: this.dataSource,
            webdatarocks: null,
            config: {
                dataSource: {
                    data: [this.dataFields],
                },
                localization: "./webDatarocks/zh.json",
            },
        };
    },
    watch: {
        dataFields(val) {
            if (this.webdatarocks) {
                this.config = this.webdatarocks.getReport();
            }
            this.config.dataSource.data[0] = val;
            this.init();
        },
        fvalue(val) {
            if (val && val.length >= 0) {
                let data = [];
                data.push(this.config.dataSource.data[0]);
                data = data.concat(val);
                this.updateData(data);
            }
        },
    },
    mounted() {
        if (this.$parent.$options._componentTag === "dragTool") {
            this.isDrag = true;
        }
        this.config = this.allConfig ? this.allConfig : this.config;
        this.init();
    },
    methods: {
        getConponent() {
            return this.webdatarocks;
        },
        getConfig() {
            this.formCreateInject.rule.props.allConfig = this.webdatarocks.getReport();
            this.config = this.webdatarocks.getReport();
            return this.config;
        },
        getWebdatarocks() {
            return this.webdatarocks;
        },
        setConfig(config) {
            this.config = config;
            this.init();
        },
        updateData(data) {
            const that = this;
            if (this.isLoad) {
                this.webdatarocks.updateData({ data: data });
                this.createChart();
            } else {
                setTimeout(() => {
                    that.updateData(data);
                }, 1000);
            }
        },
        createChart() {
            const that = this;
            this.formCreateInject.api
                .all()
                .filter((item) => item.props.rockId != undefined && that.formCreateInject.prop.name === item.props.rockId)
                .forEach((item) => {
                    item.__fc__.el.init(that.webdatarocks);
                });
        },
        init() {
            const that = this;
            if (this.webdatarocks) {
                this.webdatarocks.dispose();
                this.isLoad = false;
            }
            let toolbar = this.toolbarShow;

            if (this.isDrag) {
                // 编辑页面开启工具栏
                toolbar = true;
            }
            this.webdatarocks = new WebDataRocks({
                container: "#pivotContainer",
                height: 650,
                report: this.config,
                toolbar: toolbar,
                beforetoolbarcreated: this.customizeToolbar,
                customizeCell: this.customizeCellFunction,
                reportcomplete: function () {},
            });
            this.webdatarocks.on("dataloaded", function () {
                that.isLoad = true;
                that.createChart();
            });
            this.webdatarocks.on("cellclick", async function (cell) {
                await that.clickFunc(cell);
            });
            this.webdatarocks.on("celldoubleclick", async function (cell) {
                await that.doubleClickFunc(cell);
            });
        },
        async customizeCellFunction(cellBuilder, cellData) {
            const func = window.AsyncFunction("vm", "api", "dao", "utils", "cellBuilder", "cellData", this.customizeCellScript);
            await func(this, this.formCreateInject.api, dao, utils, cellBuilder, cellData);
        },
        customizeToolbar(toolbar) {
            if (toolbar === null) return;
            const that = this;
            var tabs = toolbar.getTabs(); // get all tabs from the toolbar
            toolbar.getTabs = function () {
                delete tabs[0]; // delete the first tab
                delete tabs[1];
                if (that.isDrag) {
                    tabs[2].handler = that.newSave;
                } else {
                    delete tabs[2];
                }
                return tabs;
            };
        },
        newSave() {
            this.formCreateInject.rule.props.allConfig = this.webdatarocks.getReport();
            this.config = this.webdatarocks.getReport();
            utils.messageSuccess("保存成功！");
        },
        getValue() {
            return this.fvalue;
        },
        setValue(val) {
            this.fvalue = val;
        },
        loadSourceData() {
            this.fvalue = utils.getSourceData(this.fDataSource);
        },
        async clickFunc(item) {
            if (!this.isDrag) {
                const func = window.AsyncFunction("vm", "api", "dao", "utils", "item", this.clickEvent);
                await func(this, this.formCreateInject.api, dao, utils, item); //
            }
        },
        async doubleClickFunc(item) {
            if (!this.isDrag) {
                const func = window.AsyncFunction("vm", "api", "dao", "utils", "item", this.doubleClickEvent);
                await func(this, this.formCreateInject.api, dao, utils, item); //
            }
        },
    },
};
</script>

<style scope>
::v-deep .webdatarocks .wdr-cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
