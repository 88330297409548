<template>
    <el-input
        :type="type"
        :size="size"
        :rows="rows"
        v-model="fvalue"
        :resize="resize"
        :style="definedCss"
        :readonly="readonly"
        :autofocus="autofocus"
        :minlength="minlength"
        :maxlength="maxlength"
        :clearable="clearable"
        :placeholder="placeholder"
        :disabled="disabledValue"
        :prefix-icon="prefixIcon"
        :suffix-icon="suffixIcon"
        :show-password="showPassword"
        :auto-complete="fAutocomplete"
        :show-word-limit="showWordLimit"
        :class="{ titleCss, contentCss }"
        @blur="handleBlur"
        @input="handleInput"
        @focus="handleFocus"
        @change="handleChange"
        @keydown.enter.native="handleEnterPress"
    ></el-input>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yes-input",
    props: {
        titleCss: String,
        contentCss: String,
        definedCss: String,
        dataSource: String,
        type: {
            type: String
        },
        clearable: {
            type: Boolean
        },
        placeholder: {
            type: String
        },
        maxlength: {
            type: Number
        },
        minlength: {
            type: Number
        },
        showWordLimit: {
            type: Boolean
        },
        showPassword: {
            type: Boolean
        },
        size: {
            type: String
        },
        disabled: {
            type: Boolean
        },
        prefixIcon: {
            type: String
        },
        suffixIcon: {
            type: String
        },
        rows: {
            type: Number
        },
        autocomplete: {
            type: String
        },
        readonly: {
            type: Boolean
        },
        resize: {
            type: String
        },
        autofocus: {
            type: Boolean
        },
        value: {
            type: String
        },
        formCreateInject: {
            type: Object,
            required: true
        },
        enterEventScript: {
            type: String
        },
        inputEventScript: {
            type: String
        },
        modelValue: {
            type: [String, Number]
        },
        missFocusEvent: {
            type: String
        },
        changeEvent: {
            type: String
        },
        focusEvent: {
            type: String
        }
    },
    data() {
        return {
            fvalue: "",
            fDataSource: this.dataSource,
            disabledValue: false,
            fAutocomplete: this.autocomplete
        };
    },
    mounted() {
        if (this.isDarg()) {
            this.fvalue = "";
            this.fAutocomplete = "new-password";
            return;
        }
        this.disabledValue = this.disabled;
        if (this.modelValue != "" && this.modelValue != undefined) {
            this.fvalue = this.modelValue;
        }
        let source = this.fDataSource ? this.fDataSource.split(".") : [""];
        const tempDataSource = this.formCreateInject.api.all().find((item) => item.type === "yes-dataSource" && item.name === source[0]);
        if (!tempDataSource) {
            return;
        }
        this.loadSourceData();
    },
    watch: {
        disabled(val) {
            this.disabledValue = val;
        },
        fvalue(val) {
            if (this.fDataSource) {
                this.updateSourceData();
            }
            this.$emit("input", val);
            utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
        },
        modelValue(val) {
            if (this.fvalue == val) {
                return;
            }
            this.fvalue = val;
        }
    },
    methods: {
        isDarg() {
            let parent = this.$parent;
            if (parent) {
                while (parent) {
                    if (!parent) {
                        return false;
                    }
                    if (parent.$options._componentTag == "dragTool") {
                        return true;
                    }
                    parent = parent.$parent;
                }
            }
            return false;
        },
        setDisabled(val) {
            this.disabledValue = val;
        },
        getValue() {
            if (this.fvalue && this.type == "number") {
                let dian = 0;
                const tf = typeof this.fvalue;
                if (tf === "string" && this.fvalue.indexOf(".")) {
                    let l = this.fvalue.split(".");
                    if (l[1]) dian = l[1].length;
                }
                return dian === 0 ? Number(this.fvalue) : Number(this.fvalue).toFixed(dian);
            }
            return this.fvalue;
        },
        // 修改组件的值
        setValue(val) {
            if (typeof val != String) {
                if (val) {
                    this.fvalue = val.toString();
                } else {
                    this.fvalue = val;
                }
                return;
            }
            this.fvalue = val;
        },
        noticeParent() {},
        // 读取绑定数据源数据
        loadSourceData() {
            let val = utils.getSourceData(this.fDataSource);
            let value = val !== undefined ? val : this.fvalue;
            if (this.type === "number" && value !== "" && value !== undefined && value !== null) {
                this.fvalue = value.toString();
            } else {
                this.fvalue = value;
            }
        },
        // 修改绑定数据源数据
        updateSourceData() {
            if (this.type === "number") {
                let dian = 0;
                if (this.fvalue.indexOf(".")) {
                    let l = this.fvalue.split(".");
                    if (l[1]) dian = l[1].length;
                }
                utils.updateSourceData(this.fDataSource, dian === 0 ? Number(this.fvalue) : Number(this.fvalue).toFixed(dian));
            } else {
                utils.updateSourceData(this.fDataSource, this.fvalue);
            }
        },
        async handleInput(event) {
            // 绑定数据源时，输入框变化对应修复数据源数据
            // if (this.fDataSource) {
            //   this.updateSourceData();
            // }
            if (!this.inputEventScript || this.inputEventScript == null) {
                return;
            }
            const inputEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", "event", this.inputEventScript);
            await inputEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils, event);
        },
        async handleEnterPress(event) {
            if (!this.enterEventScript || this.enterEventScript == null) {
                return;
            }
            //监听键盘回车事件
            const enterEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", "event", this.enterEventScript);
            await enterEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils, event);
        },
        async handleBlur() {
            if (!this.missFocusEvent || this.missFocusEvent == null) {
                return;
            }
            const blurEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", this.missFocusEvent);
            await blurEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils);
        },
        async handleChange() {
            if (!this.changeEvent || this.changeEvent == null) {
                return;
            }
            const change = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", this.changeEvent);
            await change(this, this.formCreateInject.api, dao, this.formCreateInject, utils);
        },
        async handleFocus() {
            if (!this.focusEvent || this.focusEvent == null) {
                return;
            }
            const focus = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", this.focusEvent);
            await focus(this, this.formCreateInject.api, dao, this.formCreateInject, utils);
        }
    }
};
</script>

<style></style>