<template>
    <el-select
        v-model="fvalue"
        :placeholder="placeholder"
        :disabled="disabledValue"
        :clearable="clearable"
        :filterable="filterable"
        :multiple="multiple"
        @change="selectChange"
        :size="size"
    >
        <el-option
            v-for="item in options"
            :disabled="item.disabled"
            :style="{width: optionWidth}"
            :key="item[itemkey ? itemkey : 'value']"
            :value="item[itemVal ? itemVal : 'value']"
            :label="item[itemLable ? itemLable : 'label']"
        />
    </el-select>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yes-select",
    props: {
        titleCss: String,
        contentCss: String,
        definedCss: Object,
        dataSource: String,
        viewDataSource: String,
        formCreateInject: {
            type: Object,
            required: true
        },
        size: {
            type: String
        },
        placeholder: {
            type: String
        },
        disabled: {
            type: Boolean
        },
        itemkey: {
            type: String
        },
        itemLable: {
            type: String
        },
        itemVal: {
            type: String
        },
        changeEvent: {
            type: String
        },
        clearable: {
            type: Boolean
        },
        filterable: {
            type: Boolean
        },
        multiple: {
            type: Boolean
        },
        defaultOptios: {
            type: Array,
            default: () => []
        },
        defaultValues: {
            type: String,
            default: ""
        },
        optionWidth: {
            type: String,
            default: "100% !important"
        }
    },
    data() {
        return {
            options: [],
            fvalue: "",
            fDataSource: this.dataSource,
            fViewDataSource: this.viewDataSource,
            disabledValue: false,
            fdefaultValues: this.defaultValues
        };
    },
    mounted() {
        this.disabledValue = this.disabled;
        this.options = this.defaultOptios.map((item) => {
            let obj = {};
            obj[this.itemLable] = item.label;
            obj[this.itemVal] = item.value;
            return obj;
        });
        if (this.fdefaultValues) {
            this.fdefaultValues = JSON.parse(this.fdefaultValues);
        }
        if (this.multiple) {
            this.fvalue = this.fdefaultValues;
        } else {
            this.fvalue = this.fdefaultValues[0];
        }
        this.loadSourceData();
    },
    watch: {
        disabled(val) {
            this.disabledValue = val;
        },
        fvalue(val, old) {
            if (this.fDataSource) {
                this.updateSourceData(val);
            }
            this.$emit("input", val);
            //console.log('val', val)
            //console.log('old', old)
            // 初始化不赋值
            if (Array.isArray(val) && val.length === 0 && old === "") return;
            utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
        }
    },
    methods: {
        setDisabled(val) {
            this.disabledValue = val;
        },
        // 将组件的值返回
        getValue() {
            return this.fvalue;
        },
        // 修改组件的值
        setValue(val) {
            this.fvalue = val;
        },
        setOptionsData(data) {
            this.options = data;
        },
        loadViewSourceData() {
            let val2 = utils.getSourceData(this.fViewDataSource);
            this.options = val2 !== undefined ? val2 : this.options;
        },
        // 读取绑定数据源数据
        loadSourceData() {
            let val1 = utils.getSourceData(this.fDataSource);
            if (this.multiple && !Array.isArray(val1) && val1) {
                val1 = val1.split(",");
            }
            this.fvalue = val1 !== undefined ? val1 : this.fvalue;
            // this.options = utils.getSourceData(this.optionSource);
            // this.fvalue = utils.getSourceData(this.fDataSource);
        },
        // 修改绑定数据源数据
        updateSourceData(val) {
            utils.updateSourceData(this.fDataSource, val);
        },
        async selectChange(val) {
            if (this.changeEvent) {
                const changeEvent = window.AsyncFunction("vm", "api", "dao", "value", "utils", this.changeEvent);
                await changeEvent(this, this.formCreateInject.api, dao, val, utils);
            }
        }
    }
};
</script>

<style scoped></style>
