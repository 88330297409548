<template>
  <el-breadcrumb-item
    :to="{path: to}"
    :replace="replace"
  >
    {{label}}
    <slot></slot>
  </el-breadcrumb-item>
</template>

<script>
export default {
  name: 'yes-breadcrumb-item',
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    label: String,
    to: String,
    replace: Boolean,
  },
  data () {
    return {
    }
  },
  computed: {
    isDesigner () {
      if (this.formCreateInject.children) {
        return this.formCreateInject.children.findIndex(item => item.type === 'DragTool') > -1;
      }
      return false;
    }
  },
  watch: {},
  created () { },
  methods: {
    click (value) {
      console.log('click value', value);
    }
  }
}
</script>

<style>
</style>