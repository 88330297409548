<template>
  <el-alert
    :title="fvalue.title"
    :description="fvalue.description"
    :type="type"
    :show-icon="showIcon"
    :closable="closable"
    :center="center"
    :effect="effect"
  >
    <slot />
  </el-alert>
</template>

<script>
const utils = require("@/api/core/utils");
// const dao = require('@/api/core/dao');
export default {
  name: "yesAlert",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    showIcon: Boolean,
    closable: Boolean,
    center: Boolean,
    effect: String,
    type: String,
  },
  data () {
    return {
      fvalue: {
        title: "",
        description: "",
        fDataSource: this.dataSource
      }
    };
  },
  watch: {},
  methods: {
    getValue () {
      return this.fvalue;
    },
    setValue (val) {
      this.fvalue = val;
    },
    loadSourceData () {
      this.fvalue = utils.getSourceData(
        this.fDataSource
      );
    },
    updateSourceData () {
      utils.updateSourceData(this.fDataSource, this.fvalue);
    },
    setTitleAndDescription (title, description) {
      this.fvalue.title = title || "这里是标题";
      this.fvalue.description = description || "这里是文字说明";
    },
  },
}
</script>
