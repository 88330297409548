<template>
  <el-drawer
    ref="yes_drawer"
    :visible="visible || designerVisable"
    :title="title"
    :direction="direction"
    :size="size"
    :append-to-body="false"
    :modal="false"
    :class="designerVisable ? 'designer-drawer__wrapper' : 'el-drawer__wrapper'"
    :before-close="beforeCloseEvent"
  >
    <!-- 
    :close-on-press-escape="closeOnPressEscape"
    :custom-class="customClass"
    :destroy-on-close="destroyOnClose"
    :modal="modal"
    :modal-append-to-body="modalAppendToBody"
    :show-close="showClose"
    :wrapperClosable="wrapperClosable"
    :withHeader="withHeader" -->
    <slot />
  </el-drawer>
</template>

<script>
const utils = require("@/api/core/utils");
const dao = require("@/api/core/dao");
export default {
  name: "yesDrawer",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    title: String,
    direction: String,
    size: String,
    beforeClose: String,
    // appendToBody: Boolean,
    // closeOnPressEscape: Boolean,
    // customClass: String,
    // destroyOnClose: Boolean,
    // modal: Boolean,
    // modalAppendToBody: Boolean,
    // showClose: Boolean,
    // wrapperClosable: Boolean,
    // withHeader: Boolean,
  },
  data() {
    return {
      visible: false,
      designerVisable: false,
      modalAppendToBody: false,
    };
  },
  watch: {},
  created() {
    if (this.$parent && this.$parent.$options._componentTag === "dragTool") {
      this.designerVisable = true;
      return;
    }
  },
  mounted() {
    this.$refs.yes_drawer.rendered = true;
  },
  updated() {},
  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    async beforeCloseEvent() {
      const beforeClose = window.AsyncFunction(
        "vm",
        "api",
        "rule",
        "dao",
        "utils",
        this.beforeClose
      );
      await beforeClose(
        this,
        this.formCreateInject.api,
        this.formCreateInject.rule,
        dao,
        utils
      );
      this.visible = false;
    },
  },
};
</script>

<style>
.el-drawer__wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.designer-drawer__wrapper {
  width: 100%;
  height: 100%;
  position: unset !important;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
</style>

