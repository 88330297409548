
<template>
    <div class="hello" style="width:100%;height:100%;position:relative">
        <ElButton @click="visible=true" style="width: 100%;">{{ title }}</ElButton>
        <ElDialog :visible.sync="visible" :close-on-click-modal="false" width="96%" top="20px" height="80vh" append-to-body>
            <div style="width:100%; height:70vh;position:relative">
                <div id="luckysheet"
                    style="margin:0px;padding:0px;position:absolute;width:100%;left: 0px;top: 0px;bottom:0px;"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <ElButton type="primary" @click="upload" size="small" style="float:left">上传模板</ElButton>
                <ElButton @click="visible = false" size="small">取 消</ElButton>
                <ElButton type="primary" @click="onOk" size="small">确 定</ElButton>
            </span>
        </ElDialog>
    </div>
</template>
  
  <script>
import LuckyExcel from 'luckyexcel'
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: 'yesWorkbookEdit',
    props: {
        formCreateInject: {
            type: Object,
            required: true,
        },
        value: [Array,String],
        title: {
            type: String,
            default: '编辑格式'
        },
    },
    data() {
        return {
            visible: false,
            dataJson: [
                {
                    "name": "表格1",
                    "color": "",
                    "index": 0,
                    "status": 0,
                    "order": 0,
                    "celldata": [],
                    "config": {}
                }
            ]
        }

    },
    watch: {
        visible(n) {
            if (n) {
                setTimeout(() => {
                    this.load();
                }, 200);
            } else {
                // console.log(".....destroy")
                // luckysheet.destroy()
            }
        }
    },
    async created() {
    },
    async mounted() {
    },
    methods: {
        load() {
            console.log(".....load")
            this.dataJson = [
                {
                    "name": "Sheet1",
                    "color": "",
                    "index": 0,
                    "status": 0,
                    "order": 0,
                    "celldata": [],
                    "config": {}
                }
            ]
            if(this.value != '' && this.value !== [] && this.value != undefined) {
                this.dataJson = this.value
            }
            luckysheet.create({
                container: 'luckysheet', // 设定DOM容器的id
                title: '', // 设定表格名称
                lang: 'zh', // 设定表格语言
                plugins: [],
                data: this.dataJson
            });
        },
        setJson(dataJson) {
            this.dataJson = dataJson
        },
        getJson() {
            return luckysheet.getAllSheets()
        },
        upload() {
            const vm = this
            var input = document.createElement('input')
            input.style.width="100%";
            input.type = 'file'//添加file类型
            // input.accept=".pdf" //限制只能上传PDF文件
            input.style.display="none";
            input.id = 'files';
            document.body.appendChild(input);
            input.click();
            input.onchange = async(event) => {
                vm.uploadExcel(event)
            }
        },
        uploadExcel(evt) {
            const files = evt.target.files;
            if (files == null || files.length == 0) {
                //   utils.messageError("没有选择文件");
                return;
            }
            let name = files[0].name;
            let suffixArr = name.split("."), suffix = suffixArr[suffixArr.length - 1];
            if (suffix != "xlsx") {
                utils.messageError("目前只支持上传xlsx格式文件");
                return;
            }
            LuckyExcel.transformExcelToLucky(files[0], function (exportJson, luckysheetfile) {
                if (exportJson.sheets == null || exportJson.sheets.length == 0) {
                    utils.messageError("未找到文件数据");
                    return;
                }
                window.luckysheet.destroy();
                window.luckysheet.create({
                    container: 'luckysheet', //luckysheet is the container id
                    showinfobar: false,
                    data: exportJson.sheets,
                    title: exportJson.info.name,
                    userInfo: exportJson.info.name.creator
                });
            });
        },
        onOk() {
            this.$emit('input', this.getJson());
            this.visible = false;
        },
    }
}
</script>
  <<style lang="scss">
  .luckySheet {
      margin: 0px;
      padding: 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
  }
  
  .luckysheet_info_detail {
      display: none !important;
  }
  </style>
  