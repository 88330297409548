<template>
    <div style="height:auto;">
        <iframe
            ref="iframeRef"
            frameborder="0"
            scrolling="auto"
            :id="id"
            :src="furl"
            :width="width"
            :height="height"
        >
        </iframe>
    </div>
</template>
  
<script>
export default {
    name: "yes-iframe",
    props: {
        formCreateInject: {
            type: Object,
            required: true
        },
        url: {
            type: String
        },
        width: {
            type: String
        },
        height: {
            type: String
        },
        iframeId: {
            type: String
        },
        fontFamily: {
            type: String,
            default: ""
        },
        fontFamilyTime: {
            type: Number,
            default: 1000
        }
    },
    data() {
        return {
            id: "",
            furl: this.url
        };
    },
    watch: {
        width(val) {
            this.setIframeHeightAfterLoad();
        },
        height(val) {
            this.setIframeHeightAfterLoad();
        }
    },
    created() {},
    mounted() {
        this.setIframeHeightAfterLoad();
        this.setIframeFontSize();
        this.setId();
        // this.postMessage()
    },
    methods: {
        getUrl() {
            return this.furl;
        },
        setUrl(val) {
            this.furl = val;
        },
        reLoad() {
            document.getElementById(this.id).contentWindow.location.reload(true);
        },
        setId() {
            if (this.iframeId) {
                this.id = this.iframeId;
            } else {
                this.id = this.formCreateInject.rule.name;
            }
        },
        // 设置宽高
        setIframeHeightAfterLoad() {
            const oIframe = this.$refs.iframeRef;
            // const deviceWidth = document.documentElement.clientWidth;
            // const deviceHeight = document.documentElement.clientHeight;
            // oIframe.style.width = (Number(deviceWidth)) + 'px'; //数字是页面布局宽度差值
            // oIframe.style.width = '98%'; //数字是页面布局宽度差值
            // oIframe.style.height = (Number(deviceHeight) - 20) + 'px'; //数字是页面布局高度差
            oIframe.style.width = this.width || "98%"; //数字是页面布局宽度差值
            oIframe.style.height = this.height || "100px"; //数字是页面布局高度差
        },
        setIframeFontSize() {
            if (!this.fontFamily) return;
            const oIframe = this.$refs.iframeRef;
            oIframe.addEventListener("load", () => {
                // 延时获取body元素
                setTimeout(() => {
                    var iframeDocument = oIframe.contentDocument || oIframe.contentWindow.document;
                    var bodyElement = iframeDocument.querySelector("body");
                    console.info("bodyElement", bodyElement);
                    if (bodyElement) {
                        bodyElement.style.fontFamily = this.fontFamily;
                        console.info("bodyElement.style.fontFamily", bodyElement.style.fontFamily);
                    }
                }, this.fontFamilyTime); // 延时时长，单位为毫秒 (这里的1000表示延时1秒)
            });
        },
        getWindow() {
            let id = this.iframeId ? this.iframeId : this.formCreateInject.rule.name;
            return document.getElementById(id);
        }
        // // 将组件的值返回
        // postMessage () {
        //   window.addEventListener('message', function (event) {
        //     //此处执⾏事件
        //     console.log()
        //     console.log('监听⼦页⾯的传参', event)
        //   })
        // },
        // sendIframeMessage (data = {}) {
        //   console.log('我是父页面,我要向⼦页面传参', data)
        //   let res = {
        //     data: data
        //   };
        //   this.$refs.iframeRef.contentWindow.postMessage(res, "*");
        // }
    }
};
</script>
  