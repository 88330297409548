<template>
  <div id="video-div">
    <!-- <canvas id="video-canvas"  :width="width" :height="height"  :style="{'width':width,'height':height}" @click="playOrStop()"></canvas> -->
  </div>
</template>
<script>
export default {
  name: "yes-text",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    url: String,
    width: {
      type: String,
      default: "1366px",
    },
    height: {
      type: String,
      default: "750px",
    },
  },
  data() {
    return {
      fUrl: "",
      player: "",
    };
  },
  created() {
    this.fUrl = this.url;
  },
  methods: {
    setUrl(url) {
      this.fUrl = url;
    },
    getUrl() {
      return this.fUrl;
    },
    playOrStop() {
      if (this.player.isPlaying) {
        this.player.pause();
      } else {
        this.player.play();
      }
    },
    start() {
      let divs = document.getElementById("video-div");
      var canvas_div = document.createElement("div");
      let innerHTML = `<canvas id="video-canvas"  width="${this.width}" height="${this.height}"  style="width:${this.width};height:${this.height}"></canvas>`;
      canvas_div.innerHTML = innerHTML;
      divs.appendChild(canvas_div);
      let canvas = document.getElementById("video-canvas");
      this.player = new JSMpeg.Player(this.fUrl, {
        canvas: canvas,
        loop: true,
        autoplay: true,
      });
    },
    play() {
      this.player.play();
    },
    stop() {
      this.player.pause();
    },
    destroy() {
      document.getElementById("video-div").innerHTML = "";
      this.player.destroy();
    },
  },
};
</script>
