<template>
  <div>
    <div
      v-if="isDrag"
      style="display: flex; color: #606266"
    >
      <span style="margin-left: 40px">{{ "tree:" }}</span>
      <span>{{ formCreateInject.rule.name }}</span>
    </div>
    <el-tree
      ref="tree"
      :data="tableData"
      :props="defaultProps"
      :default-expanded-keys="expendKeys"
      :show-checkbox="showCheckbox"
      :check-strictly="!checkStrictly"
      :node-key="nodeId"
      :default-checked-keys="showCheckbox ? defaultCheckedKeys : []"
      :default-expand-all="defaultExpandAll"
      :highlight-current="highlight"
      :expand-on-click-node="expandOnClickNode"
      :auto-expand-parent="autoExpandParent"
      :filter-node-method="filterNodeTree"
      @node-click="handleNodeClick"
      @check="handleCheckChange"
    ></el-tree>
  </div>
</template>

<script>
//import { viewReflect } from '@/api/core/view'
import arrayToTree from "array-to-tree";
const utils = require("@/api/core/utils");
const dao = require("../api/core/dao");
const common = require("../utils/common");
export default {
  name: "yes-tree",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    viewDataSource: String,
    nodeId: {
      type: String,
      default: "id",
    },
    nodeName: {
      type: String,
      default: "name",
    },
    parentId: {
      type: String,
      default: "parent_id",
    },
    treeClickEvent: {
      type: String,
      default: "",
    },
    treeCheckEvent: {
      type: String,
      default: "",
    },
    defaultExpandAll: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    nodeKey: {
      type: String,
      default: "id",
    },
    expandOnClickNode: {
      type: Boolean,
      default: true,
    },
    autoExpandParent: {
      type: Boolean,
      default: true,
    },
    isBaseSelect: {
      type: Boolean,
      default: false, 
    }
  },
  data () {
    return {
      tableData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      expendKeys: [],
      fvalue: [],
      isDrag: false,
      orignTableData: [],
      defaultCheckedKeys: [],
      loadDataSourceFlag: true,
      fDataSource: this.dataSource,
      fViewDataSource: this.viewDataSource,
    };
  },
  methods: {
    // 将组件的值返回
    getValue () {
      return this.fvalue;
    },
    // 修改组件的值
    setValue (val) {
      this.setSelectValue(val);
      if (this.fDataSource) {
        this.updateSourceData(this.convertValue(val));
      }
    },
    // 调用tree过滤方法 中文英过滤
    filterNodeTree (value, data, node) {
      if (!value) {
        //如果数据为空，则返回true,显示所有的数据项
        return true;
      }
      return this.chooseNode(value, data, node); // 调用过滤二层方法
    },
    // 过滤父节点 / 子节点 (如果输入的参数是父节点且能匹配，则返回该节点以及其下的所有子节点；如果参数是子节点，则返回该节点的父节点。name是中文字符，enName是英文字符.
    chooseNode (value, data, node) {
      if (data[this.nodeName].indexOf(value) !== -1) {
        return true;
      }
      const level = node.level;
      // 如果传入的节点本身就是一级节点就不用校验了
      if (level === 1) {
        return false;
      }
      // 先取当前节点的父节点
      let parentData = node.parent;
      // 遍历当前节点的父节点
      let index = 0;
      while (index < level - 1) {
        // 如果匹配到直接返回
        if (parentData.data[this.nodeName].indexOf(value) !== -1) {
          return true;
        }
        // 否则的话再往上一层做匹配
        parentData = parentData.parent;
        index++;
      }
      // 没匹配到返回false
      return false;
    },
    convertValue (val) {
      const type = common.getType(val);
      let checkedItems = [];
      if (type.indexOf("array") > -1 && val.length >= 0) {
        val.forEach((index) => {
          const indexType = common.getType(index);
          if (indexType.indexOf("object") > -1 && this.nodeId) {
            //对象
            const obj = this.orignTableData.find(
              (item) => item[this.nodeId] === index[this.nodeId]
            );
            checkedItems.push(obj);
          } else {
            const obj = this.orignTableData.find(
              (item) => item[this.nodeId] === index
            );
            checkedItems.push(obj);
          }
        });
      }
      return checkedItems;
    },
    //默认展开的节点
    setExpendKeys (val) {
      this.expendKeys = val;
    },
    setCurrentKey (val) {
      //设置默认高亮
      if (this.highlight) {
        this.$refs.tree.setCurrentKey(val);
      }
    },
    //过滤节点的方法
    filter (val) {
      this.$refs.tree.filter(val);
    },
    //初始化树的方法
    load () {
      const val1 = utils.getSourceData(this.fViewDataSource);
      if (val1 && common.getType(val1).indexOf("array") > -1) {
        this.tableData = arrayToTree(val1, {
          parentProperty: this.parentId,
          customID: this.nodeId,
        });
       // console.log("list...........", this.tableData)
        if(this.isBaseSelect) this.tableData = this.setDisableMNode(this.tableData)
        this.fvalue = [];
        this.orignTableData = val1;
      }
    },
    setDisableMNode(list) {
        list.map( v=>{
            if(v.children && v.children.length > 0) {
                v.disabled = true
                this.setDisableMNode(v.children)
            }
        })
        return list
    },
    // 读取绑定数据源数据
    loadSourceData () {
      let val2 = utils.getSourceData(this.fDataSource);
      if (typeof val2 !== "object") {
        if (this.loadDataSourceFlag) {
          this.setSelectValue(val2);
        } else {
          this.loadDataSourceFlag = true;
        }
      }
    },
    //加载内容数据源
    loadViewSourceData () {
      this.load();
    },
    // 修改绑定数据源数据
    updateSourceData (val) {
      utils.updateSourceData(this.fDataSource, val ? val : this.fvalue);
    },
    //取消勾选
    cancelSelectKeys () {
      this.$refs.tree.setCheckedNodes([]);
    },
    setSelectValue (defaultCheckedValue) {

      if (
        !this.showCheckbox &&
        defaultCheckedValue.length >= 0 &&
        this.tableData.length > 0
      ) {
        if (defaultCheckedValue.length === 0) {
          this.fvalue = [];
          this.setCurrentKey(null);
          return;
        }

        const val = defaultCheckedValue[0];
        if (common.getType(val).indexOf("object") > -1) {
          this.fvalue = this.orignTableData.filter(
            (item) => item[this.nodeId] === val[this.nodeId]
          );
          if (this.highlight) {
            this.setExpendKeys([val[this.nodeId]]);
          }
          this.setCurrentKey(val[this.nodeId]);

          return;
        }
        this.fvalue = this.orignTableData.filter(
          (item) => item[this.nodeId] === val
        );
        this.setCurrentKey(val);
        // if (this.highlight) {
        //   this.setExpendKeys([val]);
        // }
        this.loadDataSourceFlag = false;
        this.updateSourceData(this.fvalue);

        return;
      }

      const type = common.getType(defaultCheckedValue);
      if (type.indexOf("array") > -1 && defaultCheckedValue.length >= 0) {
        let checkedItems = [];
        defaultCheckedValue.forEach((index) => {
          const indexType = common.getType(index);
          if (indexType.indexOf("object") > -1 && this.nodeId) {
            //对象
            const key = index[this.nodeId];
            checkedItems.push(key);
          } else {
            checkedItems.push(index);
          }
        });

        this.defaultCheckedKeys = checkedItems;
        // this.setExpendKeys(checkedItems);
        this.fvalue = this.convertValue(checkedItems);
        this.loadDataSourceFlag = false;
        this.updateSourceData(this.fvalue);
      }

      // if (type.indexOf('object') > -1 && defaultCheckedValue.checkedKeys) {
      //   this.fvalue = defaultCheckedValue.checkedKeys;
      //   this.setExpendKeys(defaultCheckedValue.checkedKeys);
      // }
    },
    //点击事件
    async handleNodeClick (checkedData) {
      if (!this.showCheckbox) {
        this.setValue([checkedData]);
      }
      if (this.treeClickEvent) {
        const func = window.AsyncFunction(
          "vm",
          "checkedData",
          "dao",
          "api",
          "utils",
          this.treeClickEvent
        );
        await func(this, checkedData, dao, this.formCreateInject.api, utils); //
      }
    },
    //选中事件
    async handleCheckChange (checkedTreeData, checkedTree) {
      if (this.treeCheckEvent) {
        const func = window.AsyncFunction(
          "vm",
          "checkedTreeData",
          "checkedTree",
          "dao",
          "api",
          "utils",
          this.treeCheckEvent
        );
        await func(
          this,
          checkedTreeData,
          checkedTree,
          dao,
          this.formCreateInject.api,
          utils
        ); // 3
      }

      if (this.showCheckbox) {
        this.setValue(checkedTree.checkedNodes);
      }
    },
    getTree() {
      return this.$refs.tree
    },
    expandedAll(isExpand) {
       // isExpand : true 展开全部; false 收缩全部
       let treeList = this.tableData;
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.tree.store.nodesMap[treeList[i][this.nodeId]].expanded = isExpand;
        }
    }
  },
  created () {
    this.defaultProps.label = this.nodeName;
    if (this.$parent.$options._componentTag === "dragTool") {
      this.isDrag = true;
    }
  },
};
</script>

<style>
</style>