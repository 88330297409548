<template>
    <div :id="fid" :style="{ height: height, width: width }"></div>
</template>


<script>
const utils = require("@/api/core/utils");
// const dao = require('@/api/core/dao');
import * as echarts from "echarts";

export default {
    name: "yes-pieChart",
    props: {
        formCreateInject: {
            type: Object,
            required: true,
        },
        dataSource: String,
        //x轴字段(1月，2月，3月....)
        valueField: {
            type: String,
            default: "",
        },
        nameField: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "lineChart",
        },
        marginTop: {
            type: String,
            default: "10%",
        },
        marginLeft: {
            type: String,
            default: "3%",
        },
        marginRight: {
            type: String,
            default: "3%",
        },
        marginBottom: {
            type: String,
            default: "3%",
        },
        showTooltip: {
            type: Boolean,
            default: true,
        },
        canSaveImage: {
            type: Boolean,
            default: true,
        },
        height: {
            type: String,
            default: "100%",
        },
        width: {
            type: String,
            default: "100%",
        },

        //圆周大小
        radius: {
            type: String,
            default: "50%",
        },
    },
    data () {
        return {
            fid: '',
            viewData: [],
            pieData: [],
            fDataSource: this.dataSource
        };
    },
    watch: {
        viewData () {
            this.parse();
        },
    },
    created () {
        this.fid = 'pie_' + Date.now() + Math.random();
        //   this.initEcharts();
    },
    methods: {
        // 方法
        initEcharts () {
            // 基于准备好的dom，初始化echarts实例

            // 新建一个promise对象
            let newPromise = new Promise((resolve) => {
                resolve();
            });
            //然后异步执行echarts的初始化函数
            newPromise.then(() => {
                //	此dom为echarts图标展示dom
                var myChart = echarts.init(document.getElementById(this.fid));
                // 绘制图表
                myChart.setOption({
                    title: {
                        text: this.title,
                        left: "center",
                    },
                    tooltip: {
                        show: this.showTooltip,
                        trigger: "item",
                        formatter: "{b} : {c} ({d}%)",
                    },
                    toolbox: {
                        show: this.canSaveImage,
                        feature: {
                            //保存图片
                            saveAsImage: {},
                        },
                    },
                    grid: {
                        top: this.marginTop,
                        left: this.marginLeft,
                        right: this.marginRight,
                        bottom: this.marginBottom,
                        //containLabel 为 true 的时候：
                        // grid.left grid.right grid.top grid.bottom grid.width grid.height 决定的是包括了坐标轴标签在内的所有内容所形成的矩形的位置。
                        // 这常用于『防止标签溢出』的场景，标签溢出指的是，标签长度动态变化时，可能会溢出容器或者覆盖其他组件。
                        containLabel: true,
                    },
                    legend: {
                        orient: "vertical",
                        left: "left",
                    },
                    series: [
                        {
                            // name: "Access From",
                            type: "pie",
                            //圆周大小
                            radius: this.radius,
                            data: this.pieData,
                            //阴影
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                });
            });
        },
        // 将组件的值返回
        getValue () {
            //   return this.href;
        },
        // 修改组件的值
        setValue () {
            //   this.href = val;
        },
        // 读取绑定数据源数据
        loadSourceData () {
            let da = utils.getSourceData(this.fDataSource);
            this.viewData = da !== undefined ? da : this.data;
        },
        parse () {
            this.viewData.forEach((item) => {
                this.pieData.push({
                    value: item[this.valueField],
                    name: item[this.nameField],
                });
            });
            this.initEcharts();
        },
        // 修改绑定数据源数据
        updateSourceData () { },
    },
};
</script>
<style scoped></style>
