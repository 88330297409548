<template>
    <div>
        <div ref="gaugeChart" :style="{ height: height, width: width }"></div>
    </div>
</template>
  
<script>
import * as echarts from "echarts";
export default {
    name: 'yesGaugeChart',
    props: {
        formCreateInject: {
            type: Object,
            required: true,
        },
        width: {
            type: String,
            default: "100%",
        },
        height: {
            type: String,
            default: "100%",
        },
        options: {
            type: Object
        }
    },
    data() {
        return {
            fOptions: this.options,
            fValue: '',
            charts: ''
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initChart();
        });
    },
    methods: {
        initChart() {
            this.charts = echarts.init(this.$refs.gaugeChart);
            if (!this.options && !this.fOptions) {
                this.fOptions = this.getDefaultOption();
            }
            this.charts.setOption(this.fOptions);
        },
        /**
         * 赋值
         * @param {*} value 数字
         */
        setValue(value) {
            if (!value) return;
            if (!this.charts) return;
            // if (Array.isArray(value)) {
            //     this.fOptions.series = [];
            // } else {
            //     this.fOptions.series[0].data = [];
            // }
            this.fOptions.series[0].data = [];
            this.fValue = value;
            this.fOptions.series[0].data = [{ "value": value }];
            this.charts.setOption(this.fOptions);
        },
        getValue() {
            return this.fValue;
        },
        setOptions(options) {
            if (!this.charts) return;
            if (!this.options) return;
            this.fOptions = options;
            this.charts.setOption(this.fOptions);
        },
        getOptions() {
            return this.fOptions;
        },
        getDefaultOption() {
            return {
                series: [
                    {
                        type: 'gauge',
                        axisLine: {
                            lineStyle: {
                                width: 30,
                                color: [
                                    [0.3, '#67e0e3'],
                                    [0.7, '#37a2da'],
                                    [1, '#fd666d']
                                ]
                            }
                        },
                        pointer: {
                            itemStyle: {
                                color: 'auto'
                            }
                        },
                        axisTick: {
                            distance: -30,
                            length: 8,
                            lineStyle: {
                                color: '#fff',
                                width: 2
                            }
                        },
                        splitLine: {
                            distance: -30,
                            length: 30,
                            lineStyle: {
                                color: '#fff',
                                width: 4
                            }
                        },
                        axisLabel: {
                            color: 'inherit',
                            distance: 40,
                            fontSize: 20
                        },
                        detail: {
                            valueAnimation: true,
                            formatter: '{value} km/h',
                            color: 'inherit'
                        },
                        data: [
                            {
                                value: 70
                            }
                        ]
                    }
                ]
            };
        }
    }
}
</script>

<!-- 
    仪表盘示例: https://echarts.apache.org/examples/zh/editor.html?c=gauge-stage&random=cb6ieb9t99
    代码入下:
import * as echarts from 'echarts/core';
import { GaugeChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([GaugeChart, CanvasRenderer]);

var chartDom = document.getElementById('main');
var myChart = echarts.init(chartDom);
var option;

option = {
  series: [
    {
      type: 'gauge',
      axisLine: {
        lineStyle: {
          // 仪表盘宽度  
          width: 30,
          // 仪表盘分段颜色
          color: [
            [0.3, '#67e0e3'],
            [0.7, '#37a2da'],
            [1, '#fd666d']
          ]
        }
      },
      pointer: {
        itemStyle: {
          // 指针颜色, auto 随仪表盘分段颜色而变化
          color: 'auto'
        }
      },
      axisTick: {
        // 仪表盘小分段的距离
        distance: -30,
        // 仪表盘小分段的长度
        length: 8,
        lineStyle: {
          // 仪表盘小分段的颜色
          color: '#fff',
          // 仪表盘小分段的宽度
          width: 2
        }
      },
      splitLine: {
        // 仪表盘大分段的距离
        distance: -30,
        // 仪表盘大分段的长度
        length: 30,
        lineStyle: {
          // 仪表盘大分段的颜色
          color: '#fff',
          // 仪表盘大分段的宽度
          width: 4
        }
      },
      // 仪表盘大分段的字
      axisLabel: {
        color: 'inherit',
        // 仪表盘大分段字与仪表盘的距离
        distance: 40,
        // 仪表盘大分段字大小
        fontSize: 20
      },
      // 仪表盘中间的字  当前的值
      detail: {
        valueAnimation: true,
        formatter: '{value} km/h',
        color: 'inherit'
      },
      // 仪表盘当前的值
      data: [
        {
          value: 70
        }
      ]
    }
  ]
};
myChart.setOption(option);
-->