<template>
  <el-rate
    v-model="fvalue"
    :max="max"
    :disabled="disabledValue"
    :allow-half="allowHalf"
    :show-text="showText"
    :texts="texts"
    :show-score="showScore"
    :text-color="textColor"
    @change="change"
  ></el-rate>
</template>

<script>
const utils = require("@/api/core/utils");
export default {
  name: "yes-rate",
  props: {
    titleCss: String,
    contentCss: String,
    definedCss: Object,
    dataSource: String,
    formCreateInject: {
      type: Object,
      required: true,
    },
    max: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    allowHalf: {
      type: Boolean,
    },
    showText: {
      type: Boolean,
    },
    texts: {
      type: Array,
    },
    textColor: {
      type: String,
    },
    showScore: {
      type: Boolean,
    },
  },
  data () {
    return {
      fvalue: 0,
      fDataSource: this.dataSource,
      disabledValue: false
    };
  },
  mounted () {
    this.disabledValue = this.disabled
    this.loadSourceData();
  },
  watch: {
     disabled(val){
      this.disabledValue = val
    },
    fvalue (val) {
      console.log(val);
      this.$emit("input", val);
      utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
    },
  },
  methods: {
    setDisabled(val) {
      this.disabledValue = val
    },
    // 将组件的值返回
    getValue () {
      return this.fvalue;
    },
    // 修改组件的值
    setValue (val) {
      this.fvalue = val;
    },
    // 读取绑定数据源数据
    loadSourceData () {
      let val = utils.getSourceData(this.fDataSource);
      this.fvalue = val !== undefined ? val : this.fvalue;
    },
    // 修改绑定数据源数据
    updateSourceData (val) {
      utils.updateSourceData(this.fDataSource, val);
    },
    change (val) {
      if (this.fDataSource) {
        this.updateSourceData(val);
      }
    },
  },
};
</script>