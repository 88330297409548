<template>
  <i v-if="show" :class="fvalue" :title="titleh" :style="{ 'color': fColor, 'font-size': ffSize }" @click="handleClick">
    <slot />
  </i>
</template>

<script>
const dao = require('@/api/core/dao');
const utils = require('@/api/core/utils');
export default {
  name: "yesAlert",
  props: {
    formCreateInject: {
      type: Object
    },
    dataSource: String,
    picture: String,
    clickEvent: String,
    titleh: String,
    colorDefault: String,
    fSize: String
  },
  data() {
    return {
      fvalue: "",
      ffSize: this.fSize,
      fColor: this.colorDefault,
      fDataSource: this.dataSource,
      show: true
    };
  },
  watch: {
    picture(val) {
      this.fvalue = val;
    }
  },
  created() {
    this.fvalue = this.picture;
  },
  methods: {
    getColor() {
      return this.fColor;
    },
    setColor(val) {
      this.fColor = val;
    },
    getFontSize() {
      return this.ffSize;
    },
    setFontSize(val) {
      this.ffSize = val;
    },
    getValue() {
      return this.fvalue;
    },
    setValue(val) {
      this.fvalue = val;
    },
    loadSourceData() {
      this.fvalue = utils.getSourceData(
        this.fDataSource
      );
    },
    updateSourceData() {
      utils.updateSourceData(this.fvalue);
    },
    setFvalue(icon) {
      this.fvalue = icon
    },
    setShow(flag) {
      this.show = flag
    },
    isDarg() {
      if (this.$parent.$options._componentTag == "dragTool") {
        return true;
      }
    },
    async handleClick() {
      if (!this.isDarg()) {
        const clickEvent = window.AsyncFunction("vm", "api", 'dao', 'utils', this.clickEvent);
        await clickEvent(this, this.formCreateInject.api, dao, utils)
      }
    }
  },
}
</script>
