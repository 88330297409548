<template>
  <!-- <img
    :src="src"
    :width="width"
    :height="height"
    class="imgDesign"
    :style="{ 'object-fit': type }"
  /> -->
  <el-image
    v-if="src"
    :src="src"
    :fit="type"
    :preview-src-list="srcList"
    :style="`width:${width};height:${height};`"
    @click="handleClick"
  >
  </el-image>
</template>

<script>
const dao = require('@/api/core/dao');
const utils = require('@/api/core/utils');
export default {
  name: "yes-image",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: {
      type: String
    },
    url: {
      type: String,
    },
    type: {
      type: String,
    },
    showSrc: {
      type: Boolean,
    },
    clickEvent: String,
    width: {
      type: [String, Number]
    },
    height: {
      type: [String, Number]
    }
  },
  data() {
    return {
      src: '',
      srcList: [],
      fDataSource: this.dataSource
    }
  },
  watch: {
    url(val) {
      this.src = val;
    },
    showSrc(val) {
      if (val) {
        this.srcList = [this.src];
      }
    }
  },
  mounted() {
    if (this.url) {
      this.src = this.url;
      this.srcList = [this.src];
    } else {
      this.src = '/static/dist/img/empty.png';
    }
  },
  methods: {
    // 将组件的值返回
    getValue() {
      return this.src;
    },
    // 修改组件的值
    setValue(val) {
      this.src = val;
    },
    // 读取绑定数据源数据
    loadSourceData() {
      let img = undefined;
      let res = utils.getSourceData(this.fDataSource);
      if (res) {
        img = typeof (res) === 'string' ? res : res[0];
      }
      this.src = img ? img : this.url ? this.url : '/static/dist/img/empty.png';
      if (this.showSrc) {
        this.srcList = [this.src];
      } else {
        this.srcList = [];
      }
    },
    // 修改绑定数据源数据
    updateSourceData() {
      utils.updateSourceData(this.fDataSource, this.url);
    },
    isDarg() {
      if (this.$parent.$options._componentTag == 'dragTool') {
        return true;
      }
    },
    async handleClick() {
      if (!this.isDarg()) {
        const clickEvent = window.AsyncFunction("vm", "api", 'dao', 'utils', this.clickEvent);
        await clickEvent(this, this.formCreateInject.api, dao, utils);
      }
    }
  },
};
</script>

<style ></style>