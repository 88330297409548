<template>
    <el-dropdown
        :size="size"
        :type="typeButton"
        :trigger="triggerType"
        :placement="placement"
        :split-button="splitButton"
        @command="handleCommand"
    >
        <span class="el-dropdown-link">
            {{ drpValue2 }} <i
                v-if="!splitButton"
                class="el-icon-arrow-down el-icon--right"
            ></i>
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="(ce, index) in fKuList"
                :key="index"
                :command="ce"
                :style="customStyle"
            >
                {{ ce.label }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yes-dropdown",
    props: {
        formCreateInject: {
            type: Object,
            required: true,
        },
        dataSource: String,
        optionSource: {
            type: [Array, String],
            default: () => [],
        },
        drpValue1: {
            type: String,
            default: "下拉菜单",
        },
        kuList: {
            type: Array,
            default: () => [],
        },
        size: {
            type: String,
        },
        splitButton: {
            type: Boolean,
        },
        typeButton: {
            type: String,
        },
        customStyle: {
            type: String,
            default: "",
        },
        triggerType: {
            type: String,
            default: "click",
        },
        placement: {
            type: String,
        },
        clickEvent: String,
    },
    data() {
        return {
            isHidden: false,
            fvalue: null,
            isDrag: false,
            drpValue2: "",
            command: "",
            fDataSource: this.dataSource,
            fKuList: this.kuList,
        };
    },
    watch: {
        drpValue1: function (data) {
            this.drpValue2 = this.drpValue1;
            if (data == "") {
                this.drpValue2 = "下拉菜单";
            }
        },
    },
    created() {
        this.drpValue2 = this.drpValue1;
    },
    mounted() {},

    methods: {
        getValue() {
            return this.fvalue;
        },
        setValue(val) {
            this.fvalue = val;
            this.setTitle(this.fKuList.find((object) => object.value === this.fvalue).label);
        },
        loadSourceData() {
            const data = utils.getSourceData(this.fDataSource);
            if (data) {
                this.fKuList = data;
            }
            if (this.fvalue != null && this.fvalue != undefined) {
                this.setTitle(this.fKuList.find((object) => object.value === this.fvalue).label);
            }
        },
        updateSourceData() {
            utils.updateSourceData(this.fDataSource, this.fvalue);
        },
        async handleCommand(command) {
            this.setTitle(command.label);
            this.setValue(command.value);

            this.command = command;
            const clickEvent = window.AsyncFunction("vm", "api", "dao", "utils", this.clickEvent);
            await clickEvent(this, this.formCreateInject.api, dao, utils);
        },
        setTitle(val) {
            if (typeof val != String) {
                let v = val.toString();
                this.drpValue2 = v;
                return;
            }
            this.drpValue2 = val;
        },
    },
};
</script>
<style lang="scss" scoped>
.yes-dropdown-link {
    cursor: pointer;
    color: #409eff;
}

.yes-icon-arrow-down {
    font-size: 16px;
}
</style>
