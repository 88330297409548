<template>
    <div style="width:100%;min-height:20px; ">
        <div style="display:flex;align-items: center;">
          <yes-icon style="margin-left:20px" :picture="yesIcon"></yes-icon>
          <span style="margin-left:10px;color: gray;font-size: 14px;">{{yesType.replaceAll('yesm-','')}}({{yesName}})</span>
        </div>
      <slot></slot>
    </div>
  </template>
  
  <script>
import YesIcon from './yesIcon.vue';
  export default {
    components: {
        YesIcon
    },
    name: "yesMobileComponent",
    props: {
        yesType: {
          type:String
        },
        yesIcon:{type:String},
        yesName:{type:String},
        yesDatesource:{type: [Number, String, Object, Array]}
    },
    data () {
      return {
      
      };
    },
    created () {
    },
    methods: {
    },
  }
  </script>
  