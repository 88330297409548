<template>
    <div>
        <el-upload
            ref="upload"
            class="upload-demo"
            action="/upload"
            :limit="limit"
            :accept="accept"
            :data="paramData"
            :name="modelField"
            :headers="headers"
            :multiple="multiple"
            :list-type="listType"
            :file-list="fileList"
            :auto-upload="autoUpload"
            :disabled="disabledValue"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :on-preview="handlePreview"
            :on-success="uploadSuccess"
            :before-remove="beforeRemove"
            :before-upload="beforeUpload"
            :show-file-list="showFileList"
        >
            <!--  -->
            <!-- <span v-if="listType=='picture-card'">
      <i class="el-icon-plus"></i>
      <el-button
        v-if="!autoUpload"
        style="margin-left: 10px;"
        size="small"
        type="success"
        :disabled="disabledValue"
        @click="submitUpload"
      >上传到服务器</el-button>
    </span>
    <span v-else> -->
            <el-button size="small" type="primary" slot="trigger" v-if="autoUpload" :disabled="disabledValue">
                {{ customerAname }}
            </el-button>
            <el-button size="small" type="primary" slot="trigger" :disabled="disabledValue" v-if="!autoUpload"> {{ selectFileTitle }} </el-button>
            <el-button v-if="!autoUpload" style="margin-left: 10px" size="small" type="success" :disabled="disabledValue" @click="submitUpload">
                {{ uploadFileTitle }}
            </el-button>
            <!-- </span> -->
        </el-upload>
        <el-dialog :title="dialogTitle" width="70%" top="8vh" :visible.sync="imgDialog" ref="dialog" :modal-append-to-body="false">
            <el-carousel ref="uploadShow" height="70vh" :autoplay="false">
                <!-- <el-image  :src="imaUrl" /> -->
                <el-carousel-item v-for="item in fileList" :key="item.uid" :name="item.uid">
                    <el-image :src="item.url" style="width: 100%; height: 100%" fit="contain" />
                </el-carousel-item>
            </el-carousel>
        </el-dialog>
    </div>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yes-upload",
    props: {
        dataSource: String,
        formCreateInject: {
            type: Object,
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        showFileList: {
            type: Boolean,
            default: true
        },
        imageQuality: {
            type: Number
        },
        compressionThreshold: {
            type: [Number, String],
            default: 0.7
        },
        listType: {
            type: String,
            default: "text"
        },
        accept: {
            type: String
        },
        autoUpload: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number
        },
        app: {
            type: String,
            default: ""
        },
        model: {
            type: String,
            default: ""
        },
        modelField: {
            type: String,
            default: ""
        },
        modelUid: {
            type: String,
            default: ""
        },
        customerAname: {
            type: String,
            default: "上传文件"
        },
        uploadSuccessEvent: {
            type: String
        },
        isEn: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fileList: [],
            paramData: {},
            uid: "",
            loadFlag: 0,
            headers: {},
            fDataSource: this.dataSource,
            imgDialog: false,
            imaUrl: "",
            disabledValue: false,
            modelUplUid: "",
            dialogTitle: "图片详情",
            selectFileTitle: "选取文件",
            uploadFileTitle: "开始上传"
        };
    },
    created() {
        // this.fvalue = this.value? this.value : this.fvalue
    },
    mounted() {
        if (this.isEn) {
            this.uploadFileTitle = "Upload";
            this.dialogTitle = "Image details";
            this.selectFileTitle = "Select file";
        }
        this.disabledValue = this.disabled;
        this.headers = utils.getHeader();
        this.modelUplUid = this.modelUid;
        this.loadSourceData();
    },
    watch: {
        disabled(val) {
            this.disabledValue = val;
        },
        fileList(val) {
            let json = [];
            if (Array.isArray(val)) {
                let urlList = [];
                this.fileList.forEach((item) => {
                    urlList.push(item.url);
                    json = JSON.stringify(urlList);
                });
            }
            this.$emit("input", json);
            utils.noticeParentDataObject(this.formCreateInject, json);
        },
        uid(val) {
            if (val != "") {
                this.loadViewSourceData();
            }
        }
    },
    methods: {
        getValue() {
            return this.fileList;
        },
        setDisabled(val) {
            this.disabledValue = val;
        },
        // 修改组件的值
        setValue(val) {
            this.fileList = val;
            this.changeSourceData();
        },
        async loadSourceData() {
            const res = await utils.getSourceData(this.modelUplUid);
            this.uid = res != undefined && res != null ? res : this.uid;
        },
        async loadViewSourceData(isChange = true) {
            let data = {};
            // data.uid = this.uid;
            data.app = this.app;
            data.model = this.model;
            data.model_uid = this.uid;
            data.field = this.modelField;
            let res = await dao.collect("dme", "attachment", data);
            if (res.success) {
                this.fileList = [];
                let resData = res.data;
                resData.forEach((r) => {
                    this.fileList.push({
                        name: r.name,
                        url: r.url,
                        uid: r.uid
                    });
                });
            } else {
                utils.messageError(res.message);
            }
            if (isChange) this.changeSourceData();
        },
        //修改绑定数据源数据
        updateSourceData(val) {
            let jsonVal = JSON.stringify(val);
            utils.updateSourceData(this.fDataSource, jsonVal);
        },
        async handleRemove(file) {
            let uid = file.uid;
            let res = await dao.modelDeleteByUid("dme", "attachment", uid);
            if (res.success) {
                if (this.isEn) {
                    utils.messageSuccess("delete success");
                } else {
                    utils.messageSuccess("删除成功");
                }
            }
            let delUrl = file.url;
            this.fileList = this.fileList.filter((file) => file.url !== delUrl);
            this.changeSourceData();
        },
        handlePreview(file) {
            let vm = this;
            if (this.listType === "picture" || this.listType === "picture-card") {
                this.imgDialog = true;
                setTimeout(function () {
                    vm.$refs.uploadShow.setActiveItem(file.uid);
                }, 100);
            } else {
                utils.openNewWindow(file.url);
            }
        },
        handleExceed(files, fileList) {
            if (this.isEn) {
                this.$message.warning(`The number of files exceeds the limit, and a maximum of ${this.limit} can be uploaded`);
            } else {
                this.$message.warning(`文件数量超出限制, 最多只能上传${this.limit}个`);
            }
        },
        beforeRemove(file) {
            if (this.isEn) {
                return this.$confirm(`Are you sure to remove ${file.name} ?`);
            }
            return this.$confirm(`确定移除 ${file.name} ?`);
        },
        beforeUpload(file) {
            const name = file.name;
            if (name && name.length >= 128) {
                if (this.isEn) {
                    this.$message.warning("The file name is too long and cannot exceed 128 characters");
                } else {
                    this.$message.warning("文件名过长, 不能超过128个字符");
                }
                return false;
            }
            if (!this.app) {
                this.$message.warning("app为空");
                return false;
            }
            if (!this.model) {
                this.$message.warning("model为空");
                return false;
            }
            if (!this.uid) {
                this.$message.warning("model_uid为空");
                return false;
            }
            this.paramData.app = this.app;
            this.paramData.model = this.model;
            this.paramData.model_uid = this.uid;
            let ctNumber = this.compressionThreshold;
            if (!ctNumber || ctNumber == null || isNaN(ctNumber)) {
                return true;
            }
            ctNumber = Number(this.compressionThreshold);
            if (ctNumber <= 0) {
                return true;
            }
            // 1MB = 1024 * 1024 = 1,048,576
            const size = 1048576;
            const compressThreshold = ctNumber * size;
            if (file.size > compressThreshold && file.type.startsWith("image/")) {
                let tempImageQuality = Number(this.imageQuality);
                if (!tempImageQuality || tempImageQuality >= 1 || tempImageQuality <= 0) {
                    tempImageQuality = 0.7;
                }
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (event) => {
                        const imgElement = document.createElement("img");
                        imgElement.src = event.target.result;
                        imgElement.onload = () => {
                            const canvas = document.createElement("canvas");
                            canvas.width = imgElement.width;
                            canvas.height = imgElement.height;
                            const ctx = canvas.getContext("2d");
                            ctx.drawImage(imgElement, 0, 0, canvas.width, canvas.height);
                            ctx.canvas.toBlob(
                                (blob) => {
                                    const file = new File([blob], `compressed_${Date.now()}.jpg`, { type: "image/jpeg", lastModified: Date.now() });
                                    resolve(file);
                                },
                                "image/jpeg",
                                tempImageQuality
                            );
                        };
                    };
                    reader.onerror = (error) => reject(error);
                });
            }
            return true;
        },
        submitUpload() {
            this.$refs.upload.submit();
        },
        async uploadSuccess(response, file, file_list) {
            if (!response.success) {
                if (this.isEn) {
                    utils.messageError("Upload failed, please check file size or path!");
                } else {
                    utils.messageError("上传失败, 请检查文件大小或路径!");
                }
            }
            if (!this.multiple) {
                let path = response.data[this.modelField][0];
                this.fileList.push({
                    name: path.name,
                    url: path.url,
                    uid: path.uid
                });
                setTimeout(() => {
                    this.changeSourceData();
                }, 300);
                if (this.isEn) {
                    utils.messageSuccess("Upload successful");
                } else {
                    utils.messageSuccess("上传成功");
                }
            } else {
                const temp_file_list = file_list.filter((item) => item.status === "success");
                if (temp_file_list.length === file_list.length) {
                    setTimeout(async () => {
                        await this.loadViewSourceData();
                    }, 200);
                    if (this.isEn) {
                        utils.messageSuccess("Upload successful");
                    } else {
                        utils.messageSuccess("上传成功");
                    }
                }
            }
            if (this.uploadSuccessEvent) {
                // 监听键盘回车事件
                const success = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", "response", this.uploadSuccessEvent);
                await success(this, this.formCreateInject.api, dao, this.formCreateInject, utils, response);
            }
        },
        changeSourceData() {
            let urlList = [];
            this.fileList.forEach((item) => {
                urlList.push(item.url);
            });
            this.updateSourceData(urlList);
        },
        setRule(app, model, uid, field) {
            (this.app = app), (this.model = model);
            (this.uid = uid), (this.field = field);
            this.loadSourceData();
        }
    }
};
</script>

<style></style>
