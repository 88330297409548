<template >
  <el-submenu
    :popper-append-to-body="false"
    :index="item[index]"
    v-if="item.children&&item.children.length>0"
  >
    <template slot="title">
      <i
        v-if="icon"
        :class="item[icon]"
      ></i>
      <span>{{item[title]}}</span>
    </template>
    <yes-sub-menu-item
      v-for="menu in item.children"
      :key="menu[index]"
      :item="menu"
      :icon="icon"
      :index="index"
      :title="title"
    >
    </yes-sub-menu-item>

  </el-submenu>

  <el-menu-item
    :index="item[index]"
    v-else
  ><i
      v-if="icon"
      :class="item[icon]"
    ></i><span>{{item[title]}}</span></el-menu-item>
</template>


<script>
import yesSubMenuItem from './yesSubMenuItem'

export default {
  name: "yesSubMenuItem",
  components: {
    yesSubMenuItem
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    index: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {
        return {

        }
      }
    }
  },
  data () {
    return {
    }

  }
}
</script>
<style scoped>
.el-submenu .el-menu-item {
  padding: 0;
  min-width: 0px;
}
</style>