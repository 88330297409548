<template>
  <el-progress ref="progressRef" :type="type" :color="color" :width="width" :status="status" :percentage="fvalue"
    :show-text="showText" :text-color="textColor" :text-inside="textInside" :stroke-width="strokeWidth"
    :stroke-linecap="strokeLinecap" :define-back-color="defineBackColor" :format="handleFormat">
    <slot></slot>
  </el-progress>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
  name: 'yes-progress',
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    percentage: Number,
    type: String,
    status: String,
    strokeWidth: [String, Number],
    textInside: Boolean,
    showText: Boolean,
    strokeLinecap: String,
    width: String,
    color: [String, Array],
    fontStyle: String,
    defineBackColor: String,
    textColor: String,
    formatEvent: String
  },
  data() {
    return {
      fvalue: 0,
      fDataSource: this.dataSource
    };
  },
  created() {
    this.fvalue = this.percentage;
  },
  mounted() {
    this.setFontStyle(this.fontStyle);
    if (this.color) {
      if (this.color.indexOf('[') > -1 && this.color.indexOf(']') > -1) {
        this.color = JSON.parse(this.color);
      }
    }
  },
  methods: {
    getValue() {
      return this.fvalue;
    },
    setValue(val) {
      this.fvalue = val;
    },
    setColor(val) {
      if (val) {
        if (val.indexOf('[') > -1 && val.indexOf(']') > -1) {
          this.color = JSON.parse(val);
        } else {
          this.color = val;
        }
      }
    },
    setFontStyle(val) {
      if (!val) {
        return;
      }
      this.fontStyle = val;
      if (this.textInside && this.type == 'line') {
        this.$el.children[0].children[0].children[0].children[0].style = val;
      } else {
        this.$el.children[1].style = val;
      }
    },
    loadSourceData() {
      this.fvalue = utils.getSourceData(
        this.fDataSource
      );
    },
    updateSourceData() {
      utils.updateSourceData(this.fDataSource, this.fvalue);
    },
    handleFormat(percentage) {
      if (!this.formatEvent) {
        return percentage;
      }
      const format = window.Function("vm", "api", "dao", "fApi", "utils", "percentage", this.formatEvent);
      return format(this, this.formCreateInject.api, dao, this.formCreateInject, utils, percentage);
    },
  },
}
</script>