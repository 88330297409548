<template>
  <el-badge
    :max="max"
    :type="type"
    :value="fvalue"
    :is-dot="fIsDot"
    :hidden="hidden"
  ></el-badge>
</template>

<script>
const utils = require("@/api/core/utils");
export default {
  name: 'yes-badge',
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    showValue: {
      type: [String, Boolean, Number]
    },
    max: Number,
    isDot: Boolean,
    hidden: Boolean,
    type: String,
  },
  watch: {
    showValue(val) {
      console.info("val", val);
      if (type === 'boolean') {
        this.fvalue = undefined;
        this.fIsDot = val;
      } else {
        if (this.isNumber(val)) {
          this.fvalue = parseFloat(val);
        } else {
          this.fvalue = val;
        }
      }
    }
  },
  data() {
    return {
      fvalue: '',
      fDataSource: this.dataSource,
      fIsDot: this.isDot,
      fHidden: this.hidden
    }
  },
  created() {
    let type = typeof (this.showValue);
    if (type === 'boolean') {
      this.setIsDot(this.fvalue);
      this.fvalue = undefined;
    } else {
      this.fvalue = this.showValue;
    }
  },
  methods: {
    isNumber(val) {
      var regPos = /^\d+(\.\d+)?$/; //非负浮点数
      var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
      if (regPos.test(val) || regNeg.test(val)) {
        return true;
      } else {
        return false;
      }
    },
    getValue() {
      return this.fvalue;
    },
    setValue(val) {
      let type = typeof (val);
      if (type === 'boolean') {
        this.setIsDot(val);
        this.fvalue = undefined;
      } else {
        this.fvalue = val;
      }
    },
    setIsDot(val) {
      this.fIsDot = val;
    },
    setHidden(val) {
      this.fHidden = val;
    },
    loadSourceData() {
      const value = utils.getSourceData(
        this.fDataSource
      );
      if (this.isNumber(value)) {
        this.fvalue = parseFloat(value);
      } else {
        this.fvalue = value;
      }
    },
    updateSourceData() {
      utils.updateSourceData(
        this.fDataSource,
        this.fvalue
      );
    },
  }
}
</script>

<style></style>