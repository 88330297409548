<template>
  <div
    id="scatterPlotMain"
    :style="{ height: height, width: width }"
  ></div>
</template>


<script>
const utils = require("@/api/core/utils");
// const dao = require('@/api/core/dao');
import * as echarts from "echarts";

export default {
  name: "yes-scatterPlot",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    //x轴字段(1月，2月，3月....)
    xField: {
      type: String,
      default: "",
    },
    yField: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "scatterPlot",
    },
    marginTop: {
      type: String,
      default: "10%",
    },
    marginLeft: {
      type: String,
      default: "3%",
    },
    marginRight: {
      type: String,
      default: "3%",
    },
    marginBottom: {
      type: String,
      default: "3%",
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    canSaveImage: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
    size: {
      type: Number,
      default: 20,
    },
  },
  data () {
    return {
      viewData: [],
      scatterList: [],
      fDataSource: this.dataSource
    };
  },
  // created() {
  //   this.initEcharts();
  // },
  watch: {
    viewData () {
      this.parse();
    },
  },
  methods: {
    // 方法
    initEcharts () {
      // 基于准备好的dom，初始化echarts实例

      // 新建一个promise对象
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      //然后异步执行echarts的初始化函数
      newPromise.then(() => {
        //	此dom为echarts图标展示dom
        var myChart = echarts.init(document.getElementById("scatterPlotMain"));
        // 绘制图表
        myChart.setOption({
          title: {
            text: this.title,
            left: "center",
          },
          grid: {
            top: this.marginTop,
            left: this.marginLeft,
            right: this.marginRight,
            bottom: this.marginBottom,
            //containLabel 为 true 的时候：
            // grid.left grid.right grid.top grid.bottom grid.width grid.height 决定的是包括了坐标轴标签在内的所有内容所形成的矩形的位置。
            // 这常用于『防止标签溢出』的场景，标签溢出指的是，标签长度动态变化时，可能会溢出容器或者覆盖其他组件。
            containLabel: true,
          },
          xAxis: {},
          yAxis: {},
          tooltip: {
            show: this.showTooltip,
            trigger: "axis",
          },
          toolbox: {
            show: this.canSaveImage,
            feature: {
              //保存图片
              saveAsImage: {},
            },
          },
          series: [
            {
              symbolSize: this.size,
              data: this.scatterList,
              type: "scatter",
            },
          ],
        });
      });
    },
    // 将组件的值返回
    getValue () {
      //   return this.href;
    },
    // 修改组件的值
    setValue () {
      //   this.href = val;
    },
    // 读取绑定数据源数据
    loadSourceData () {
      let da = utils.getSourceData(this.fDataSource);
      this.viewData = da !== undefined ? da : this.data;
    },
    parse () {
      this.viewData.forEach((item) => {
        this.scatterList.push([item[this.xField], item[this.yField]]);
      });
      this.initEcharts();
    },
    // 修改绑定数据源数据
    updateSourceData () { },
  },
};
</script>
<style scoped>
</style>