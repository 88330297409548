<template>
  <el-transfer
    v-model="fvalue"
    :props="{ key: akey, label: label }"
    :data="data"
    :left-default-checked="left_default_checked"
    :right-default-checked="right_default_checked"
    :titles="titles"
    :button-texts="buttonText"
    @change="change"
  >
  </el-transfer>
</template>

<script>
const utils = require("@/api/core/utils");
export default {
  name: "yes-transfer",
  props: {
    titleCss: String,
    contentCss: String,
    definedCss: Object,
    dataSource: String,
    viewDataSource: String,
    formCreateInject: {
      type: Object,
      required: true,
    },
    left_default_checked: {
      type: Array,
    },
    right_default_checked: {
      type: Array,
    },
    titles: {
      type: Array,
    },
    buttonText: {
      type: Array,
    },
    label: {
      type: String,
      default: "name",
    },
    akey: {
      type: String,
      default: "id",
    },
  },
  data () {
    return {
      fvalue: [],
      data: [],
      fDataSource: this.dataSource,
      fViewDataSource: this.viewDataSource,
    };
  },
  mounted () {
    this.loadSourceData();
  },
  watch: {
    fvalue (val) {
      this.$emit("input", val);
      utils.noticeParentDataObject(this.formCreateInject, this.fvalue);
    },
  },
  methods: {
    // 将组件的值返回
    getValue () {
      return this.fvalue;
    },
    // 修改组件的值
    setValue (val) {
      this.fvalue = val;
    },
    loadViewSourceData () {
      let val2 = utils.getSourceData(this.optionSource);
      this.data = val2 !== undefined ? val2 : this.data;
    },

    // 读取绑定数据源数据
    loadSourceData () {
      let val1 = utils.getSourceData(this.fDataSource);
      this.fvalue = val1 !== undefined ? val1 : this.fvalue;
    },
    // 修改绑定数据源数据
    updateSourceData (val) {
      utils.updateSourceData(this.fDataSource, val);
    },
    change (val) {
      if (this.fDataSource) {
        this.updateSourceData(val);
      }
    },
  },
};
</script>