<template>
    <div style="display: flex; align-items: center">

        <el-tooltip
            placement="top-start"
            :content="inputName"
            :disabled="isShowTooltip"
        >
            <el-input
                size="mini"
                suffix-icon="el-icon-more"
                v-model="inputName"
                :readonly="true"
                :disabled="disabledValue"
                @focus="handleButton"
                @mouseout.native="handleMouseout"
                @mouseover.native="handleMouseover"
            >
            </el-input>
        </el-tooltip>
        <el-dialog
            top="40px"
            width="95%"
            :modal="true"
            :title="title"
            :append-to-body="true"
            :visible.sync="dialogVisible"
            @open="onOpen"
            @closed="onClose"
        >
            <div style="width: 100%; height: 100%;display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

                <div style="width: 45%; height: 100%;">
                    <div v-if="search">
                        <el-input
                            clearable
                            size="mini"
                            type="input"
                            v-model="searchData[search]"
                            :placeholder="queryInputPlaceholder"
                            @clear="handleSearch"
                            @keyup.enter.native="handleSearch"
                        />
                    </div>
                    <div style="margin-top: 10px; margin-bottom: 10px; font-size: 18px; font-weight: bold;"> {{leftTitle}} </div>
                    <div style="margin-top: 5px">
                        <el-table
                            border
                            size="mini"
                            ref="yesTransferTableRef"
                            :data="tableData"
                            :empty-text="emptyText"
                            :height="maxHeight"
                            :max-height="maxHeight"
                            :highlight-current-row="true"
                            :style="{ width: tableWidth }"
                            :row-key="getRowKeys"
                            @selection-change="handleSelectionChanges"
                        >
                            <el-table-column
                                width="50"
                                type="selection"
                                :reserve-selection="true"
                            >
                            </el-table-column>
                            <el-table-column
                                sortable
                                size="mini"
                                v-for="(item, index) in columnField"
                                :key="index"
                                :prop="item.value"
                                :width="item.width"
                                :label="item.label"
                                :show-overflow-tooltip="true"
                            ></el-table-column>
                        </el-table>
                        <div style=" display: flex; align-items: center; justify-content: space-between; margin-top: 25px; ">
                            <div>
                                <el-pagination
                                    layout="total,prev,pager,next,jumper"
                                    :total="total"
                                    :current-page="pageNum"
                                    :hide-on-single-page="true"
                                    :page-size="currentPageSize"
                                    @current-change="handleCurrentChange"
                                />
                            </div>
                            <!-- <div>
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="clear"
                                >
                                    {{clearText}}
                                </el-button>
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="onSubmit"
                                >
                                    {{confirmText}}
                                </el-button>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div style="width: 60px; height: 100%;">
                    <div>
                        <el-button
                            type="primary"
                            @click="handleAdd"
                        >
                            {{addText}} <i class="el-icon-caret-right"></i>
                        </el-button>
                    </div>
                    <div style="margin-top: 15px;">
                        <el-button
                            type="danger"
                            @click="handleRemove"
                        >
                            <i class="el-icon-caret-left"></i> {{removeText}}
                        </el-button>
                    </div>
                </div>
                <div style="width: 45%; height: 100%;">
                    <div style="height: 28px;"></div>
                    <!-- <div
                        v-if="search"
                        style="opacity: 0;"
                    > -->
                    <!-- <el-input
                            :readonly="true"
                            style="cursor: text;"
                        /> -->
                    <!-- </div> -->
                    <div style="margin-top: 10px;margin-bottom: 10px; font-size: 18px; font-weight: bold;"> {{rightTitle}} </div>
                    <div style="margin-top: 5px">
                        <el-table
                            border
                            size="mini"
                            ref="yesTransferRightTableRef"
                            :height="maxHeight"
                            :max-height="maxHeight"
                            :empty-text="'暂无数据'"
                            :data="tableDataByRight"
                            :highlight-current-row="true"
                            :style="{ width: tableWidth }"
                            :row-key="getRowKeys"
                            @selection-change="handleSelectionChangesByRight"
                        >
                            <el-table-column
                                width="50"
                                type="selection"
                                :reserve-selection="true"
                            >
                            </el-table-column>
                            <el-table-column
                                sortable
                                size="mini"
                                v-for="(item, index) in columnField"
                                :key="index"
                                :prop="item.value"
                                :width="item.width"
                                :label="item.label"
                                :show-overflow-tooltip="true"
                            ></el-table-column>
                        </el-table>
                        <div style="display: flex; align-items: center; justify-content: end; margin-top: 25px; ">
                            <div>
                                <el-button
                                    plain
                                    size="mini"
                                    type="primary"
                                    @click="clear"
                                >
                                    {{clearText}}
                                </el-button>
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="onSubmit"
                                >
                                    {{confirmText}}
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { K } from "handlebars";
const dao = require("../api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yes-transfer-table",
    props: {
        formCreateInject: {
            type: Object,
            required: true
        },
        dataSource: String,
        app: {
            type: String,
            default: ""
        },
        view: {
            type: String,
            default: ""
        },
        submitMethodEvent: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: "穿梭视图"
        },
        leftTitle: {
            type: String,
            default: "左侧标题"
        },
        rightTitle: {
            type: String,
            default: "右侧标题"
        },
        viewLabel: {
            type: String,
            default: ""
        },
        viewValue: {
            type: String,
            default: ""
        },
        pageSize: {
            type: [String, Number],
            default: "10"
        },
        inputWidth: {
            type: [String, Number],
            default: "200"
        },
        tableWidth: {
            type: [String, Number],
            default: "100%"
        },
        columnWidth: {
            type: [String, Number],
            default: "300"
        },
        search: {
            type: String,
            default: ""
        },
        initData: {
            type: Object,
            defeat: () => ({})
        },
        disabled: {
            type: Boolean
        },
        showColumnKeys: Array,
        clearMethodEvent: {
            type: String,
            default: ""
        },
        maxHeight: {
            type: String,
            default: "500px"
        },
        enterClick: {
            type: String,
            default: ""
        },
        inputClick: {
            type: String,
            default: ""
        },
        isEnterQuery: {
            type: Boolean,
            default: true
        },
        multiSelect: {
            type: Boolean,
            default: true
        },
        rowKey: {
            type: String,
            default: ""
        },
        // 查询视图后执行的脚本
        queryViewAfterEvent: {
            type: String,
            default: ""
        },
        // 搜索输入框占位符
        queryInputPlaceholder: {
            type: String,
            default: "请输入搜索内容,按回车键触发"
        },
        clearText: {
            type: String,
            default: "清 空"
        },
        confirmText: {
            type: String,
            default: "确 定"
        },
        addText: {
            type: String,
            default: "新 增"
        },
        removeText: {
            type: String,
            default: "移 除"
        }
    },
    watch: {
        disabled(val) {
            this.disabledValue = val;
        }
    },
    data() {
        return {
            total: 0,
            pageNum: 1,
            fvalue: "",
            inputName: "",
            fRowKey: "uid",
            selectRow: [],
            selectRowRight: [],
            tableData: [],
            tableDataByRight: [],
            searchData: {},
            loadFlag: true,
            columnField: [],
            currentPageSize: 10,
            emptyText: "暂无数据",
            isShowTooltip: false,
            dialogVisible: false,
            disabledValue: false,
            designerVisable: false,
            fDataSource: this.dataSource,
            initSearchData: this.initData,
            multiSelectValue: this.multiSelect
        };
    },
    methods: {
        handleMouseout() {
            if (!this.isShowTooltip) this.isShowTooltip = true;
        },
        handleMouseover() {
            if (utils.isNull(this.fvalue)) {
                if (!this.isShowTooltip) this.isShowTooltip = true;
                return;
            }
            if (this.isShowTooltip) this.isShowTooltip = false;
        },
        // 设置row-key
        getRowKeys(row) {
            let result = undefined;
            if (row && this.fRowKey) {
                result = row[this.fRowKey];
            }
            if (!result) {
                this.$messageWarning({ message: "行数据的Key不能为空!", type: "warning" });
            }
            return result;
        },
        setDisabled(val) {
            this.disabledValue = val;
        },
        // 将组件的值返回
        getValue() {
            return this.fvalue;
        },
        // 修改组件的值
        setValue(val) {
            this.loadFlag = false;
            this.fvalue = val;
            if (this.fDataSource) {
                this.updateSourceData(val);
            }
            this.loadFlag = true;
        },
        setInputName(val) {
            this.inputName = val;
            this.$emit("input", this.inputName);
        },
        // 修改绑定数据源数据
        updateSourceData(val) {
            utils.updateSourceData(this.fDataSource, val ? val : this.fvalue);
        },
        // 读取绑定数据源数据
        async loadSourceData() {
            if (this.loadFlag) {
                const res = utils.getSourceData(this.fDataSource);
                if (typeof res !== "object" && res && this.viewValue && (this.fvalue !== res || this.inputName === "")) {
                    if (this.fvalue !== res) {
                        this.fvalue = res;
                        let queryData = {};
                        queryData[this.viewValue] = res;
                        const tempSearchData = { ...queryData, ...this.initSearchData };
                        const view = await dao.view(this.app, this.view, tempSearchData, 1, 1);
                        if (view.data.length > 0) {
                            this.setInputName(view.data[0][this.viewLabel]);
                        }
                    }
                } else {
                    if (this.fvalue !== res) {
                        this.setInputName("");
                    }
                }
            }
        },
        async setQueryData(data, key) {
            if (data !== undefined) {
                if (key == undefined) {
                    this.initSearchData = data;
                } else {
                    this.$set(this.initSearchData, key, data);
                }
                await this.getView();
                await this.loadSourceData();
            }
        },
        async clear() {
            this.selectRow = [];
            this.selectRowRight = [];
            if (this.clearMethodEvent) {
                const func = window.AsyncFunction("vm", "row", "rightRow", "api", "dao", "utils", this.clearMethodEvent);
                await func(this, this.selectRow, this.selectRowRight, this.formCreateInject.api, dao, utils);
            }
            //输入框显示值和绑定值设为空字符串 否则是undefined
            //this.setValue(this.selectRow[this.viewValue]);
            //this.setInputName(this.selectRow[this.viewLabel]);
            this.setValue("");
            this.setInputName("");
            this.$message({ message: "清空成功", type: "success" });
            this.onClose();
        },
        async handleButton() {
            this.dialogVisible = true;
            if (this.inputClick && this.inputClick != null) {
                const searchScript = window.AsyncFunction("vm", "api", "dao", "utils", "selectRow", "selectRowRight", "fvalue", "inputName", this.inputClick);
                await searchScript(this, this.formCreateInject.api, dao, utils, this.selectRow, this.selectRowRight, this.fvalue, this.inputName);
            }
        },
        show() {
            this.dialogVisible = true;
        },
        async onOpen() {
            if (this.search) {
                this.$set(this.searchData, this.search, "");
            }
            await this.getView();
        },
        async onSubmit() {
            // let selectRow = this.selectRow;
            let selectRow = this.tableDataByRight;
            if (!this.multiSelectValue) {
                if (selectRow) {
                    this.setInputName(selectRow[this.viewLabel]);
                    this.setValue(selectRow[this.viewValue]);
                }
            } else {
                if (Array.isArray(selectRow) && selectRow.length > 0) {
                    let labelList = [];
                    let valueList = [];
                    selectRow.forEach((e) => {
                        labelList.push(e[this.viewLabel]);
                        valueList.push(e[this.viewValue]);
                    });
                    this.setInputName(labelList.join(","));
                    this.setValue(valueList);
                } else {
                    this.setInputName("");
                    this.setValue("");
                }
            }
            if (this.submitMethodEvent && this.submitMethodEvent != null) {
                const submitScript = window.AsyncFunction("vm", "row", "tableDataByRight", "api", "dao", "utils", this.submitMethodEvent);
                await submitScript(this, this.selectRow, selectRow, this.formCreateInject.api, dao, utils);
            }
            this.onClose();
        },
        async onClose() {
            this.pageNum = 1;
            this.tableData = [];
            // this.selectRow = [];
            this.searchData = {};
            this.dialogVisible = false;
        },
        async handleSearch() {
            if (this.isEnterQuery === undefined || this.isEnterQuery) {
                this.pageNum = 1;
                await this.getView();
            }
            const enterClick = this.enterClick;
            if (enterClick && enterClick != null) {
                const tempSearchData = { ...this.searchData, ...this.initSearchData };
                const enterScript = window.AsyncFunction("vm", "searchData", "api", "dao", "utils", enterClick);
                await enterScript(this, tempSearchData, this.formCreateInject.api, dao, utils);
            }
        },
        handleSelectionChange(e) {
            if (this.multiSelectValue && !Array.isArray(e)) {
                this.selectRow = [e];
            } else {
                this.selectRow = e;
            }
        },
        handleSelectionChangesByRight(selection, row) {
            this.selectRowRight = selection;
        },
        handleSelectionChanges(selection, row) {
            this.selectRow = selection;
        },
        async handleCurrentChange(e) {
            this.pageNum = e;
            await this.getView();
        },
        async getView(page, size) {
            if (this.app && this.view) {
                // this.tableData = [];
                this.emptyText = "loading...";
                const tempSearchData = { ...this.searchData, ...this.initSearchData };
                const res = await dao.view(this.app, this.view, tempSearchData, page ? page : this.pageNum, size ? size : this.pageSize);
                if (!res.success) {
                    this.total = 0;
                    this.tableData = [];
                    this.emptyText = "暂无数据";
                    return utils.messageWarning("请求失败, 请重试");
                }
                if (!Array.isArray(res.data) || res.data.length === 0) {
                    this.total = 0;
                    this.tableData = [];
                    this.emptyText = "暂无数据";
                    return;
                }
                this.total = res.count;
                this.tableData = res.data;
                if (this.queryViewAfterEvent) {
                    const func = window.AsyncFunction("vm", "api", "dao", "utils", "tableData", this.queryViewAfterEvent);
                    await func(this, this.formCreateInject.api, dao, utils, this.tableData);
                }
                if (!this.multiSelect) {
                    const row = this.tableData.filter((item) => {
                        return item[this.viewValue] === this.fvalue;
                    });
                    if (row[0]) this.setInputName(row[0][this.viewLabel]);
                } else {
                    // const row = this.tableData.filter((item) => {
                    //     return this.fvalue.includes(item[this.viewValue]);
                    // });
                    // if (Array.isArray(row) && row.length > 0) {
                    //     let labelList = [];
                    //     row.forEach((e) => {
                    //         labelList.push(e[this.viewLabel]);
                    //     });
                    //     this.setInputName(labelList.join(","));
                    // }
                }
            }
        },
        clearSelectionItemsRight() {
            this.$nextTick(async () => {
                await this.$refs.yesTransferRightTableRef.clearSelection();
            });
        },
        handleSelectionItemsRight() {
            this.$nextTick(async () => {
                if (!this.multiSelectValue) {
                    let item = undefined;
                    if (Array.isArray(this.selectRow)) {
                        item = this.selectRow[0];
                    } else {
                        item = this.selectRow;
                    }
                    await this.$refs.yesTransferRightTableRef.toggleRowSelection(item, true);
                } else {
                    for (let item of this.selectRowRight) {
                        await this.$refs.yesTransferRightTableRef.toggleRowSelection(item, true);
                    }
                }
            });
        },
        /**
         * 打开视图, 多选框选中
         * selectItems是一个数组, 数组的每一条数据是表格的一整行数据
         * 1. 先在input点击事件调用clearSelectionItems方法
         * 2. 然后再调用initSelectItems方法
         */
        async initSelectItemsRight(selectItems) {
            if (selectItems) {
                this.selectRowRight = selectItems;
            }
            if (utils.isNull(this.selectRowRight)) {
                return;
            }
            await this.handleSelectionItems();
        },
        clearSelectionItems() {
            this.$nextTick(async () => {
                await this.$refs.yesTransferTableRef.clearSelection();
            });
        },
        handleSelectionItems() {
            this.$nextTick(async () => {
                if (!this.multiSelectValue) {
                    let item = undefined;
                    if (Array.isArray(this.selectRow)) {
                        item = this.selectRow[0];
                    } else {
                        item = this.selectRow;
                    }
                    await this.$refs.yesTransferTableRef.toggleRowSelection(item, true);
                } else {
                    for (let item of this.selectRow) {
                        await this.$refs.yesTransferTableRef.toggleRowSelection(item, true);
                    }
                }
            });
        },
        /**
         * 打开视图, 多选框选中
         * selectItems是一个数组, 数组的每一条数据是表格的一整行数据
         * 1. 先在input点击事件调用clearSelectionItems方法
         * 2. 然后再调用initSelectItems方法
         */
        async initSelectItems(selectItems) {
            if (selectItems) {
                this.selectRow = selectItems;
            }
            if (utils.isNull(this.selectRow)) {
                return;
            }
            await this.handleSelectionItems();
        },
        handleAdd() {
            const selectRow = this.selectRow;
            if (selectRow && selectRow.length > 0) {
                // this.rightKeyValueList = [];
                this.tableDataByRight = JSON.parse(JSON.stringify(selectRow));
                // this.tableDataByRight.map((item) => this.rightKeyValueList.push(item[this.fRowKey]));
            }
        },
        handleRemove() {
            const selectRowRight = this.selectRowRight;
            if (selectRowRight && selectRowRight.length > 0) {
                const fRowKey = this.fRowKey;
                const list = this.selectRowRight.map((item) => item[fRowKey]);
                this.tableDataByRight = this.tableDataByRight.filter((item) => !list.includes(item[fRowKey]));
                this.$refs.yesTransferRightTableRef.clearSelection();
                this.selectRowRight = [];
                for (const item of this.tableData) {
                    const key = item[fRowKey];
                    if (list.includes(key)) {
                        this.$refs.yesTransferTableRef.toggleRowSelection(item, false);
                    }
                }
            }
        }
    },
    async created() {
        if (this.$parent && this.$parent.$options._componentTag === "dragTool") {
            return;
        }
        this.fRowKey = this.rowKey ? this.rowKey : "uid";
        const queryData = {
            app: this.app,
            view: this.view
        };
        const res = await dao.view("dme", "column", queryData);
        const headList = res.data.sort((x, y) => x.seq - y.seq);
        headList.forEach((item) => {
            if (!this.showColumnKeys || this.showColumnKeys.length === undefined || this.showColumnKeys.length === 0) {
                this.columnField.push({ label: item.name, value: item.alias });
            } else {
                this.showColumnKeys.map((v) => {
                    if (v.key === item.alias) {
                        const label = v.label ? v.label : item.name;
                        const width = v.width ? v.width + "px" : undefined;
                        this.columnField.push({
                            label: label,
                            value: item.alias,
                            width: width
                        });
                    }
                });
            }
        });
    },
    async mounted() {
        this.disabledValue = this.disabled;
        this.currentPageSize = parseInt(this.pageSize);
    }
};
</script>

<style></style>
