<template>
  <div>
    <template v-if="fvalue.length > 0">
      <el-timeline-item
        v-for="(item, index) in fvalue"
        :key="index"
        :timestamp="timestamp"
        :hide-timestamp="hideTimestamp"
        :placement="placement"
        :type="type"
        :color="color"
        :size="size"
        :icon="icon"
        :dot="dot"
      >
        <slot>
        </slot>
      </el-timeline-item>
    </template>
    <template v-else>
      <el-timeline-item
        :timestamp="timestamp"
        :hide-timestamp="hideTimestamp"
        :placement="placement"
        :type="type"
        :color="color"
        :size="size"
        :icon="icon"
        :dot="dot"
      >
        <slot>

        </slot>
      </el-timeline-item>
    </template>
  </div>
</template>

<script>
const utils = require("@/api/core/utils");
export default {
  name: "yes-timeline-item",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    timestamp: String,
    hideTimestamp: Boolean,
    placement: String,
    type: String,
    color: String,
    size: String,
    icon: String,
    dot: String,
  },
  data () {
    return {
      fvalue: [{}],
      fDataSource: this.dataSource
    };
  },
  created () {
    // this.fvalue = []
  },
  watch: {},
  methods: {
    getValue () {
      return this.fvalue;
    },
    setValue (val) {
      this.fvalue = val;
    },
    loadSourceData () {
      this.fvalue = utils.getSourceData(
        this.fDataSource
      );
    },
    updateSourceData () {
      utils.updateSourceData(this.fDataSource, this.fvalue);
    },
  },
};
</script>

<style>
</style>