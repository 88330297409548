<template>
  <el-empty
    :description="description"
    :image="image"
    :image-size="imageSize"
  >
    <slot />
  </el-empty>
</template>

<script>
// import uniqueId from "@form-create/utils/lib/unique";
// const utils = require("@/api/core/utils");
// const dao = require('@/api/core/dao');
export default {
  name: "yesEmpty",
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    description: String,
    image: String,
    imageSize: Number,
  },
  data () {
    return {};
  },
  watch: {},
  methods: {},
}
</script>
