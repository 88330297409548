<template>
  <div :style="{width:`${menuWidth?menuWidth:(mode === 'vertical' ? '25%' : '100%')}`}">
    <div
      v-if="isDrag"
      style="display:flex;color: #606266;"
    >
      <span style="margin-left:40px">{{'menu:'}}</span>
      <span>{{formCreateInject.rule.name}}</span>

    </div>
    <!-- <el-radio-group
      v-model="isCollapse"
      style="margin-bottom: 20px;"
    >
      <el-radio-button :label="false">展开</el-radio-button>
      <el-radio-button :label="true">收起</el-radio-button>
    </el-radio-group> -->
    <el-menu
      :default-active="activeIndex"
      :mode="mode"
      @select="handleSelect"
      @open="handleOpen"
      @close="handleClose"
      class="el-menu-demo"
      :background-color="backgroundColor"
      :text-color="textColor"
      :active-text-color="activeTextColor"
    >
      <yes-sub-menu-item
        v-for="item in menuTree"
        :key="item[nodeId]"
        :item="item"
        :icon="icon"
        :index="nodeId"
        :title="nodeName"
        :class="spmenuitem"
      >
      </yes-sub-menu-item>

    </el-menu>
  </div>
</template>
<script>
import yesSubMenuItem from "./yesSubMenuItem";
import arrayToTree from "array-to-tree";
const utils = require("@/api/core/utils");
const dao = require("../api/core/dao");
const common = require('../utils/common');

export default {
  name: "menuTree",
  components: {
    yesSubMenuItem
  },
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    nodeId: {
      type: String,
      default: "id",
    },
    nodeName: {
      type: String,
      default: "name",
    },
    parentId: {
      type: String,
      default: "parent_id",
    },
    sort: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'vertical'
    },
    menuWidth: {
      type: String,
      default: ''
    },
    activeIndex: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: '#ffffff'
    },
    textColor: {
      type: String,
      default: '#303133'
    },
    activeTextColor: {
      type: String,
      default: '#409EFF'
    },
    selectMethodBody: {
      type: String,
      default: ''
    },
    onMethodBody: {
      type: String,
      default: ''
    },
    closeMethodBody: {
      type: String,
      default: ''
    },
    spmenuitem:{
      type: String,
      default: ''
    }
  },
  data () {
    return {
      menuTree: [],
      fvalue: [],
      isCollapse: false,
      isDrag: false,
      fDataSource: this.dataSource
    };
  },
  created () {
    if (this.$parent.$options._componentTag === 'dragTool') {
      this.isDrag = true;
    }
  },
  methods: {
    // 将组件的值返回
    getValue () {
      return this.fvalue;
    },
    // 修改组件的值
    setValue (val) {
      this.fvalue = val;
      this.toTree(val)
    },
    // 读取绑定数据源数据
    loadSourceData () {
      if (this.menuTree.length === 0) {
        this.load();
      }
    },
    load () {//从新加载菜单的方法
      const val = utils.getSourceData(
        this.fDataSource
      );
      if (val && common.getType(val).indexOf('array') > -1) {
        this.fvalue = val;
        this.toTree(val)
      }

    },
    toTree (val) {
      this.menuTree = arrayToTree(val, {
        parentProperty: this.parentId,
        customID: this.nodeId,
      });

      if (this.sort) {
        const sorts = this.sort.split(',');
        const field = sorts[0];
        const order = sorts[1];
        this.menuTree.sort((a, b) => order === "desc" ? (b[field] - a[field]) : (a[field] - b[field]))
        this.menuTree.forEach(menu => this.sortBy(menu, field, order))
      }
    },
    sortBy (menu, field, order) {
      if (menu.children && menu.children.length > 0) {
        menu.children.sort((a, b) => order === "desc" ? (b[field] - a[field]) : (a[field] - b[field]))
        menu.children.forEach(menu => this.sortBy(menu, field, order))
      }
    },
    // 修改绑定数据源数据
    updateSourceData () {
    },
    async handleSelect (index) {
      const data = this.fvalue.find(item => item[this.nodeId] === index);
      if (this.selectMethodBody) {
        const func = window.AsyncFunction(
          "vm",
          "index",
          "data",
          "dao",
          "api",
          "utils",
          this.selectMethodBody
        );
        await func(
          this,
          index,
          data,
          dao,
          this.formCreateInject.api,
          utils
        );
      }
    },
    async handleOpen (index) {
      const data = this.fvalue.find(item => item[this.nodeId] === index);
      if (this.selectMethodBody) {
        const func = window.AsyncFunction(
          "vm",
          "index",
          "data",
          "dao",
          "api",
          "utils",
          this.onMethodBody
        );
        await func(
          this,
          index,
          data,
          dao,
          this.formCreateInject.api,
          utils
        );
      }
    },
    async handleClose (index) {
      const data = this.fvalue.find(item => item[this.nodeId] === index);
      if (this.selectMethodBody) {
        const func = window.AsyncFunction(
          "vm",
          "index",
          "data",
          "dao",
          "api",
          "utils",
          this.closeMethodBody
        );
        await func(
          this,
          index,
          data,
          dao,
          this.formCreateInject.api,
          utils
        );
      }
    },
  }
}
</script>
<style scoped>
.el-menu {
  border-right: 0;
}
</style>